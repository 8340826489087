import React from "react";

const OurMissionCard = ({ image, title, content, colored }) => {
  return (
    <div
      className={`${
        colored ? "bg-[#FFF9E2]" : " bg-white"
      } rounded-[1.5rem] usecase-shadow flex items-center md:gap-8 gap-4 md:py-[1.25rem] md:px-[2.5rem] p-2 md:w-[50.313rem] border border-[#DADADA]`}
    >
      <img
        src={image}
        alt="for students image"
        className="md:w-[6rem] w-16 md:h-[6rem] h-16"
      />

      <h1 className="md:text-[1.25rem] text-sm md:leading-7">
        <span className="md:text-[2rem] font-semibold capitalize">{title}</span>
        <br />
        {content}
      </h1>
    </div>
  );
};

export default OurMissionCard;
