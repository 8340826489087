import React from 'react'

function OSMain() {
  return (
    <div className='w-full flex flex-col items-center xl:pt-[12.313rem] pt-32 xl:px-0 px-[1.25rem] gap-4'> 
    <div className="px-[1.25rem] md:pt-[2.5rem] py-12 md:pb-[6rem] items-center w-full gap-[1.125rem]">
      {/* header */}
      <div className="flex flex-col items-center gap-2">
        <h1 className="sm:text-[1rem] md:text-[1.75rem] text-center font-medium mb-8 whitespace-nowrap py-1">
            Industry First Generative AI Launcher for IFPs
            <br />
            with Android And Fuchsia OS
        </h1>
        <h3 className="md:text-[1rem] text-[0.70rem] text-[#202020] font-normal text-center">
        Wriety OS brings your IFPD to life with its generative AI features, whether it's Android or Fuchsia, 
        <br />
        we cover your needs. All important daily-use tools are at your fingertips. Don't know which feature 
        <br />
        to use for a task? Ask the OS to suggest it, voila! You will have it. Wriety OS takes generative AI
        <br />
        seriously. Many daily-use cases are automated, providing a rich toolset and content. 
        <br />
        The next-generation OS for next-generation hardware.
        </h3>
      </div>
    </div>
    

    </div>
  )
}

export default OSMain