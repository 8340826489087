import React from "react";

import BlogCard from "../../components/blog/BlogCard";

const BlogPosts = () => {
  return (
    <div className="flex flex-col px-[1.25rem] md:pt-[3.469rem] md:pb-[2.813rem] py-6 items-center w-full  bg-[#FFFBEB]">
      {/* header */}
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-[#E2B00B] md:text-[2rem] text-xl font-medium">
          Blog posts
        </h1>
        <h2 className="md:text-[3.25rem] text-[1.25rem] text-[#202020] font-semibold text-center">
          Take a look at our latest blogs
        </h2>
      </div>

      {/* cards */}
      <div className="flex md:flex-row flex-col gap-6 md:mt-[3.531rem] mt-6">
        <BlogCard />
        <BlogCard />
        <BlogCard />
      </div>

      {/* view all button */}
      <button
        type="submit"
        className="md:mt-16 mt-8 text-sm text-[#202020] font-medium rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-3 px-[2.75rem]"
      >
        View all
      </button>
    </div>
  );
};

export default BlogPosts;
