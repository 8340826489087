import React from "react";
import ContactUs from "../components/ContactUs";

const GetInTouch = () => {
  return (
    <div>
      <ContactUs getInTouch />
    </div>
  );
};

export default GetInTouch;
