import React from "react";

const HighlightCard = ({ heading, subheading }) => {
  return (
    <div className="md:w-[22.5rem] w-full md:h-[13.938rem] h-[10rem] flex flex-col gap-4 items-center justify-center usecase-shadow border border-[#DADADA] bg-[#FFFFFF] rounded-[1.5rem]">
      <h1 className="md:text-[5.625rem] text-[3rem] text-[#FFC727] font-semibold md:leading-[8.438rem]">
        {heading}
      </h1>

      <h2 className="md:text-[1.75rem] text-xs text-[#4B465C] font-medium">
        {subheading}
      </h2>
    </div>
  );
};

export default HighlightCard;
