import React from "react";

import ContactUs from "./ContactUs";

import PartnersIllustration from "../assets/partners/partners-illustration.svg";
import Brand1 from "../assets/home/brand1.svg";
import Brand2 from "../assets/home/brand2.svg";
import Brand3 from "../assets/home/brand3.svg";

const Partners = () => {
  return (
    <div className="flex px-[1.25rem] flex-col items-center md:mt-[11.25rem] mt-24 border-b border-[#D2D2D2]">
      {/* header */}
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center md:gap-2">
          <h1 className="md:text-[2rem] text-[1.5rem] text-[#E2B00B] font-medium">
            Partners
          </h1>
          <h2 className="md:text-[4rem] text-[2rem] font-semibold text-[#202020]">
            Our Partners
          </h2>
        </div>

        <p className="md:text-lg text-sm text-[#202020] md:w-[41.938rem] text-center">
          There are many variations of passages of available, Majority
          alteration by injected humour words eiusmod tempor incididunt blandit.
        </p>
      </div>

      {/* image */}
      <img
        src={PartnersIllustration}
        alt="illustration"
        className="md:w-[25.75rem] mt-4"
      />

      {/* brands */}
      <div className="flex flex-col lg:gap-[5.938rem] gap-4 lg:mt-[5.313rem] mt-8 lg:pb-[8.625rem] pb-8 lg:mx-0 mx-16 ">
        <div className="flex lg:flex-row flex-col lg:gap-20 gap-4">
          <img src={Brand3} alt="brand 3" className="lg:h-[5.625rem]" />
          <img src={Brand2} alt="brand 2" className="lg:h-[5.625rem]" />
          <img src={Brand1} alt="brand 1" className="lg:h-[5.625rem]" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
