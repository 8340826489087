import React from "react";
import Comparision from "../components/features/Comparision";
import ContactUs from "../components/ContactUs";

const Features = () => {
  return (
    <>
      <Comparision />
      <ContactUs getInTouch/>
    </>
  );
};

export default Features;
