import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForTeachers from "../../assets/usecases/for-teachers.svg";
import MathsIllustration from "../../assets/usecases/maths-illustration.svg";
import ForStudents from "../../assets/usecases/for-students.svg";

const Usecase5 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:gap-0 gap-6 xl:pl-[6.25rem] xl:pr-[2.313rem] px-[1.25rem] xl:pt-[4.625rem] xl:pb-[15rem] py-6 bg-[#FFFBEB] justify-center">
      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[47.125rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-[37.6x]">
            Maths & Science
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Mathematics and Science
            <br />
            Education
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020]">
          Teachers can use Wriety to create engaging lessons with text,
          diagrams, and multimedia.Students can collaborate in real-time on
          assignments and projects.
        </p>

        {/* cards */}
        <div className="hidden absolute -bottom-[9rem] left-[1.063rem] xl:flex flex-col gap-6">
          <UsecaseCard
            title="students"
            image={ForStudents}
            content="Teachers can use Wriety's mathematical tools and formula editor for teaching."
          />

          <div className="ml-[11.625rem]">
            <UsecaseCard
              title="teachers"
              image={ForTeachers}
              content="Students can solve math problems, visualize scientific concepts, and share their work."
            />
          </div>
        </div>
      </div>

      {/* illustration */}
      <img
        src={MathsIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] xl:mt-[1.25rem] xl:mx-0 md:mx-auto mx-0"
      />

      <div className="xl:hidden flex flex-col gap-4">
        <UsecaseCard
          title="students"
          image={ForStudents}
          content="Teachers can use Wriety's mathematical tools and formula editor for teaching."
        />

        <UsecaseCard
          title="teachers"
          image={ForTeachers}
          content="Students can solve math problems, visualize scientific concepts, and share their work."
        />
      </div>
    </div>
  );
};

export default Usecase5;
