import React from "react";

import OurMissionCard from "../about/OurMissionCard";

import AboutIllustration from "../../assets/about/about-illustration.svg";
import Rocket from "../../assets/download/rocket.png";

const AboutMain = () => {
  return (
    <div className="w-full flex flex-col items-center xl:pt-[12.313rem] pt-32 xl:px-0 px-[1.25rem] bg-[#FFFBEB] gap-4">
      {/* heading */}
      <div className="flex items-center justify-center relative w-fit z-1">
        <h1 className="z-10 font-medium xl:text-[2.5rem] text-[1.5rem] text-center">
          About Us
        </h1>

        <div className="hidden xl:block absolute bottom-0 -left-[0.3rem] h-[1.875rem] w-[12rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
      </div>

      <div className="w-full flex xl:flex-row flex-col xl:pl-[6.25rem] xl:pb-[33.063rem] justify-center xl:pr-[41.875rem]">
        {/* content */}
        <div className="relative flex flex-col gap-[1rem] xl:w-[41.875rem] mt-[1.7rem]">
          <div>
            <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium">
              Who we are?
            </h1>
            <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
              The story behind our
              <br />
              company
            </h2>
          </div>

          <p className="xl:text-xl text-[#202020]">
            Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis
            lacinia libero eget lectus fringilla, id euismod dolor vehicula.
            Suspendisse euismod.Lorem ipsum dolor sit amet, euismod, nec varius
            urna fermentum. Duis lacinia libero eget lectus fringilla, id
            euismod dolor vehicula. Suspendisse euismod
          </p>

          {/* cards */}
          <div className="xl:flex hidden absolute -bottom-[28rem] left-0 flex-col gap-[2.75rem]">
            <OurMissionCard
              title="Our Mission"
              image={Rocket}
              content="
                  Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
            />

            <div className="ml-[5.625rem]">
              <OurMissionCard
                title="Our Mission"
                image={Rocket}
                content="
                  Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
              />
            </div>
          </div>
        </div>

        {/* illustration */}
        <img
          src={AboutIllustration}
          alt="ilustration"
          className="xl:w-[44.688rem] xl:min-h-[44.563rem] z-1 xl:absolute xl:right-10 top-[18.5rem]"
        />
      </div>

      <div className="xl:hidden flex flex-col gap-4">
        <OurMissionCard
          title="Our Mission"
          image={Rocket}
          content="Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
        />

        <OurMissionCard
          title="Our Mission"
          image={Rocket}
          content="Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
        />
      </div>
    </div>
  );
};

export default AboutMain;
