import React, { useState } from "react";
import "./Homeanimation.css";

const images = {
  Phones: "/home/Phones.svg",
  iPad: "/home/ipad.svg",
  IFP: "/home/IFP.svg",
  Mac: "/home/g12.svg",
  Laptop: "/home/Laptop.svg",
  "Android Tab": "/home/Androidtab.svg",
  "Interactive Projectors": "/home/Projector.svg",
  "Android Box": "/home/Androidbox.svg",
};

const defaultImage = "/home/multiplatforms.svg"; 

const Homeanimation = () => {
  const [selectedImage, setSelectedImage] = useState(defaultImage);
  const [isFading, setIsFading] = useState(false);

  const handleHoverImage = (image) => {
    setIsFading(true);
    setTimeout(() => {
      setSelectedImage(images[image]);
      setIsFading(false);
    }, 300);
  };

  const handleMouseLeave = () => {
    setIsFading(true);
    setTimeout(() => {
      setSelectedImage(defaultImage);
      setIsFading(false);
    }, 300);
  };

  return (
    <div className="gallery-container justify-beteween">
      <div className="options text-left">
        {Object.keys(images).map((key) => (
          <button
          className=" hover:font-[700] hover:transition-all transition-all"
            key={key}
            onMouseEnter={() => handleHoverImage(key)}
            onMouseLeave={handleMouseLeave}
          >
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </button>
        ))}
      </div>
      <div className="image-display">
        <img
          src={selectedImage}
          alt="Selected"
          className={isFading ? "fade-out" : "fade-in"}
        />
      </div>
    </div>
  );
};

export default Homeanimation;
