import React from 'react'
import mdmimage1 from "../../assets/mdmwriety/mdmimage1.png"
import mdmimage2 from "../../assets/mdmwriety/mdmimage2.png"
import mdmimage3 from "../../assets/mdmwriety/mdmimage3.png"
function MDMMain() {
  return (
    <div className='w-full flex flex-col items-center xl:pt-[6.313rem] pt-[6rem] xl:px-0 px-[1.25rem] gap-4'> 
    <div className="flex flex-col px-[1.25rem] md:pt-[4.5rem] items-center w-full gap-[1.125rem]">
        <div className="flex flex-col items-center gap-2">
            <h1 className="sm:text-[1rem] md:text-[1.75rem] font-medium mb-4 whitespace-nowrap py-1">
                Wriety MDM
            </h1>
            <h3 className="md:text-[1rem] text-[0.70rem] text-[#202020] font-normal text-center">
                Wriety MDM offers organizations a streamlined and rapid unified management solution for all devices which
            <br />
                have Wriety installed. This establishes an efficient and real-time equipment management system. It 
            <br />
                facilitates the digitization of the campus environment, enhancing the convenience of campus
            <br />
                management
            </h3>
        </div>
        <div className="sm:h-full sm:w-[250px] md:h-full md:w-[500px]">
            <img src={mdmimage1} alt="mdmimage1" className=''/>
        </div>
    </div>
    <div className="flex flex-col px-[1.25rem] md:pt-[4.5rem] items-center w-full gap-[2.5rem]">
        <div className="flex flex-col items-center gap-2">
            <h1 className="sm:text-[1rem] md:text-[1.75rem] font-medium mb-4 whitespace-nowrap py-1">
                Effortless Device Oversight
            </h1>
            <h3 className="md:text-[1rem] text-[0.70rem] text-[#202020] font-normal text-center">
                Seamlessly access and manage your devices in real-time through the cloud. Take command of your
            <br />
                screens remotely, initiate screen wake-ups, implement screen locks as needed, and enjoy full control over
            <br />
                your ecosystem with our integrated Android technology – all from anywhere you happen to be.
            </h3>
        </div>
        <div className="sm:h-full sm:w-[250px] md:h-full md:w-[500px]">
            <img src={mdmimage2} alt="mdmimage2" className=''/>
        </div>
    </div>
    <div className="flex flex-col px-[1.25rem] md:pt-[4.5rem]  md:pb-[6rem] items-center w-full gap-[2.5rem]">
        <div className="flex flex-col items-center gap-2">
            <h1 className="sm:text-[1rem] md:text-[1.75rem] font-medium mb-4 whitespace-nowrap py-1">
                Seamless Firmware Management
            </h1>
            <h3 className="md:text-[1rem] text-[0.70rem] text-[#202020] font-normal text-center">
                Effortlessly schedule and execute firmware updates tailored to your organization's timeline. With Wriety
            <br />
                MDM, updates are not only faster but also boast a higher success rate, thanks to reduced traffic
            <br />
                consumption. Experience optimized performance with every update.
            </h3>
        </div>
        <div className="sm:h-full sm:w-[250px] md:h-full md:w-[500px]">
            <img src={mdmimage3} alt="mdmimage3" className=''/>
        </div>
    </div>
   

    </div>
  )
}

export default MDMMain