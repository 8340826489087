import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

const ComingSoonModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <FontAwesomeIcon icon={faHourglassHalf} className="text-6xl text-gray-600 mb-4" />
        <h2 className="text-2xl font-bold mb-4">Coming Soon!</h2>
        <p className="mb-6">We're working hard to bring you this feature. Stay tuned!</p>
        <button
          onClick={onClose}
          className="bg-[#302C2C] text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ComingSoonModal;