import React from 'react'
import oswrietymain from '../../assets/oswriety/oswrietymain.svg'
function OSImage() {
  return (
    <div className='h-full w-full md:mt-10'>
        <img src={oswrietymain} className=' w-full' alt="MDMimage" />
    </div>
  )
}

export default OSImage      