import React, { useState, useMemo  } from "react";
import RightArrow from "../assets/right-arrow.svg";
import ContactIllustration from "../assets/contact-illustration.png";
import Mail from "../assets/features/mail.svg";
import Phone from "../assets/features/phone.svg";
import Time from "../assets/features/time.svg";
import UncheckedBox from "../assets/unchecked-box.svg";
import CheckedBox from "../assets/checked-box.svg";
import ChevronDown from "../assets/chevron-down.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import CountryDropdown from "./CountryDropdown";
import { CountryDropdown} from 'react-country-region-selector';
import TagsInput from "react-tagsinput";
import axios from "axios";
import 'react-tagsinput/react-tagsinput.css'


function ContactUs({ getInTouch }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [country, setCountry] = useState('India');
  const [emails, setEmails] = useState([]); // Initial emails state
  const [selectedDate, setSelectedDate] = useState(null);
  const isEmail = (input) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(input);
  }

  const submitForm = (e) => {
    e.preventDefault();
    console.log('Name: ', name);
    console.log('Emails: ', emails);
    console.log('Country: ', country);
    console.log('Date: ', selectedDate);
    console.log('Subject: ', subject);
    console.log('Message: ', message);
    console.log('Phone: ', phone);
    console.log('Email: ', email);
    console.log('Checked: ', checked);
    console.log('Form Submitted Successfully');
    console.log({name, emails, country, selectedDate, subject, message, phone, email, checked});
    // Upload the form to excel sheet and reload the page
    axios({
      url: 'HittheAPIURL', //the backend api url
      headers: {
      'Content-Type': 'application/json'
      },
      data: {name, emails, country, selectedDate, subject, message, phone, email, checked}
    }).then((response) => {
      console.log(response);
      // window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }


  return (
    <div
      className={`w-full xl:px-[7.188rem] px-[1.25rem] lg:py-19 ${
        getInTouch && "py-24"
      } lg:py-12 md:py-12 sm:py-2 flex flex-col justify-center items-center lg:gap-[3.75rem] gap-8 bg-[#FEFEFE]`}
    >
      

      {/* main */}
      <div className="flex lg:gap-[7.5rem] gap-8 lg:flex-row md:flex-row flex-col items-center mt-[90px]">
        <img
          src={ContactIllustration}
          alt="illustration"
          className="xl:w-[20.688rem] lg:w-[10.688rem] md:w-[20.688rem] sm:w-[20.688rem] lg:px-0 px-16"
        />

        <div className="lg:w-[47.375rem] flex flex-col lg:gap-16 gap-8">
          {/* contacts */}
          <div className="flex flex-col gap-2">
            <div className="relative ">
              <h1 className="lg:text-[2.5rem] text-[1.5rem] font-semibold leading-snug">
                Contact us
              </h1>
              <div className="-z-10 absolute top-8 -left-0.5 h-[1.4rem] w-[14rem] bg-[#FFF9E2]" />
            </div>
            <h2>We are here to help you.</h2>
          </div>

          <div className="flex xl:gap-[7.25rem] justify-between">
            <div className="flex flex-col gap-[0.75rem] items-center">
              <div className="flex flex-col items-center justify-center h-[2.75rem] w-[2.75rem] rounded-full bg-[#000]">
                <img src={Mail} alt="logo" className="w-[0.875rem] invert" />
              </div>
              <h1 className="lg:text-sm text-[0.65rem] whitespace-nowrap">
                info@wriety.com
              </h1>
            </div>

            <div className="flex flex-col gap-[0.75rem] items-center">
              <div className="flex flex-col items-center justify-center h-[2.75rem] w-[2.75rem] rounded-full bg-[#000]">
                <img src={Phone} alt="logo" className="w-[0.825rem] invert" />
              </div>
              <h1 className="lg:text-sm text-[0.65rem] whitespace-nowrap">+91 9154950218</h1>
            </div>

            <div className="flex flex-col gap-[0.75rem] items-center">
              <div className="flex flex-col items-center justify-center h-[2.75rem] w-[2.75rem] rounded-full bg-[#000]">
                <img src={Time} alt="logo" className="w-[0.875rem] invert" />
              </div>
              <h1 className="lg:text-sm text-[0.65rem] whitespace-nowrap">
                Mon - Sat 9.00 - 18.00
              </h1>
            </div>
          </div>

          {/* form */}
          <form>
            <div className="grid grid-cols-2 gap-x-[1.75rem] lg:gap-y-8 md:gap-y-8 sm:gap-y-8 gap-y-4">
              <input
                required
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] px-[0.625rem] lg:pt-[1.625rem] md:pt-[1.625rem] sm:pt-[1.625rem] pt-[1.625rem] lg:pb-[0.625rem] lg:pt-[0.625rem] pb-4 w-full lg:h-[3.75rem] h-[2rem]  border border-[#CCCCCC] focus:outline-none   placeholder:text-[#CCCCCC]"
                placeholder="Your Name*"
              />
<input
                required
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px]  px-[0.625rem] lg:pt-[1.625rem] md:pt-[1.625rem] sm:pt-[1.625rem] pt-[1.625rem] lg:pb-[0.625rem] lg:pb-[0.625rem] md:pb-[0.625rem] sm:pb-[0.625rem] pb-[0.625rem]  w-full lg:h-[3.75rem] h-[2rem] p-2 border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC] "
                placeholder="Email*"
              />

              <input
                required
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] px-[0.625rem] lg:pt-[1.625rem] md:pt-[1.625rem] sm:pt-[1.625rem] pt-[1.625rem] lg:pb-[0.625rem] md:pb-[0.625rem] sm:pb-[0.625rem] pb-[0.625rem]  w-full lg:h-[3.75rem] h-[2rem] p-2 border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC] "
                placeholder="Phone Number"
              />

              <input
                required
                type="text"
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] px-[0.625rem] lg:pt-[1.625rem] md:pt-[1.625rem] sm:pt-[1.625rem] pt-[1.625rem] lg:pb-[0.625rem] lg:pt-[0.625rem] pb-4 w-full lg:h-[3.75rem] h-[2rem] p-2 border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC] "
                placeholder="Subject*"
              />
            </div>

            <div className="lg:mt-8 md:mt-8 sm:mt-6 mt-4  ">
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] px-6 pt-[1.25rem] lg:pb-[0.625rem] pb-4 w-full h-[9.625rem] p-2 border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC] "
                placeholder="Message(Optional)"
              ></textarea>
            </div>

            {/* submit button */}
            {!checked && (
              <div className="w-full mt-6 flex items-center justify-center">
                <button
                  type="button"
                  onClick={(e) => submitForm(e)}
                  className="w-fit rounded-[0.625rem] border border-[#EECA53] bg-[#000] py-3 px-[1.25rem]"
                >
                  <div className="flex gap-1 items-center">
                    <h1 className="text-[#fff] lg:text-base text-sm font-medium">
                      Submit
                    </h1>

                    <img src={RightArrow} alt="arrow" className="h-6 w-6 invert" />
                  </div>
                </button>
              </div>
            )}

            {/* booking */}
            {getInTouch && (
              <div className="flex flex-col gap-8">
                {/* checkbox */}
                <div className="flex items-center gap-4 mt-3">
                  <button onClick={() => setChecked(!checked)}>
                    <img
                      src={checked ? CheckedBox : UncheckedBox}
                      alt="check box"
                    />
                  </button>

                  <h1 className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] text-20 text-[#0E123D]">
                    Want to Book a Free demo
                  </h1>
                </div>

                {/* booking form */}
                {checked && (
                  <>
                    <div className="grid grid-cols-2 gap-x-[1.75rem] gap-y-8">
                      <div className="">
                        {/* Datepicker */}
                        <DatePicker
                          required
                          id="date"
                          name="date"
                          wrapperClassName="w-full"
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          placeholderText="Select Date"
                          className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] flex justify-between px-[0.625rem] w-full lg:h-[3.75rem] h-[2rem] py-[1.125rem] border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC]"
                        />
                      </div>
                      
                      <div className="">
                        {/* Timepicker */}
                        <DatePicker
                          selected={selectedDate}
                          onChange={date => setSelectedDate(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          wrapperClassName="w-full"
                          placeholderText="Select a Time Slot"
                          className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] flex justify-between px-[0.625rem] w-full lg:h-[3.75rem] h-[2rem] py-[1.125rem] border border-[#CCCCCC] focus:outline-none  placeholder:text-[#CCCCCC]"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>


                      <div className="">  
                        <div className="">
                          <CountryDropdown 
                          required
                          id="country"
                          name="country"
                          defaultOptionLabel="India"
                          classes="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] flex justify-between px-[0.625rem] w-full lg:h-[3.75rem] h-[2rem] py-[0.125rem] border border-[#CCCCCC] focus:outline-none"
                          value={country}
                          onChange={(val) => setCountry(val)}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] flex justify-between px-[0.625rem] w-full lg:h-[3.75rem] h-[4rem] border border-[#CCCCCC] focus:outline-none placeholder:text-[#CCCCCC]">
                      <TagsInput validate={input => isEmail(input)} inputProps={{className: "lg:text-[15px] md:text-[15px] sm:text-[14px] text-[13px] w-full focus:outline-none", placeholder: "Add a guest email"}} className="focus:outline-none border-none react-tagsinput w-full" value={emails} onChange={email =>  setEmails(email)} />
                    </div>
                  </>
                )}
              </div>
            )}
          {/* submit button */}
          {checked && (
              <div className="w-full mt-6 flex items-center justify-center">
                <button
                  type="button"
                  onClick={(e) => submitForm(e)}
                  className="w-fit rounded-[0.625rem] border border-[#EECA53] bg-[#000] py-3 px-[1.25rem]"
                >
                  <div className="flex gap-1 items-center">
                    <h1 className="text-[#fff] lg:text-base text-sm font-medium">
                      Submit
                    </h1>

                    <img src={RightArrow} alt="arrow" className="h-6 w-6 invert" />
                  </div>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
