import React, { useState, useEffect } from "react";
import Sticky from 'react-stickynode';
import { Link } from 'react-router-dom';
import "./../App.css"

const TermsAndConditions = () => {
  const [activeSection, setActiveSection] = useState("");
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);
  const sections = [
    { id: "terms-and-conditions", title: "Terms And Conditions" },
    { id: "cookies", title: "Cookies" },
    { id: "license", title: "License" },
    { id: "hyperlinking-to-our-content", title: "Hyperlinking to our Content" },
    { id: "iframes", title: "iFrames" },
    { id: "content-liability", title: "Content Liability" },
    { id: "reservation-of-rights", title: "Reservation of Rights" },
    { id: "removal-of-links", title: "Removal of links from our website" },
    { id: "disclaimer", title: "Disclaimer" },
  ];

  // Function to handle smooth scrolling to a section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsStickyEnabled(false);
      } else {
        setIsStickyEnabled(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to determine which section is active based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    // Loop through each section to determine which one is active
    sections.forEach((item) => {
      const section = document.getElementById(item.id);
      if (section) {
        const sectionTop = section.offsetTop - 200; // Adjusted for better active section detection
        const sectionHeight = section.clientHeight;

        // Check if the current scroll position is within this section
        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(item.id);
        }
      }
    });
  };

  // Effect to add scroll event listener on component mount
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty array ensures effect runs only on mount and unmount

  return (
    <div className=" items-center justify-center">
    <div className="md:gap-[40px] gap-[10px] parent-div lg:w-[1570px] w-[96%] flex flex-col md:flex-row lg:px-[120px] md:px-[100px] sm:px-[30px] px-[30px] pt-[120px] lg:pt-[150px] md:pt-[100px] sm:pt-[80px] ssx-pt-[50px] pb-[120px] lg:pb-[100px] md:pb-[100px] sm:pb-[80px] s-pb-[50px] items-start justify-start md:mb-[50px] mb-[0px]">


      {/* Left sidebar */}
      <Sticky
              enabled={isStickyEnabled}
              top={0}
              bottomBoundary=".parent-div"
            >
      <div className="leftside w-[100%]">
      <Link
              className="w-[200px] hover:no-underline hover:text-[#212529]  mb-[20px block]"
              to="/"
            >
              {" "}
              <button className="w-[200px] flex items-center gap-[10px] ">
                <img className="w-[10px]" src="/arrow-black.svg" alt="" /> Go
                back{" "}
              </button>{" "}
            </Link>
        <nav className="sticky-nav1 mt-[40px]">
          <ul  className='md:w-[300px]'>
            {sections.map((item, index) => (
              <li key={index} className="mb-2">
                <button
                  className={`w-[100%] contents text-[16px]  cursor-pointer ${
                    activeSection === item.id ? "font-semibold active" : ""
                  }`}
                  onClick={() => scrollToSection(item.id)}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      </Sticky>
      {/* Main content */}
      <div className="right-side md:w-[75%] w-[100%] ml-auto">
        <section id="terms-and-conditions" className="mb-8">
          <h1 className="text-3xl font-semibold">Terms And Conditions</h1>
          <p className="py-4">
            Welcome to Wriety! These terms and conditions outline the rules and
            regulations for the use of Wriety's Website, located at Wriety.com.
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Wriety if you do not agree to
            take all of the terms and conditions stated on this page. The
            following terminology applies to these Terms and Conditions, Privacy
            Statement and Disclaimer Notice and all Agreements: "Client", "You"
            and "Your" refers to you, the person log on this website and
            compliant to the Company's terms and conditions. "The Company",
            "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
            "Parties", or "Us", refers to both the Client and ourselves. All
            terms refer to the offer, acceptance and consideration of payment
            necessary to undertake the process of our assistance to the Client
            in the most appropriate manner for the express purpose of meeting
            the Client's needs in respect of provision of the Company's stated
            services, in accordance with and subject to, prevailing law of af.
            Any use of the above terminology or other words in the singular,
            plural, capitalization and/or he/she or they, are taken as
            interchangeable and therefore as referring to same.
          </p>
        </section>
        <section id="cookies" className="mb-8">
          <h1 className="text-3xl font-semibold">Cookies</h1>
          <p className="py-4">
            We employ the use of cookies. By accessing Wriety, you agree to use
            cookies in agreement with Wriety's Privacy Policy. Most interactive
            websites use cookies to let us retrieve the user's details for each
            visit. Cookies are used by our website to enable the functionality
            of certain areas to make it easier for people visiting our website.
            Some of our affiliate/advertising partners may also use cookies.
          </p>
        </section>
        <section id="license" className="mb-8">
          <h1 className="text-3xl font-semibold">License</h1>
          <p className="py-4">
            Unless otherwise stated, Wriety and/or its licensors own the
            intellectual property rights for all material on Wriety. All
            intellectual property rights are reserved. You may access this from
            Wriety for your own personal use subjected to restrictions set in
            these terms and conditions.
            <br />
            <br />
            You must not:
            <ul className="list-disc list-inside">
              <li>Republish material from Wriety</li>
              <li>Sell, rent or sub-license material from Wriety</li>
              <li>Reproduce, duplicate or copy material from Wriety</li>
              <li>Redistribute content from Wriety</li>
            </ul>
          </p>
        </section>
        <section id="hyperlinking-to-our-content" className="mb-8">
          <h1 className="text-3xl font-semibold">Hyperlinking to our Content</h1>
          <p className="py-4">
            The following organizations may link to our Website without prior
            written approval:
            <ul className="list-disc list-inside">
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </li>
              <li>
                System wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ul>
            <br />
            These organizations may link to our home page, to publications or
            to other Website information so long as the link: (a) is not in any
            way deceptive; (b) does not falsely imply sponsorship, endorsement
            or approval of the linking party and its products and/or services;
            and (c) fits within the context of the linking party's site. We may
            consider and approve other link requests from the following types of
            organizations:
            <ul className="list-disc list-inside">
              <li>commonly-known consumer and/or business information sources;</li>
              <li>dot.com community sites;</li>
              <li>associations or other groups representing charities;</li>
              <li>online directory distributors;</li>
              <li>internet portals;</li>
              <li>accounting, law and consulting firms; and</li>
              <li>educational institutions and trade associations.</li>
            </ul>
            <br />
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Wriety; and
            (d) the link is in the context of general resource information.
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site. If you are one of the organizations listed in
            paragraph 2 above and are interested in linking to our website, you
            must inform us by sending an e-mail to Wriety. Please include your
            name, your organization name, contact information as well as the URL
            of your site, a list of any URLs from which you intend to link to
            our Website, and a list of the URLs on our site to which you would
            like to link. Wait 2-3 weeks for a response.
            <br />
            <br />
            Approved organizations may hyperlink to our Website as follows:
            <ul className="list-disc list-inside">
              <li>By use of our corporate name; or</li>
              <li>By use of the uniform resource locator being linked to; or</li>
              <li>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party's site.
              </li>
            </ul>
            <br />
            No use of Wriety's logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>
        </section>
        <section id="iframes" className="mb-8">
          <h1 className="text-3xl font-semibold">iFrames</h1>
          <p className="py-4">
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>
        </section>
        <section id="content-liability" className="mb-8">
          <h1 className="text-3xl font-semibold">Content Liability</h1>
          <p className="py-4">
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>
        </section>
        <section id="reservation-of-rights" className="mb-8">
          <h1 className="text-3xl font-semibold">Reservation of Rights</h1>
          <p className="py-4">
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and it's linking policy at any
            time. By continuously linking to our Website, you agree to be bound
            to and follow these linking terms and conditions.
          </p>
        </section>
        <section id="removal-of-links" className="mb-8">
          <h1 className="text-3xl font-semibold">Removal of links from our website</h1>
          <p className="py-4">
            If you find any link on our Website that is offensive for any reason,
            you are free to contact and inform us any moment. We will consider
            requests to remove links but we are not obligated to or so or to
            respond to you directly. We do not ensure that the information on
            this website is correct, we do not warrant its completeness or
            accuracy; nor do we promise to ensure that the website remains
            available or that the material on the website is kept up to date.
          </p>
        </section>
        <section id="disclaimer" className="mb-8">
          <h1 className="text-3xl font-semibold">Disclaimer</h1>
          <p className="py-4">
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
            <ul className="list-disc list-inside">
              <li>limit or exclude our or your liability for death or personal injury;</li>
              <li>limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;</li>
              <li>limit any of our or your liabilities in any way that is not
                permitted under applicable law; or</li>
              <li>exclude any of our or your liabilities that may not be excluded
                under applicable law.</li>
            </ul>
            <br />
            The limitations and prohibitions of liability set in this Section and
            elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and for
            breach of statutory duty. As long as the website and the information
            and services on the website are provided free of charge, we will not
            be liable for any loss or damage of any nature.
          </p>
        </section>
      </div>
    </div>
    </div>

  );
};

export default TermsAndConditions;
