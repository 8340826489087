import Cards from "./Cards";
import cloudStorage from "../../assets/add-ons/cloud-storage-short.svg";
import cloudRecording from "../../assets/add-ons/cloud-recording-short.svg";
import videoConfrenceing from "../../assets/add-ons/video-confrence-short.svg";
import JungleeAI from "../../assets/add-ons/junglee-ai-short.svg";
import downArrow from "../../assets/downArrow.svg";
import deleteIcon from "../../assets/add-ons/delete-icon.svg";

const AddOns2 = () => {
  return (
    <div className="flex items-center justify-center ">
      <div className="w-[1570px] px-[120px] pt-[140px]">
        <div
          className="flex flex-row gap-[20px] justify-center"
          style={{ alignItems: "flex-start" }}
        >
          <div className="flex flex-col px-[20px] py-[15px] items-center justify-center bg-[#FFFFFF] rounded-[8px]">
            <div className="flex flex-row items-center justify-between border-b border-[#E3E3E3] py-[12px] w-[535px]">
              <div
                className="flex flex-row gap-[20px] items-center justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <img
                  src={cloudStorage}
                  alt="cloud-storage"
                  className="w-[100px] h-[100px] rounded-[8px]"
                />
                <div
                  className="flex flex-col gap-[20px] items-start justify-center"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-[15px] font-semibold">Cloud storage</p>
                    <p className="text-[14px] text-[#9E9E9E]">
                      Available storage: 12 GB
                    </p>
                  </div>
                  <div
                    className="flex flex-row gap-[12px] justify-center"
                    style={{ alignItems: "center" }}
                  >
                    <p className="text-[14px] text-[#9E9E9E]">Quantity:</p>
                    <div className="flex flex-row gap-[3px] border border-[#E0E0E0] rounded-[8px] px-[10px] py-[3px] items-center justify-center">
                      <p className="text-[#9A9A9A] mr-[10px]">-</p>
                      <p className="text-[14px] font-semibold">500</p>
                      <p className="text-[#9A9A9A] ml-[10px]">+</p>
                    </div>
                    <div className="flex flex-row px-[12px] py-[5px] gap-[18px] border border-[#E0E0E0] items-center justify-center rounded-[8px]">
                      <p className="text-[14px] font-semibold">5GB</p>
                      <img
                        src={downArrow}
                        alt="down-arrow"
                        className="w-[10px] h-[4px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-[26px]">
                <p className="text-[14px] font-semibold">$ 20.00</p>
                <img src={deleteIcon} alt="delete" />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-[#E3E3E3] py-[12px] w-[535px]">
              <div
                className="flex flex-row gap-[20px] items-center justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <img
                  src={cloudRecording}
                  alt="cloud-recording"
                  className="w-[100px] h-[100px] rounded-[8px]"
                />
                <div
                  className="flex flex-col gap-[20px] items-start justify-center"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-[15px] font-semibold">Cloud Recording</p>
                    <p className="text-[14px] text-[#9E9E9E]">
                      Available Licenses: 10
                    </p>
                  </div>
                  <div
                    className="flex flex-row gap-[12px] justify-center"
                    style={{ alignItems: "center" }}
                  >
                    <p className="text-[14px] text-[#9E9E9E]">Quantity:</p>
                    <div className="flex flex-row gap-[3px] border border-[#E0E0E0] rounded-[8px] px-[10px] py-[3px] items-center justify-center">
                      <p className="text-[#9A9A9A] mr-[10px]">-</p>
                      <p className="text-[14px] font-semibold">5</p>
                      <p className="text-[#9A9A9A] ml-[10px]">+</p>
                    </div>
                    {/* <div className="flex flex-row px-[12px] py-[5px] gap-[18px] border border-[#E0E0E0] items-center justify-center rounded-[8px]">
                      <p className="text-[14px] font-semibold">5GB</p>
                      <img
                        src={downArrow}
                        alt="down-arrow"
                        className="w-[10px] h-[4px]"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-[26px]">
                <p className="text-[14px] font-semibold">$ 345.00</p>
                <img src={deleteIcon} alt="delete" />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-[#E3E3E3] py-[12px] w-[535px]">
              <div
                className="flex flex-row gap-[20px] items-center justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <img
                  src={videoConfrenceing}
                  alt="video"
                  className="w-[100px] h-[100px] rounded-[8px]"
                />
                <div
                  className="flex flex-col gap-[20px] items-start justify-center"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-[15px] font-semibold">
                      Video Conferencing(VC) & Webinar
                    </p>
                    <p className="text-[14px] text-[#9E9E9E]">
                      Available Licenses
                    </p>
                  </div>
                  <div
                    className="flex flex-row gap-[12px] justify-center"
                    style={{ alignItems: "center" }}
                  >
                    <p className="text-[14px] text-[#9E9E9E]">Quantity:</p>
                    <div className="flex flex-row gap-[3px] border border-[#E0E0E0] rounded-[8px] px-[10px] py-[3px] items-center justify-center">
                      <p className="text-[#9A9A9A] mr-[10px]">-</p>
                      <p className="text-[14px] font-semibold">10</p>
                      <p className="text-[#9A9A9A] ml-[10px]">+</p>
                    </div>
                    <div className="flex flex-row px-[12px] py-[5px] gap-[18px] border border-[#E0E0E0] items-center justify-center rounded-[8px]">
                      <p className="text-[14px] font-semibold">1-50</p>
                      <img
                        src={downArrow}
                        alt="down-arrow"
                        className="w-[10px] h-[4px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-[26px]">
                <p className="text-[14px] font-semibold">$ 2000</p>
                <img src={deleteIcon} alt="delete" />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-[#E3E3E3] py-[12px] w-[535px]">
              <div
                className="flex flex-row gap-[20px] items-center justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <img
                  src={cloudStorage}
                  alt="cloud-storage"
                  className="w-[100px] h-[100px] rounded-[8px]"
                />
                <div
                  className="flex flex-col gap-[20px] items-start justify-center"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-[15px] text-medium">Cloud storage</p>
                    <p className="text-[14px] text-[#9E9E9E]">
                      Available Credits: 23
                    </p>
                  </div>
                  <div
                    className="flex flex-row gap-[12px] justify-center"
                    style={{ alignItems: "center" }}
                  >
                    <p className="text-[14px] text-[#9E9E9E]">Quantity:</p>
                    <div className="flex flex-row gap-[3px] border border-[#E0E0E0] rounded-[8px] px-[10px] py-[3px] items-center justify-center">
                      <p className="text-[#9A9A9A] mr-[10px]">-</p>
                      <p className="text-[14px] font-semibold">500</p>
                      <p className="text-[#9A9A9A] ml-[10px]">+</p>
                    </div>
                    {/* <div className="flex flex-row px-[12px] py-[5px] gap-[18px] border border-[#E0E0E0] items-center justify-center rounded-[8px]">
                      <p className="text-[14px] font-semibold">5GB</p>
                      <img
                        src={downArrow}
                        alt="down-arrow"
                        className="w-[10px] h-[4px]"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-[26px]">
                <p className="text-[14px] font-semibold">$ 500</p>
                <img src={deleteIcon} alt="delete" />
              </div>
            </div>
          </div>
          <div className="flex flex-col px-[30px] py-[30px] gap-[30px] items-center justify-center bg-[#FFFFFF] rounded-[8px]">
            <div className="flex flex-col gap-[20px] items-center justify-center">
              <div className="flex flex-row gap-[60px] items-center justify-center">
                <p className="text-[14px] text-[#9E9E9E]">
                  Total Purchase amount
                </p>
                <p className="text-[14px] text-[#9E9E9E]">$2865.00</p>
              </div>
              <div className="flex flex-row gap-[105px] items-center justify-center">
                <p className="text-[14px] text-[#9E9E9E]">Applicable Taxes</p>
                <p className="text-[14px] text-[#9E9E9E]">$286.50</p>
              </div>
              <div className="flex flex-row gap-[50px] items-center justify-center">
                <p className="text-[15px] font-semibold">
                  Total Billable Amount
                </p>
                <p className="text-[15px] font-semibold">$3151.50</p>
              </div>
            </div>
            <div className="flex flex-col gap-[10px] items-center justify-center w-[276px]">
              <button className="py-[15px] px-[60px] text-[14px] bg-[#302C2C] text-[#FFFFFF] rounded-[9px]">
                Proceed to Checkout
              </button>
              <button className="py-[15px] text-[14px] px-[106px] bg-[#FFFFFF] text-[#302C2C] border border-[#9E9E9E] rounded-[9px]">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <Cards />
      </div>
    </div>
  );
};

export default AddOns2;
