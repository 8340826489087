import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import './EmblaCarousel.css'; // यहाँ पर आपको अपने स्टाइल्स को एडजस्ट करना होगा
import { Link } from 'react-router-dom';

const EmblaCarousel = ({ slides }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sliderRef = useRef(null);
  const progressRef = useRef([]);
  const progressValueRef = useRef([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (current) => {
      setSelectedIndex(current);
      resetProgress();
    },
  };

  const resetProgress = () => {
    progressRef.current.forEach((el, index) => {
      if (el) {
        el.style.width = '0%';
        progressValueRef.current[index] = 0;
      }
    });
  };

  useEffect(() => {
    const progressInterval = setInterval(() => {
      progressRef.current.forEach((el, index) => {
        if (el && index === selectedIndex) {
          const currentWidth = progressValueRef.current[index];
          const newWidth = Math.min(currentWidth + 0.3, 100);
          el.style.width = `${newWidth}%`;
          progressValueRef.current[index] = newWidth;

          if (newWidth === 100) {
            sliderRef.current.slickNext();
          }
        }
      });
    }, 15);

    return () => {
      clearInterval(progressInterval);
    };
  }, [selectedIndex]);

  return (
    <div className="embla">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div className="embla__slide" key={index}>
            <div className="embla__slide__inner">
              <Link className="hover:no-underline" to={`/blog/${slide.slug}`}>
                <div className="embla__slide__content">
                  <h2 className="md:text-[54px] text-[30px] tracking-[-0.96px]">{slide.title}</h2>
                  <p>{slide.date}</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
      <div className="embla__dots">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`embla__dot ${index === selectedIndex ? 'is-selected' : ''}`}
            onClick={() => sliderRef.current.slickGoTo(index)}
          >
            <div
              className="progress"
              ref={(el) => {
                progressRef.current[index] = el;
                progressValueRef.current[index] = 0;
              }}
              style={{ width: '0%' }}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default EmblaCarousel;
