import React from "react";
import WhyChoseUsCard from "./WhyChoseUsCard";

import InnovationImage from "../../assets/about/innovation.png";
import AccessibilityImage from "../../assets/about/accessibility.png";
import SupportImage from "../../assets/about/support.png";

const WhyChoseUs = () => {
  return (
    <div className="flex flex-col px-[1.25rem] md:pt-[4.5rem] py-12 md:pb-[6rem] items-center w-full gap-[1.125rem]">
      {/* header */}
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-[#E2B00B] md:text-[2rem] text-xl font-medium">
          Why chose Wriety
        </h1>
        <h2 className="md:text-[4rem] text-[1.5rem] text-[#202020] font-semibold text-center">
          We Believe In Good Ideas
          <br />
          Flexibility & Precision
        </h2>
      </div>

      {/* cards */}
      <div className="flex md:flex-row flex-col gap-6">
        <WhyChoseUsCard
          title="Innovation"
          image={InnovationImage}
          content="Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
        />
        <WhyChoseUsCard
          title="Accessibility"
          image={AccessibilityImage}
          content="Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
        />
        <WhyChoseUsCard
          title="Support"
          image={SupportImage}
          content="Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
        />
      </div>
    </div>
  );
};

export default WhyChoseUs;
