import Cards from "../add-ons/Cards";
import MarketCard from "./MarketCard";
import img1 from "../../assets/market-place/img-1.png";
import img2 from "../../assets/market-place/img-2.png";
import img3 from "../../assets/market-place/img-3.png";
import img4 from "../../assets/market-place/img-4.png";
import img5 from "../../assets/market-place/img-5.png";
import img6 from "../../assets/market-place/img-6.png";
import img7 from "../../assets/market-place/img-7.png";
import img8 from "../../assets/market-place/img-8.png";
import img9 from "../../assets/market-place/img-9.png";
import img10 from "../../assets/market-place/img-10.png";
import img11 from "../../assets/market-place/img-11.png";
import img12 from "../../assets/market-place/img-12.png";
import Facebook from "../../assets/market-place/f-logo.svg";
import Linkedin from "../../assets/brand-linkedin.svg";
import Youtube from "../../assets/market-place/youtube-logo.svg";
import { useState } from "react";
import "./MarketPlace.css"

const MarketPlace1 = () => {
  const [alignItems, setAlignItems] = useState(
    window.innerWidth >= 320 && window.innerWidth < 880
      ? "center"
      : "flex-start"
  );
  return (
    <div className="w-full">
      <div className="flex items-center justify-center relative pb-[100px]">
        <div
          className={`max-w-[1570px] w-full lg:px-[60px] pt-[140px] s:px-[20px] `}
        >
          <div
            className={`flex flex-col s:px-[0px] xmd:px-[25px] s:gap-[11px] xmd:gap-[20px]  justify-center relative`}
          >
            <div
              className="flex flex-row s:gap-[10px] xmd:gap-[15px] w-[80%] opacity-100"
              style={{ alignItems: alignItems }}
            >
              <p className="text-[#302C2CCC] s:text-[13px] xmd:text-[18px] font-semibold">
                Lessons
              </p>
              <p className="text-[#5a5a5acc] s:text-[13px] xmd:text-[18px] font-semibold">
                Lessons Plans
              </p>
              <p className="text-[#5a5a5acc] s:text-[13px] xmd:text-[18px] font-semibold">
                Activites
              </p>
              <p className="text-[#5a5a5acc] s:text-[13px] xmd:text-[18px] font-semibold">
                Templates
              </p>
            </div>
            <div className="flex flex-col gap-[20px] items-center justify-center opacity-100">
              <div
                className="flex s:flex-col s:w-full xmd:flex-row gap-[20px] items-start justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <MarketCard
                 className="bg-[#fff]"
                  img={img1}
                  title="Algebra Basics"
                  grade="Grade 7"
                  subject="Mathematics"
                  topic="Linear Equations"
                  views="5k views"
                  date="26th May"
                  time="00:45"
                  name="John Doe"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img2}
                  title="World War II History"
                  grade="Grade 10"
                  subject="History"
                  topic="World Wars"
                  views="13k views"
                  date="26th May"
                  time="01:00"
                  name="Jane Smith"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img3}
                  title="Basic Chemistry"
                  grade="Grade 9"
                  subject="Science"
                  topic="Chemical Reactions"
                  views="18k views"
                  date="26th May"
                  time="00:50"
                  name="Alice Johnson"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img4}
                  title="Introduction to Programming"
                  grade="Grade 11"
                  subject="Computer Science"
                  topic="Basics of Python"
                  views="2.2k views"
                  date="26th May"
                  time="00:40"
                  name="Bob Brown"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
              </div>

              <div
                className="flex s:flex-col s:w-full xmd:flex-row gap-[20px] items-start justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <MarketCard
                  img={img5}
                  title="Geometry Fundamentals"
                  grade="Grade 8"
                  subject="Mathematics"
                  topic="Shapes and Angles"
                  views="53k views"
                  date="26th May"
                  time="00:55"
                  name="Carol White"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img6}
                  title="Shakespeare's Plays"
                  grade="Grade 12"
                  subject="English Literature"
                  topic="Macbeth"
                  views="53k views"
                  date="26th May"
                  time="01:10"
                  name="David Green"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img7}
                  title="American Civil War"
                  grade="Grade 11"
                  subject="History"
                  topic="Civil War and Reconstruction"
                  views="53k views"
                  date="26th May"
                  time="01:00"
                  name="Emma Black"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img8}
                  title="Trigonometry"
                  grade="Grade 10"
                  subject="Mathematics"
                  topic="Trigonometric Ratios"
                  views="53k views"
                  date="26th May"
                  time="00:45"
                  name="Frank Harris"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
              </div>

              <div
                className="flex s:flex-col s:w-full xmd:flex-row gap-[20px] items-start justify-center"
                style={{ alignItems: "flex-start" }}
              >
                <MarketCard
                  img={img9}
                  title="Human Anatomy"
                  grade="Grade 12"
                  subject="Biology"
                  topic="The Circulatory System"
                  views="53k views"
                  date="26th May"
                  time="00:50"
                  name="Grace Lee"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img10}
                  title="Environmental Science"
                  grade="Grade 9"
                  subject="Science"
                  topic="Ecosystems"
                  views="53k views"
                  date="26th May"
                  time="00:40"
                  name="Henry King"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img11}
                  title="Medieval History"
                  grade="Grade 8"
                  subject="History"
                  topic="Feudalism"
                  views="53k views"
                  date="26th May"
                  time="00:55"
                  name="Irene Scott"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
                <MarketCard
                  img={img12}
                  title="Advanced Calculus"
                  grade="Grade 12"
                  subject="Mathematics"
                  topic="Integrals"
                  views="53k views"
                  date="26th May"
                  time="01:00"
                  name="Jack Martin"
                  // handleHover={handleHover}
                  // handleHoverOnMouseLeave={handleHoverOnMouseLeave}
                />
              </div>
            </div>

            <div
              className="z-10	absolute flex flex-col items-start justify-center s:w-max w-[695px] m-auto left-0 right-0 top-0 bottom-0 s:px-[20px]"
              style={{ alignItems: "flex-start" }}
            >
              <p className="s:text-[18px] xmd:text-[30px] font-semibold text-[#FFFFFF] left-0">
                Marketplace
              </p>
              <p className="s:text-[30px] xmd:text-[90px] font-bold text-[#FFFFFF]">
                COMING SOON
              </p>
              <p className="s:text-[10px] xmd:text-[20px]  text-[#FFFFFF]">
                We’re working hard to bring you an exciting new marketplace.
                Stay tuned!
              </p>

              <div
                className="flex s:flex-row justify-center items-center gap-[8px] mt-[10px] s:w-full"
                style={{ alignItems: "center" }}
              >
                {/*<button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#FFFFFF] rounded-[9999px]">
                  <img src={Facebook} alt="Facebook" />
                </button> */}
                <a
                href="https://www.linkedin.com/company/wriety/"
                target="_blank"
                rel="noopener noreferrer"
              >
                  <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#FFFFFF] rounded-[9999px]">
                  <img src={Linkedin} alt="Linkedin" />
                </button>
                  </a>
                 <a
                href="https://www.youtube.com/channel/UCpcvUecJxIaKaGZwsvxAL7g"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#FFFFFF] rounded-[9999px]">
                  <img src={Youtube} alt="Youtube" />
                </button>
                   </a>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap"></div>
      </div>
      <div className="bg-[#FAF9F8] pt-[80px] flex items-center justify-center">
        <div className="w-[1570px] xmd:px-[120px]">
          <Cards />
        </div>
      </div>
    </div>
  );
};

export default MarketPlace1;
