import React from "react";

import Dash from "../../assets/features/dash.svg";
import Check from "../../assets/features/check.svg";

const TableBody6 = () => {
  return (
    <tbody className=" border-collapse border border-[C7C7C7]">
      <tr>
        <td className="flex flex-col border border-[C7C7C7] md:px-4 md:pt-4 md:pb-6 p-2">
          <div className="relative ">
            <h1 className="md:text-[1.75rem] font-semibold leading-snug">
              Language Detection & Immersive Reader
            </h1>

            <div className="md:block hidden -z-10 absolute top-6 -left-0.5 h-[1.25rem] w-[20rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
            <div className="md:block hidden -z-10 absolute top-16 -left-0.5 h-[1.25rem] w-[16.25rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
          </div>
        </td>
        <td className="border border-[C7C7C7]" />
        <td className="border border-[C7C7C7]" />
        <td className="border border-[C7C7C7]" />
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 bg-[#FFF9E2]">
          Pen, Highlighter, Laser pen, Rainbow pen
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 ">
          Handwriting Recognition
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 bg-[#FFF9E2]">
          Written Equation to Latex converter
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 ">
          Shape Recognizer
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 bg-[#FFF9E2]">
          Erasers (Pixel, Element, Erase all)
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 ">
          PDF/PPT Viewer
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 bg-[#FFF9E2]">
          Periodic Table Elements
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 bg-[#FFF9E2]">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>

      <tr>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs md:px-4 md:pt-[0.938rem] md:pb-[0.563rem] p-2 ">
          Immersive Reader
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Dash}
            alt="dash"
            className="md:w-[2.563rem] w-[1.5rem] md:h-[2.563rem] mx-auto my-auto"
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
        <td className="border-x border-[C7C7C7] h-[3.188rem] md:text-lg text-xs px-4 ">
          <img
            src={Check}
            alt="dash"
            className="md:w-[1.75rem] w-[1rem] md:h-[1.75rem] h-[1rem] mx-auto my-auto "
          />
        </td>
      </tr>
    </tbody>
  );
};

export default TableBody6;
