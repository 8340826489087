import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForProfessionals from "../../assets/usecases/for-professional.png";
import NoteTakingIllustration from "../../assets/usecases/notetaking-illustration.svg";
import ForStudents from "../../assets/usecases/for-students.svg";

const Usecase11 = () => {
  return (
    <div className="relative w-full flex xl:flex-row flex-col xl:gap-0 gap-6 xl:pl-[6.25rem] xl:pr-[39.188rem] px-[1.25rem] xl:pt-[6.25rem] xl:pb-[19.375rem] py-6 bg-[#FFFBEB] justify-center">
      {/* content */}
      <div className="z-10 relative flex flex-col gap-[1rem] xl:w-[47rem] xl:mt-[1.5rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-tight">
            Note-taking
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-[1.2]">
            Professional Note-
            <br />
            taking
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:leading-[1.4] xl:tracking-tight">
          Professionals can use Wriety for taking detailed meeting notes. The
          app's features like text formatting, annotations, and multimedia
          support comprehensive note-taking.
        </p>

        {/* cards */}
        <div className="xl:block hidden absolute z-10 -bottom-[14rem] left-0">
          <UsecaseCard
            title="professionals"
            image={ForProfessionals}
            content="Professionals can use Wriety for taking detailed meeting notes."
          />
        </div>

        {/* illustration */}
        <img
          src={NoteTakingIllustration}
          alt="ilustration"
          className="xl:left-[42rem] xl:top-8 -z-10 xl:absolute xl:w-[38.188rem] xl:h-[25.438rem] max-w-[38.188rem] max-h-[25.438rem] xl:mx-0 md:mx-auto mx-0"
        />
      </div>

      <div className="xl:hidden">
        <UsecaseCard
          title="professionals"
          image={ForProfessionals}
          content="Professionals can use Wriety for taking detailed meeting notes."
        />
      </div>
    </div>
  );
};

export default Usecase11;
