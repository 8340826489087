import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForTeachers from "../../assets/usecases/for-teachers.svg";
import ForTeams from "../../assets/usecases/for-teams.svg";
import Illustration from "../../assets/usecases/brainstorming-illustration.svg";
import ForStudents from "../../assets/usecases/for-students.svg";

const Usecase4 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:gap-0 gap-6 xl:px-[6.25rem] px-[1.25rem] bg-[#FEFEFE] xl:py-[6.25rem] py-6 justify-center">
      {/* illustration */}
      <img
        src={Illustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] z-50 xl:mt-5 xl:mx-0 md:mx-auto mx-0"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[44.063rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium">
            Brainstorming
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Collaborative Brainstorming
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:w-[41.938rem]">
          Teams can brainstorm ideas on an infinite canvas, using text,
          drawings, and multimedia.
          <br />
          Features like chat, collaborative drawing, and templates enhance the
          process.
        </p>

        {/* cards */}
        <div className="xl:block hidden absolute -bottom-0 left-[0.625rem]">
          <UsecaseCard
            colored
            title="teams"
            image={ForTeams}
            content="Teams can brainstorm ideas on an infinite canvas, using text, drawings, and multimedia."
          />
        </div>
      </div>

      <div className="xl:hidden mt-8">
        <UsecaseCard
          colored
          title="teams"
          image={ForTeams}
          content="Teams can brainstorm ideas on an infinite canvas, using text, drawings, and multimedia."
        />
      </div>
    </div>
  );
};

export default Usecase4;
