import React from "react";

import TrustSection from "../components/TrustSection";
import HowItWorks from "../components/how-it-works/HowItWorks";

const HowItWorksPage = () => {
  return (
    <div className="md:mt-[6.5rem] mt-6">
      <HowItWorks />
      <TrustSection />;
    </div>
  );
};

export default HowItWorksPage;
