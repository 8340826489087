import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForInstitutions from "../../assets/usecases/for-institutions.svg";
import GamedesignIllustration from "../../assets/usecases/gamedesign-illustration.svg";

const Usecase9 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:pl-[6.25rem] xl:pr-[5rem] px-[1.25rem] xl:gap-[5.563rem] gap-6 xl:pt-[6.25rem] xl:pb-[6.25rem] py-6 bg-[#FFFBEB] justify-center">
      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[41.938rem] xl:mt-[1.5rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-tight">
            Game Design
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.25rem] font-semibold leading-[1.2]">
            Collaborative Game Design
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:leading-[1.4]">
          Game developers can use the app for collaborative game design and
          storyboarding. <br /> The infinite canvas allows for expansive game
          world planning.
        </p>

        {/* cards */}
        <div className="xl:block hidden absolute -bottom-[1rem] left-[2.2rem]">
          <UsecaseCard
            title="Institutions"
            image={ForInstitutions}
            content="Wriety can integrate with Learning Management Systems (LMS) for seamless Virtual Classroom"
          />
        </div>
      </div>

      {/* illustration */}
      <img
        src={GamedesignIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] xl:mx-0 md:mx-auto mx-0"
      />

      <div className="block xl:hidden">
        <UsecaseCard
          title="Institutions"
          image={ForInstitutions}
          content="Wriety can integrate with Learning Management Systems (LMS) for seamless Virtual Classroom"
        />
      </div>
    </div>
  );
};

export default Usecase9;
