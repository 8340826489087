import React, { useEffect, useState } from "react";
import StoryCard from "./StoryCard";
import ArrowLeft from "../../assets/home/arrow-left.svg";
import ArrowRight from "../../assets/home/arrow-right.svg";
import fetchData from "../../constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const StoriesSection = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData()
      .then(({ data }) => {
        const testimonials = data[0].acf.testimonials;
        if (!testimonials || !testimonials.length) return false;
        testimonials.forEach((item, index) => {
          item.id = index + 1;
        });
        // if (testimonials.length % 2 !== 0)
        //   testimonials.push({
        //     id: testimonials.length + 1,
        //     ...testimonials[0],
        //   });
        setData(testimonials);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const slider = React.useRef(null);
  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          centerPadding: '60px',
          centerMode: true,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          centerMode: true,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  const handlePrevClick = () => {
    slider.current.slickPrev();
  };

  const handleNextClick = () => {
    slider.current.slickNext();
  };


  return (
<div className="xl:py-[6.25rem] py-12 px-[1.25rem] flex flex-col items-center xl:gap-16 gap-8">
      <div className="flex flex-col lg:gap-[11.438rem]">
        {/* heading */}
        <div className="flex flex-col gap-[0.625rem] items-center">
          <div className="relative ">
            <h1 className="xl:text-[2.5rem] text-[1.5rem] font-semibold xl:pl-4">
              Real Stories, Real Impact
            </h1>

            <div className="xl:block hidden -z-10 absolute top-7 left-[6.7rem] h-[1.875rem] w-[9.188rem] bg-[#FFF9E2]" />
            <div className="xl:block hidden -z-10 absolute top-7 left-[23.2rem] h-[1.875rem] w-[9.188rem] bg-[#FFF9E2]" />
          </div>

          <h2 className="font-medium xl:text-lg text-center">
            Hear from Educators and Students Who've Transformed Learning with Wriety
          </h2>
        </div>
      </div>

      {/* storycards */}
      <div className="container overflow-hidden">
        <Slider ref={slider} {...settings}>
          {data.map((item) => (
            <StoryCard
            key={item.id}
            name={item.name}
            position={item.designation}
            picture={item.profile_image}
            content={item.testimonial}
            />
            ))}    
        </Slider>
      </div>

      {/* arrows */}
      {data.length > 1 && (
        <div className="flex gap-[1.25rem]">
          <button
            onClick={handlePrevClick}
            className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]"
            >
            <img
              src={ArrowLeft}
              alt="arrow left"
              className="h-[1.875rem] w-[1.875rem]"
              />
          </button>

          <button
            onClick={handleNextClick}
            className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]"
            >
            <img
              src={ArrowRight}
              alt="arrow right"
              className="h-[1.875rem] w-[1.875rem]"
              />
          </button>
        </div>
      )}
    </div>
  );
};

export default StoriesSection;
