import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmblaCarousel from "../EmblaCarousel";
import blogs from "./../../data/blog.json";
import whiteRightArrow from "./../../assets/project-menu/oem/white-right-arrow.svg";
import secondCardImage from "./../../assets/project-menu/advanced/Background.jpg";
import rightArrow from './../../assets/right-arrow.svg'
import blackRightArrow from "../../assets/project-menu/oem/blackRightArrow.svg";

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [recentSearchTerm, setRecentSearchTerm] = useState("");
  const [displayedBlogs, setDisplayedBlogs] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4);
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);
  useEffect(() => {
    setBlogPosts(blogs);
    setDisplayedBlogs(blogs.slice(0, visibleCount));
    setRecentPosts(blogs);
  }, [visibleCount]);

  useEffect(() => {
    const filteredBlogs = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedBlogs(filteredBlogs.slice(0, visibleCount));
  }, [searchTerm, visibleCount]);

  useEffect(() => {
    const filteredRecentBlogs = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(recentSearchTerm.toLowerCase())
    );
    setRecentPosts(filteredRecentBlogs);
  }, [recentSearchTerm]);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const slides = blogPosts.map((blog) => ({
    title: blog.title,
    date: blog.date,
    slug: blog.slug,
  }));

  return (
    <>
      <section className="bg-[#F6F4F2]">
        <div className="s:max-w-[94%] xl:max-w-[1280px] mx-auto py-[100px] md:pb-[100px] pb-[50px]">
          <div className="flex md:flex-row flex-col justify-between md:py-[80px] ">
            <h2 className="md:text-[72px] text-[40px] inter font-[500] md:pb-[0] pb-[10px]">Blog</h2>
            <p className="md:pb-[0] pb-[40px] md:w-[509px] w-[100%] md:text-[20px] md:leading-[30px] text-[#302C2CA6] font-[400]">
              Discover the latest updates in our newsletters and posts,
              featuring new features, release notes, and more. Stay informed and
              never miss out on what's new and exciting.
            </p>
          </div>
          <EmblaCarousel
            className="md:mt-[50px]"
            slides={slides}
            options={{ loop: false }}
          />
        </div>
      </section>

      <section className="bg-[#FFF]">
        <div className="mb:py-[120px] pb-0 s:max-w-[94%] xl:max-w-[1280px] mx-auto py-[80px]">
          <div className="flex justify-between">
            <h2 className="md:w-[50%] w-[50%] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500] tracking-[-.72px] text-[#000]">
              All Features
            </h2>
            <input
              className=" w-[20%] mob-100 border-[#E6E4E2!important] bg-transparent rounded-[8px!important] placeholder-[#00000099!important]"
              type="search"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap justify-between md:gap-[20px] gap-[20px] md:mt-[40px] mt-[40px]">
            {displayedBlogs.length > 0 ? (
              displayedBlogs.map((blog) => (
                <Link
                  className="hover:no-underline hover:text-[#000] md:w-[49%]"
                  to={`/blog/${blog.slug}`}
                  key={blog.id}
                >
                  <div className="box-card md:w-[100%] w-[100%] bg-[#F6F4F2] rounded-[10px]">
                    <img
                      className="rounded-tl-[10px] rounded-tr-[10px] md:w-full md:h-[291px] object-cover"
                      src={blog.image}
                      alt=""
                    />
                    <div className="md:p-[40px] p-[20px]">
                      <h2 className="md:pb-[20px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                        {blog.title}
                      </h2>
                      <p className="truncate-lines-2">{blog.description}</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center w-full">No search matches found.</p>
            )}
          </div>
          {visibleCount < blogPosts.length && (
            <div className="flex flex-col items-center md:mt-[80px] mt-[50px]">
              <button
                className="bg-[#000000] py-[9px] px-[18px] rounded-[8px] text-[#FFFFFF] xmd:text-[10px] lg:text-[12px] xl:text-[16px]"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            </div>
          )}

          <div className="flex justify-between md:mt-[150px] mt-[80px]">
            <h2 className="md:w-[50%] w-[50%] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500] tracking-[-.72px] text-[#000]">
              Recent Posts
            </h2>
            <input
              className="mob-100 w-[20%] border-[#E6E4E2!important] bg-transparent rounded-[8px!important] placeholder-[#00000099!important]"
              type="search"
              placeholder="Search..."
              value={recentSearchTerm}
              onChange={(e) => setRecentSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap justify-between md:gap-[20px] gap-[20px] md:mt-[40px] mt-[40px]">
            {recentPosts.length > 0 ? (
              recentPosts.map((blog) => (
                <Link
                  className="hover:no-underline hover:text-[#000] md:w-[49%]"
                  to={`/blog/${blog.slug}`}
                  key={blog.id}
                >
                  <div className="min-h-[400px] box-card md:w-[100%] w-[100%] bg-[#F6F4F2] rounded-[10px]">
                    <div className="md:p-[40px] p-[20px]">
                      <h2 className="md:pb-[20px] tracking-[-.72px] md:text-[36px] text-[26] leading-[32px] md:leading-[42px] font-[500]">
                        {blog.title}
                      </h2>
                      <p>{blog.description}</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center w-full">No search matches found.</p>
            )}
          </div>
          {visibleCount < blogPosts.length && (
            <div className="flex flex-col items-center md:mt-[80px] mt-[50px]">
              <button
                className="bg-[#000000] py-[9px] px-[18px] rounded-[8px] text-[#FFFFFF] xmd:text-[10px] lg:text-[12px] xl:text-[16px]"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            </div>
          )}

          <div className="flex items-center justify-center w-full md:mt-[150px] mt-[80px]">
            <div className="w-full max-w-[1570px] flex s:flex-col xmd:flex-row gap-[20px] text-[#ffffff]">
              <div className="flex flex-col s:w-full xmd:w-4/6 h-full rounded-[12px] overflow-hidden">
                <div className="bg-[#15315B] pt-[40px] pb-[40px] s:pb-[32px] sm:pb-[20px] h-[340px]">
                <h1 className="font-medium xmd:text-[28px] sm:pl-[40px] sm:pr-[100px] pl-[70px] pr-[210px] s:text-[19px] sm:text-[22px] lg:text-[48px] s:pr-[50px] s:pl-[18px] xmd:w-[332px] lg:w-[433px]">
            Try Wriety for <span className="text-[#AAC6F1]">free</span>
          </h1>
         
          <div
                    className="mt-[20px] s:ml-[20px] lg:ml-[40px] flex flex-row gap-[10px]  py-[9px] min-w-max rounded-[10px]"
                    style={{ width: "max-content" }}
                  >

                  <a href="https://app.wriety.com/web" rel="noreferrer"
                className="bg-[#ffffff] px-[18px] py-[9px] gap-[10px] rounded-[6px] flex flex-row items-center hover:no-underline hover-container"
              >
                <p className="text-[#302C2C] ">Get started</p>
                <div className="flex flex-row gap-[10px] relative overflow-hidden ">
                  <span className="absolute initial-slide-in">
                    <img
                      src={blackRightArrow}
                      alt="arrow"
                      className="w-[14px] h-[13px]"
                    />
                  </span>

                  <span className="initial-slide-out">
                    <img
                      src={blackRightArrow}
                      alt="arrow"
                      className="w-[14px] h-[13px]"
                    />
                  </span>
                </div>
              </a>
</div>


                </div>
                <div className="py-[30px] bg-[#496495] px-[70px] s:px-[25px] sm:px-[40px]">
                  <p className="text-[26px] text-[18px]">Schedule a demo</p>
                  <div className="w-[180px] mt-[20px]  flex flex-row gap-[9px]">
                    <div
                      className="flex flex-col gap-[5px] justify-center"
                      style={{ alignItems: "flex-start" }}
                      onMouseEnter={() => setIsHoverContactSales(true)}
                      onMouseLeave={() => setIsHoverContactSales(false)}
                    >
                      <Link
                        to="/contact-us"
                        className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                      >
                        <p className="leading-[12px] font-semibold">
                          Contact Sales
                        </p>
                        <span
                          className={`${isHoverContactSales ? "bounce-x" : ""}`}
                        >
                          <img
                            src={whiteRightArrow}
                            alt="Arrow"
                            className="w-[21px] h-[12px]"
                          />
                        </span>
                      </Link>
                      <div
                        className={`h-[2px] bg-[#fff] justify-end ${
                          isHoverContactSales
                            ? "w-[80%] transition-all"
                            : "w-[100%] transition-all"
                        }`}
                        style={{ willChange: "transform" }}
                      ></div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="w-full bg-cover rounded-[12px]">
                <img
                  src={secondCardImage}
                  alt="second card"
                  className="w-full s:h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogList;
