import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForCompanies from "../../assets/usecases/for-companies.png";
import ForEmployees from "../../assets/usecases/for-employees.png";
import CoorporateIllustration from "../../assets/usecases/corporate-illustration.svg";

const Usecase8 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:pl-[3.5rem] xl:pr-[5.313rem] px-[1.25rem] bg-[#FEFEFE] xl:pt-[4.5rem] xl:pb-[11.5rem] py-6 xl:gap-[3.25rem] gap-6 justify-center">
      {/* illustration */}
      <img
        src={CoorporateIllustration}
        alt="ilustration"
        className="xl:w-[34.375rem] xl:h-[23.75rem] max-w-[34.375rem] max-h-[23.75rem] z-50 xl:mt-[5.625rem] xl:mx-0 md:mx-auto mx-0"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[43.563rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-[37.6px]">
            Corporate
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Corporate Training
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:w-[43.563rem] ">
          Companies can use Wriety to develop training materials and interactive
          modules.
          <br />
          Employees can access these resources for onboarding and skill
          development.
        </p>

        {/* cards */}
        <div className="xl:flex hidden absolute -bottom-[9.5rem] -left-40 flex-col gap-6">
          <div className="ml-[5.5rem]">
            <UsecaseCard
              colored
              title="companies"
              image={ForCompanies}
              content="Companies can use Wriety to develop training materials and interactive modules."
            />
          </div>

          <UsecaseCard
            colored
            title="employees"
            image={ForEmployees}
            content="Employees can access these resources for onboarding and skill development."
          />
        </div>
      </div>

      <div className="flex xl:hidden flex-col gap-4">
        <UsecaseCard
          colored
          title="companies"
          image={ForCompanies}
          content="Companies can use Wriety to develop training materials and interactive modules."
        />

        <UsecaseCard
          colored
          title="employees"
          image={ForEmployees}
          content="Employees can access these resources for onboarding and skill development."
        />
      </div>
    </div>
  );
};

export default Usecase8;
