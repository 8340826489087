import React from "react";
import TrustSection from "../components/TrustSection";
import AboutMain from "../components/about/AboutMain";
import WhyChoseUs from "../components/about/WhyChoseUs";
import AboutWriety from "../components/about/AboutWriety";
import Achivements from "../components/about/Achivements";
import BlogPosts from "../components/about/BlogPosts";

const About = () => {
  return (
    <div>
      <AboutMain />
      <WhyChoseUs />
      <AboutWriety />
      <Achivements />
      <BlogPosts />
      <TrustSection />
    </div>
  );
};

export default About;
