import React from "react";

import HighlightsIllustration from "../../assets/download/highlights-illustration.svg";
import HighlightCard from "../download/HighlightCard";

const Achivements = () => {
  return (
    <div className="flex flex-col md:pl-[3.688rem] md:pr-[5.25rem] px-[1.25rem] md:pt-[5rem] md:pb-[6.25rem] py-8 items-center w-full md:gap-8">
      {/* header */}
      <div className="flex flex-col items-center">
        <h1 className="text-[#E2B00B] md:text-[2rem] text-xl font-medium">
          Statistics
        </h1>
        <h2 className="md:text-[4rem] text-[1.5rem] text-[#202020] font-semibold text-center mt-2">
          Our Achivements
        </h2>
        <h3 className="md:w-[42rem] text-center md:text-lg text-sm text-[#202020]">
          There are many variations of passages of available, Majority
          alteration by injected humour words eiusmod tempor incididunt blandit.
        </h3>
      </div>

      <div className="flex xl:flex-row flex-col gap-[1.875rem] items-center">
        {/* illustration */}
        <img
          src={HighlightsIllustration}
          alt="Highlights Illustration"
          className="xl:w-[32rem] xl:h-[32rem]"
        />

        {/* highlight cards */}
        <div className="w-full grid grid-cols-2 gap-x-6 gap-y-6">
          <HighlightCard heading="99%" subheading="Client Satisfaction" />
          <HighlightCard heading="1M" subheading="Pro users" />
          <HighlightCard heading="180+" subheading="Features" />
          <HighlightCard heading="800k" subheading="Active Users" />
        </div>
      </div>
    </div>
  );
};

export default Achivements;
