import React from "react";

import AboutIllustration2 from "../../assets/about/about-illustration2.svg";

const AboutWriety = () => {
  return (
    <div className="w-full px-[1.25rem] bg-[#FFFBEB] flex justify-center lg:py-[5.406rem] py-6 lg:pl-[5.25rem] lg:pr-[4.313rem] items-center">
      {/* content */}
      <div className=" flex flex-col lg:gap-8 w-full mt-[1.7rem]">
        <div className="flex flex-col gap-2">
          <h1 className="text-[#E2B00B] lg:text-[2rem] text-xl font-medium">
            About wriety
          </h1>
          <h2 className="text-[#202020] lg:text-[3.25rem] text-[1.5rem] font-semibold leading-snug">
            Revolutionizing
            <br />
            Education with
            <br />
            Smartboard Solutions
          </h2>
        </div>

        {/* illustration */}
        <img
          src={AboutIllustration2}
          alt="illustration"
          className="lg:hidden lg:w-[40.688rem] lg:h-[40.688rem]"
        />

        <p className="lg:text-lg text-[#202020] lg:leading-snug">
          At Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate and engage with content.At
          Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate and engage with content.
          <br />
          <br />
          We believe that education should be dynamic, interactive, and
          accessible to all. With this vision in mind, we've developed a
          comprehensive smart whiteboard platform that offers an array of
          innovative features to elevate the teaching and learning experience.We
          believe that education should be dynamic, interactive, and accessible
          to all. With this vision in mind, we've developed a comprehensive
          smart whiteboard platform that offers an array of innovative features
          to elevate the teaching and learning experience.
        </p>
      </div>

      {/* illustration */}
      <img
        src={AboutIllustration2}
        alt="illustration"
        className="lg:block hidden w-[40.688rem] h-[40.688rem]"
      />
    </div>
  );
};

export default AboutWriety;
