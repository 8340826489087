import React, {useState, useEffect } from "react";
import fetchData from "../constant";

// import Brand1 from "../assets/home/brand1.svg";
// import Brand2 from "../assets/home/brand2.svg";
// import Brand3 from "../assets/home/brand3.svg";
// import Brand4 from "../assets/home/brand4.png";
// import Brand5 from "../assets/home/brand5.png";
// import Brand6 from "../assets/home/brand6.png";

// const dataa = [
//   { id: 1, image: Brand1 },
//   { id: 2, image: Brand2 },
//   { id: 3, image: Brand3 },
//   { id: 4, image: Brand4 },
//   { id: 5, image: Brand5 },
//   { id: 6, image: Brand6 },
//   { id: 7, image: Brand2 },
//   { id: 8, image: Brand1 },
//   { id: 9, image: Brand4 },
//   { id: 10, image: Brand3 },
//   { id: 11, image: Brand6 },
//   { id: 12, image: Brand5 },
//   { id: 13, image: Brand4 },
//   { id: 14, image: Brand2 },
//   { id: 15, image: Brand5 },
//   { id: 16, image: Brand1 },
//   { id: 17, image: Brand6 },
//   { id: 18, image: Brand3 },
// ];


const TrustSection = () => {
  useEffect(() => {
    fetchData().then(({data}) => {
      const company_logos = data[2].acf.company_logos;
      if (!company_logos || !company_logos.length) return false;
      company_logos.forEach((item, index) => {  item.id = index + 1; });
      setData(company_logos);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const [data, setData] = useState([]);
  const [active, setActive] = useState(1);

  const [logosPerSlide, setLogosPerSlide] = useState();

  useEffect(() => {
    const updateLogosPerSlide = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1024) {
        setLogosPerSlide(6);
      } else if (screenWidth > 768) {
        setLogosPerSlide(4);
      } else{
        setLogosPerSlide(3);
      }
    };

    updateLogosPerSlide();
    window.addEventListener("resize", updateLogosPerSlide);

    return () => {
      window.removeEventListener("resize", updateLogosPerSlide);
    };
  }, [data]);


  
  return (
    <div className="py-[2.75rem] overflow-hidden bg-gradient-to-b from-[#FBF6E73D] to-[#FBF6E782] px-0 flex flex-col items-center md:gap-[4.125rem] gap-8 justify-center">
      {/* heading */}
      {/* <div className="relative">
        <h1 className="md:text-[2.5rem] text-[1.5rem] font-semibold">
          Trusted by Teams at
        </h1> 

       <div className="hidden md:block -z-10 absolute bottom-[0.1rem] left-0 h-[1.875rem] w-[10.188rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
      </div> */}

      <div className="flex">
        {data && data
          .filter((item) => item.id >= active && item.id < active + logosPerSlide)
          .map((item) => {
            return (
              <img
                key={item.id}
                src={item.logo}
                alt="brand image"
                className="xl:h-[2.938rem] h-[1.8rem] flex-1 xl:mx-[2rem] mx-[16px] md:m-[2rem]"
              />
            );
          })}
      </div>

      {/* buttons */}
      <div className="flex gap-6">
        <button
          className={`${
            active === 1
              ? "bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]"
              : "bg-gray-300"
          } h-4 w-4  rounded-full`}
          onClick={() => setActive(1)}
        />
        {data.length > logosPerSlide && <button
          className={`${
            active === logosPerSlide + 1
              ? "bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]"
              : "bg-gray-300"
          } h-4 w-4 rounded-full`}
          onClick={() => setActive(logosPerSlide + 1)}
        />}
        {data.length > 2 * logosPerSlide && <button
          className={`${
            active === logosPerSlide * 2 + 1
              ? "bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]"
              : "bg-gray-300"
          } h-4 w-4 rounded-full`}
          onClick={() => setActive(logosPerSlide * 2 + 1)}
        />}
      </div>
    </div>
  );
};

export default TrustSection;
