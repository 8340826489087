import axios from 'axios';

const fetchData = async () => {
  try {
    return axios.get('https://wriety.jethitech.com/wp-json/wp/v2/pages?_fields=acf&acf_format=standard');
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default fetchData;