import { useEffect } from "react";
import $ from "jquery";
import "./LandingPage.css";
import monkeyImg from "../../assets/landing-page/monkey-img.svg";
import item1Icon from "../../assets/landing-page/item1-icon.svg";
import item2Icon from "../../assets/landing-page/item2-icon.svg";
import item3Icon from "../../assets/landing-page/item3-icon.svg";
import item4Icon from "../../assets/landing-page/item4-icon.svg";
import item5Icon from "../../assets/landing-page/item5-icon.svg";
import item6Icon from "../../assets/landing-page/item6-icon.svg";
import item7Icon from "../../assets/landing-page/item7-icon.svg";
import item8Icon from "../../assets/landing-page/item8-icon.svg";

const JungleeAI = () => {
  useEffect(() => {
    let progressContainer = document.getElementById("ferris_wheel");

    if (!progressContainer) {
      console.error("Element with ID 'ferris_wheel' not found in the DOM.");
      return;
    }

    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting) {
          $(".circlerotate .InnerTitle").animate(
            {
              opacity: 1,
            },
            1000,
            "linear"
          );

          $(".circlerotate .Innerbg").animate(
            {
              opacity: 1,
            },
            2000,
            "linear"
          );

          setTimeout(function () {
            $(".circlerotate .Innerbg").css({
              opacity: 1,
              "transition-timing-function": "linear",
            });
          }, 1000);

          setTimeout(function () {
            $(".ferris_wheel").css({
              opacity: 1,
              transform: "translate(-50%, -50%) scale(1)",
              "transition-timing-function": "linear",
            });
          }, 1000);

          setTimeout(function () {
            $(".circlerotate").css({
              border: "5px solid #b9b9b9",
              background: "#dee1f2",
              "transition-timing-function": "linear",
            });
          }, 3000);
        }
      },
      {
        threshold: 0.5, // Adjust this value to control when the animation starts (0.5 means 50% of the element is visible)
      }
    );

    observer.observe(progressContainer);
  });
  return (
    // <div className="container-fluid">
    <div className="justify-center bg-[#449792] rounded-[14px] w-[100%] lg:w-[1270px] overflow-y-hidden">
      <div className="circlerotate">
        <div className="Inner">
          <span className="Innerbg" style={{ opacity: "0" }}></span>
          <img src={monkeyImg} alt="monkey" className="monkeyImg" />
        </div>
        <div className="ferris_wheel" id="ferris_wheel">
          <div className="buckets wrapper circle ">
            <div className="item 1 active">
              <div className="label bg-[#297470]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item1Icon} alt="item1" />
                </div>
              </div>
            </div>
            <div className="item 2">
              <div className="label bg-[#753534]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item2Icon} alt="item2" />
                </div>
              </div>
            </div>
            <div className="item 3">
              <div className="label bg-[#297470]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item3Icon} alt="item3" />
                </div>
              </div>
            </div>
            <div className="item 4">
              <div className="label bg-[#753534]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item4Icon} alt="item4" />
                </div>
              </div>
            </div>
            <div className="item 5">
              <div className="label bg-[#297470]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item5Icon} alt="item5" />
                </div>
              </div>
            </div>
            <div className="item 6">
              <div className="label bg-[#753534]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item6Icon} alt="item6" />
                </div>
              </div>
            </div>
            <div className="item 7">
              <div className="label bg-[#297470]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item7Icon} alt="item7" />
                </div>
              </div>
            </div>
            <div className="item 8">
              <div className="label bg-[#753534]">
                <div className="w-[30px] h-[30px] p-[2px]">
                  <img src={item8Icon} alt="item8" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default JungleeAI;
