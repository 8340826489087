import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForTeachers from "../../assets/usecases/for-teachers.svg";
import BuisnessIllustration from "../../assets/usecases/buisness-illustration.svg";
import ForStudents from "../../assets/usecases/for-students.svg";

const Usecase2 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col  xl:px-[6.25rem] px-[1.25rem] bg-[#FEFEFE] xl:py-[6.25rem] py-6 xl:gap-[4.375rem] gap-8 justify-center">
      {/* illustration */}
      <img
        src={BuisnessIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] xl:mx-0 md:mx-auto mx-0 z-50 xl:mt-2"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[41.938rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-[48px]">
          Bussiness
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Business Presentation
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:w-[40rem]">
          Professionals can use Wriety to design and deliver impactful
          presentations.Integration with screen sharing and video tools allows
          for remote presentations.
        </p>

        {/* cards */}
        <div className="hidden xl:block absolute bottom-8 -left-24">
          <UsecaseCard
            colored
            title="professionals"
            image={ForStudents}
            content="Professionals can use Wriety to design and deliver impactful
            presentations."
          />
        </div>
      </div>

      <div className="xl:hidden block">
        <UsecaseCard
          colored
          title="professionals"
          image={ForStudents}
          content="Professionals can use Wriety to design and deliver impactful
            presentations."
        />
      </div>
    </div>
  );
};

export default Usecase2;
