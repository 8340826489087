import notSayMoreImage from "../../assets/landing-page/we-not-say-more.svg";
import messageIcon from "../../assets/landing-page/message-icon.svg";
import { useEffect, useState } from "react";
import "./LandingPage.css"
import Marquee from "react-fast-marquee";



const SliderAnimation = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div
      className="slider-container md:py-[140px] s:py-[70px] w-full"
      id="slider-container"
    >
       <Marquee direction="right">
        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px]">@Pan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px] ">
                  Pan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very satisfied with your software, it is very powerful,
                and our cooperation has made our products a lot
                more competitive.
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:md:text-[13px] s:text-[11px]  text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:md:text-[13px] s:text-[11px]  text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:md:text-[13px] s:text-[11px]  text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@danp128</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Dan Peterson
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are thrilled with the power of your software, @Wriety which
                has made our products more competitive. - Karthik
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@simonw</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Simon Willison
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                @Wriety is robust and has greatly enhanced our product
                competitiveness through our collaboration. - Josh (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@colek42c</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Cole Kennedy
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very pleased with your powerful software @Wriety. Our
                cooperation has made our products a lot more competitive. - Rita
                (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@tlesher</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Tim Lesher
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@Pan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Pan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very satisfied with your software, it is very powerful,
                and our cooperation has made our products a lot
                more competitive.
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@danp128</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Dan Peterson
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are thrilled with the power of your software, @Wriety which
                has made our products more competitive. - Karthik
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@simonw</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Simon Willison
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                @Wriety is robust and has greatly enhanced our product
                competitiveness through our collaboration. - Josh (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[53px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@colek42c</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Cole Kennedy
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very pleased with your powerful software @Wriety. Our
                cooperation has made our products a lot more competitive. - Rita
                (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@tlesher</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Tim Lesher
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Marquee>


        <Marquee direction="left" className="md:pt-[20px] md:pb-[20px] s:pt-[20px]">
    
        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@jgavinray</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  J. Gavin Ray
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                @Wriety is impressive and has made our products more
                competitive, thanks to our excellent cooperation. - Khasim
                (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[100px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@dixdotdev</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  big jim
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[75px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@TheodoreJSalvo</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Theo Salvo
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very pleased with your powerful software @Wriety. Our
                cooperation has made our products a lot more competitive. - Rita
                (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[74px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@falsefalse</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  llia
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are highly satisfied with the power of @Wriety. Our
                cooperation has made our products much more competitive. -
                Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[74px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@bensonk42</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Benson Kalahar
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[75px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@jgavinray</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  J. Gavin Ray
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                @Wriety is impressive and has made our products more
                competitive, thanks to our excellent cooperation. - Khasim
                (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[100px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@dixdotdev</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  big jim
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[75px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@TheodoreJSalvo</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Theo Salvo
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are very pleased with your powerful software @Wriety. Our
                cooperation has made our products a lot more competitive. - Rita
                (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[74px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@falsefalse</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  llia
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are highly satisfied with the power of @Wriety. Our
                cooperation has made our products much more competitive. -
                Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[74px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@bensonk42</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Benson Kalahar
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                So I decided to reserve this morning to read up on @Wriety and
                get it set up on my various machines at home. That took a hot 30
                minutes and I have to find something else to do. 🤷
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[75px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Marquee>
      {innerWidth > 768 && (
        
        <Marquee direction="right" className="pt-[0px]">  
        

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@plasticine</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Justin Morris
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We're extremely satisfied with the power of your software
                @Wriety. Our partnership has made our products significantly
                more competitive. - Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@jashankj</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Jashank Jeremy
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                Your software's @Wriety power is undeniable, and our cooperation
                has made our products far more competitive. - Pan (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[55px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@LiamJKeegan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Liam Keegan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                The powerful software @Wriety has significantly boosted our
                product's competitiveness. We are very satisfied. - Rita (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@matolucina</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Martin Lucina
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are highly satisfied with the power of @Wriety. Our
                cooperation has made our products much more competitive. -
                Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@LiamJKeegan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Liam Keegan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                The powerful software @Wriety has significantly boosted our
                product's competitiveness. We are very satisfied. - Rita (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@plasticine</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Justin Morris
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We're extremely satisfied with the power of your software
                @Wriety. Our partnership has made our products significantly
                more competitive. - Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@jashankj</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Jashank Jeremy
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                Your software's @Wriety power is undeniable, and our cooperation
                has made our products far more competitive. - Pan (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[55px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@LiamJKeegan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Liam Keegan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                The powerful software @Wriety has significantly boosted our
                product's competitiveness. We are very satisfied. - Rita (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@matolucina</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Martin Lucina
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                We are highly satisfied with the power of @Wriety. Our
                cooperation has made our products much more competitive. -
                Srikanth (crown)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slide-item">
          <div className="blog_post">
            <div className="container_copy flex flex-col items-start justify-start gap-[25px]">
              <div className="flex flex-col gap-[2px]">
                <h4 className="md:text-[15px] s:text-[12px] ">@LiamJKeegan</h4>
                <p className="text-[#FFFFFF66] md:text-[13px] s:text-[11px]">
                  Liam Keegan
                </p>
              </div>
              <p className="md:text-[16px] s:text-[13px]">
                The powerful software @Wriety has significantly boosted our
                product's competitiveness. We are very satisfied. - Rita (Dahua)
              </p>
              <div className="flex flex-row items-start justify-center gap-[15px] mt-[30px]">
                <div className="flex flex-row gap-[6px] items-start justify-center">
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-[16px] h-[16px]"
                  />
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Reply
                  </p>
                </div>
                <div className="flex flex-row gap-[15px] items-start justify-center">
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A]">
                    Share
                  </p>
                  <p className="md:text-[13px] s:text-[11px] text-[#FFFFFF8A] mb-[8px]">
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Marquee>)}
      <div
        className="bg-[#302C2C] absolute w-[96%] s:w-[300px] xs:w-[400px] lg:w-[500px]  h-[50px] lg:h-[80px] text-[18px]  lg:text-[33px] left-0 right-0 bottom-0 top-0 m-auto text-[#ffffff] flex items-center justify-center shadow-box rounded-[14px]"
        style={{ zIndex: "4000" }}
      >
        we couldn't have said more
      </div>
    </div>
  );
};

export default SliderAnimation;
