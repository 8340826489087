import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForCompanies from "../../assets/usecases/for-companies.png";
import ForContentCreators from "../../assets/usecases/for-contentcreators.png";
import ContentCreationIllustration from "../../assets/usecases/contentcreation-illustration.svg";

const Usecase10 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:gap-[5.75rem] gap-6 xl:pl-[3.5rem] xl:pr-[7.125rem] px-[1.25rem] bg-[#FEFEFE] xl:pt-[4rem] xl:pb-[11.5rem] py-6 justify-center">
      {/* illustration */}
      <img
        src={ContentCreationIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] z-50 xl:mt-[3rem] xl:mx-0 md:mx-auto mx-0"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[43.563rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-tight">
            Content Creation
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Marketplace for
            <br />
            Educational Content
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:w-[43.563rem] xl:leading-snug">
          Educators and content creators can share lesson plans, quizzes, and
          digital books.
          <br />
          Students can access and review content shared on the marketplace.
        </p>

        {/* cards */}
        <div className="hidden absolute bottom-12 -right-4 xl:flex flex-col gap-6">
          <UsecaseCard
            colored
            title="Content creator"
            image={ForContentCreators}
            content="Companies can use Wriety to develop training materials and interactive modules."
          />
        </div>
      </div>

      <div className="xl:hidden">
        <UsecaseCard
          colored
          title="Content creator"
          image={ForContentCreators}
          content="Companies can use Wriety to develop training materials and interactive modules."
        />
      </div>
    </div>
  );
};

export default Usecase10;
