import React from 'react'
import OSMain from '../components/wriety-os/OSMain'
import OSImage from '../components/wriety-os/OSImage'

function WrietyOS() {
  return (
    <div>
        <OSMain />
        <OSImage />
    </div>
  )
}

export default WrietyOS