import { useEffect, useRef } from "react";
import $ from "jquery";
// import  gsap from "gsap";
// import gsap from "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.8.0/gsap.min.js"
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./LandingPage.css";
import brainImage from "../../assets/landing-page/artificial 1.svg";
import AIAnimationImg from "../../assets/landing-page/AI-animation-img.svg";

// gsap.registerPlugin(ScrollTrigger);
const WheelAnimation = () => {
  useEffect(() => {
    // GSAP and ScrollTrigger Animation
    var lastScroll = 0;
    const label = document.querySelectorAll(".aicontainer");
    if (label && label.length <= 0) {
      return;
    }

    const handleScroll = () => {
      // const scrollY = window.scrollY;
      console.log("handle scroll triggered...");
      window.gsap.fromTo(
        label,
        { opacity: 1 },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: label,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
            direction: 1,
            toggleClass: {
              targets: ".AIanimator",
              className: "animate",
            },
            onUpdate: (self) => {
              console.log("inside on update");
              var ProgressPercentage =
                50 - Math.round(self.progress.toFixed(3) * 100);

              var TransformScale = 0.6 + parseFloat(self.progress.toFixed(3));
              if (TransformScale > 1) {
                TransformScale = 1;
              }
              if (ProgressPercentage > 0 && lastScroll <= ProgressPercentage) {
                lastScroll = ProgressPercentage;
                $(".outline-cicle-light").css({
                  transform: "scale(-" + TransformScale + ")",
                });
                $(".outline-cicle-dark").css({
                  transform: "scale(-" + TransformScale + ")",
                });
                $(".inner-circle").css({
                  transform: "scale(-" + TransformScale + ")",
                });
                $(".ip-container").css({
                  transform: "rotate(-" + ProgressPercentage + "deg)",
                });
                $(".ip-containerparentdiv").css({
                  transform: "rotate(" + ProgressPercentage + "deg)",
                });
              } else {
                $(".outline-cicle-light").css({
                  transform: "scale(" + TransformScale + ")",
                });
                $(".outline-cicle-dark").css({
                  transform: "scale(" + TransformScale + ")",
                });
                $(".inner-circle").css({
                  transform: "scale(" + TransformScale + ")",
                });
                $(".ip-container").css({
                  transform: "rotate(-" + ProgressPercentage + "deg)",
                });
                $(".ip-containerparentdiv").css({
                  transform: "rotate(+" + ProgressPercentage + "deg)",
                });
              }

              if (ProgressPercentage > 20) {
                $(".logo").css({ "clip-path": "inset(18% round 30px)" });
              } else {
                $(".logo").css({ "clip-path": "inset(0% round 30px)" });
              }
            },
          },
        }
      );
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="container-fluid flex flex-col bg-[#ffffff] items-center lg:pt-[100px] pt-[40px]  justify-center lg:gap-[100px] gap-[40px] pb-[20px] lg:pb-[100px]">
      <div className="mt-[40px] lg:mt-[100px] mb-[35px] aicontainer  lg:w-[100%] w-[96%] mx-auto">
        <div className="mx-auto aspect-[654/508] lg:max-w-[654px] max-w-[96%] xl:min-h-[508px] lg:min-h-[508px] min-h-[auto]">
          <div className="flex w-full justify-center">
            <div className="relative aspect-[654/508] w-full max-w-[654px] xl:min-h-[508px] lg:min-h-[508px] min-h-[auto]">
              <div className="absolute left-[50%] top-[50%] aspect-1 w-full max-w-[78%] -translate-x-1/2 -translate-y-1/2">
                <div
                  className="outline-cicle-light relative h-full w-full rounded-full border-[1.5px]  border-grey-2"
                  style={{ transform: "scale(0.8) translateZ(0px)" }}
                ></div>
              </div>
              <div className="absolute left-[50%] top-[50%] aspect-1 w-full max-w-[70.86%] -translate-x-1/2 -translate-y-1/2">
                <div
                  className="outline-cicle-dark relative h-full w-full rounded-full border-[1.5px] border-grey-4"
                  style={{ transform: "scale(0.8) translateZ(0px)" }}
                ></div>
              </div>
              <div className="absolute left-[50%] top-[50%] aspect-1 w-full max-w-[52.15%] -translate-x-1/2 -translate-y-1/2">
                <div
                  className="inner-circle relative h-full w-full rounded-full bg-grey-2"
                  style={{ transform: "scale(0.6) translateZ(0px)" }}
                ></div>
              </div>
              <div className="absolute left-[50%] top-[50%] aspect-1 w-full max-w-[18.4%] -translate-x-1/2 -translate-y-1/2">
                <div
                  className="logo relative h-full w-full bg-white rounded-[9999px]"
                  style={{ clipPath: "inset(0% round 30px)" }}
                >
                  <div
                    className="absolute inset-[8%] bg-[#242424] rounded-[9999px]"
                    style={{ clipPath: "inset(0% round 20px)" }}
                  ></div>
                  <div
                    className="absolute inset-0 flex items-center justify-center"
                    style={{ opacity: 1 }}
                  >
                    <img
                      src={brainImage}
                      alt="brain"
                      className="s:w-[30px] s:h-[30px] xs:w-[38px] xs:h-[38px] sm:w-[48px] sm:h-[48px]"
                    />
                  </div>
                </div>
              </div>
              <div className="absolute left-[50%] top-[50%] aspect-1 w-full max-w-[80%] -translate-x-1/2 -translate-y-1/2">
                <div
                  className="relative h-full w-full ip-containerparentdiv"
                  style={{ transform: "rotate(-50deg) translateZ(0px)" }}
                >
                  <div
                    className="ip-container absolute left-[30%] top-[10%] w-full max-w-[51%]"
                    style={{ transform: "rotate(50deg) translateZ(0px)" }}
                  >
                    <div className="t-18-mono relative w-full items-center justify-center rounded-full p-1 font-sfMono text-heading-black sm:p-[10px]">
                      <div className="absolute inset-0 h-full w-full rounded-full bg-white"></div>
                      <div className="relative block w-full rounded-full  bg-grey-3 px-2 py-2 text-center xs:py-[11px] md:px-6">
                        <span className="s:text-[6px] xs:text-[8px] sm:text-[11px] md:text-[15px] text-[#302C2C]">
                          Math Formula and Arithmetic
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ip-container absolute left-[-10%] top-[45%] w-full max-w-[38.2%]"
                    style={{ transform: "rotate(50deg) translateZ(0px)" }}
                  >
                    <div className="t-18-mono relative w-full items-center justify-center rounded-full p-1 font-sfMono text-heading-black sm:p-[10px]">
                      <div className="absolute inset-0 h-full w-full rounded-full bg-white"></div>
                      <div className="relative block w-full rounded-full  bg-grey-3 px-2 py-2 text-center xs:py-[11px] md:px-6">
                        <span className="s:text-[6px] xs:text-[8px] sm:text-[11px] md:text-[15px] text-[#302C2C]">
                          194 Languages
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ip-container absolute right-[-15%] top-[45%] w-full max-w-[42.4%]"
                    style={{ transform: "rotate(50deg) translateZ(0px)" }}
                  >
                    <div className="t-18-mono relative w-full items-center justify-center rounded-full p-1 font-sfMono text-heading-black sm:p-[10px]">
                      <div className="absolute inset-0 h-full w-full rounded-full bg-white"></div>
                      <div className="relative block w-full rounded-full  bg-grey-3 px-2 py-2 text-center xs:py-[11px] md:px-6">
                        <span className="s:text-[6px] xs:text-[8px] sm:text-[11px] md:text-[15px] text-[#302C2C]">
                          Freehand Flowsheets
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ip-container absolute bottom-[11%] left-[30%] w-full max-w-[40.2%]"
                    style={{ transform: "rotate(50deg) translateZ(0px)" }}
                  >
                    <div className="t-18-mono relative w-full items-center justify-center rounded-full p-1 font-sfMono text-heading-black sm:p-[10px]">
                      <div className="absolute inset-0 h-full w-full rounded-full bg-white"></div>
                      <div className="relative block w-full rounded-full  bg-grey-3 px-2 py-2 text-center xs:py-[11px] md:px-6">
                        <span className="s:text-[6px] xs:text-[8px] sm:text-[11px] md:text-[15px] text-[#302C2C]">
                          Doodle drawing
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img src={AIAnimationImg} alt="AI-Animation-img" /> */}
      <div className="flex flex-col lg:gap-[35px] gap-[47px] ">
        <div className="">
          <h3 className="lg:text-[58px] text-[35px] text-center font-semibold">
            Artificial Intelligence
          </h3>
        </div>
        <div className="text-[#302C2CA6] text-center">
          <p className="lg:text-[20px] text-[16px] ">
            Online and offline AI capabilities bringing seamless <br />{" "}
            productivity effortlessly.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WheelAnimation;
