import React from "react";

import UsecaseCard from "../../components/usecases/UsecaseCard";

import ForTeachers from "../../assets/usecases/for-teachers.svg";
import LearningIllustration from "../../assets/usecases/learning-illustration.svg";
import ForStudents from "../../assets/usecases/for-students.svg";

const Usecase1 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col  xl:gap-[4.313rem] xl:pl-[6.25rem] xl:pr-[2.313rem] px-[1.25rem] xl:pt-[6.5rem] xl:pb-[12.625rem] py-6 bg-[#FFFBEB] justify-center">
      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[41.938rem] xl:mt-[2rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-[48px]">
            Learning
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.25rem] font-semibold leading-tight">
            Language Learning
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020]">
          Language instructors can utilize Wriety for interactive language
          lessons.Language learners can practice writing and pronunciation using
          various tools.
        </p>

        {/* cards */}
        <div className="hidden absolute -bottom-[8rem] left-[1.063rem] xl:flex flex-col gap-6">
          <UsecaseCard
            title="learners"
            image={ForStudents}
            content="Language learners can practice writing and pronunciation using various tools."
          />

          <div className="ml-[11.625rem]">
            <UsecaseCard
              title="instructors"
              image={ForTeachers}
              content="Language instructors can utilize Wriety for interactive language lessons."
            />
          </div>
        </div>
      </div>

      {/* illustration */}
      <img
        src={LearningIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] z-50 xl:mx-0 md:mx-auto mx-0"
      />

      {/* cards */}
      <div className="xl:hidden flex flex-col gap-4">
        <UsecaseCard
          title="learners"
          image={ForStudents}
          content="Language learners can practice writing and pronunciation using various tools."
        />

        <UsecaseCard
          title="instructors"
          image={ForTeachers}
          content="Language instructors can utilize Wriety for interactive language lessons."
        />
      </div>
    </div>
  );
};

export default Usecase1;
