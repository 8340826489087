import React from "react";

import Dash from "../../assets/pricing/minus.svg";
import Check from "../../assets/pricing/circle-check.svg";
import Checkbox from "../../assets/pricing/checkbox.svg";

const PricingTable = () => {
  return (
    <table className="w-full">
      <tbody className=" border-collapse border border-[C7C7C7]">
        <tr className="xl:hidden">
          <td className="border-[C7C7C7] bg-[#FFF9E2]"></td>
          <td className="md:w-[21.938rem] min-w-[4.6rem] border-[C7C7C7] text-center py-4 font-semibold text-lg md:px-4 bg-[#FFF9E2]">
            Basic
          </td>
          <td className="md:w-[21.938rem]  min-w-[4.6rem] border-[C7C7C7] text-center py-4 font-semibold text-lg md:px-4 bg-[#FFF9E2]">
            Pro
          </td>
          <td className="md:w-[21.938rem]  min-w-[4.6rem] border-[C7C7C7] text-center py-4 font-semibold text-lg md:px-4 bg-[#FFF9E2]">
            Teams
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Language Recognition
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] min-w-[4.6rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem]  min-w-[4.6rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem]  min-w-[4.6rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Maths Recognition
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto py-auto"
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto"
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Handwriting Recognition
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Text and Shape Tool
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Magic and laser pen
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">Stopwatch</h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Roll dice , Draw a number
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Import, Export Files
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Geometry tools
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto"
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Embedded Browser
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto py-auto"
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Split Screen
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto"
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Symmetric Drawing
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto py-auto"
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto"
            />
          </td>
          <td className=" border-[C7C7C7] text-lg md:px-4">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Import/Export via QR
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto py-auto"
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto"
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Content Library
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            <h1 className="text-center">Limited</h1>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            <h1 className="text-center">Expanded</h1>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            <h1 className="text-center">Expanded</h1>
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Collaboration
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] md:text-lg text-sm md:px-4 bg-[#FFF9E2]">
            <h1 className="text-center">5 Users</h1>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] md:text-lg text-sm md:px-4 bg-[#FFF9E2]">
            <h1 className="text-center">8 Users</h1>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] md:text-lg text-sm md:px-4 bg-[#FFF9E2]">
            <h1 className="text-center">15 Users</h1>
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Security Features
              </h1>
            </div>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            <h1 className="text-center">Basic</h1>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            {" "}
            <h1 className="text-center">Basic</h1>
          </td>
          <td className=" border-[C7C7C7] md:text-lg text-sm md:px-4">
            <h1 className="text-center">Advanced</h1>
          </td>
        </tr>

        <tr>
          <td className="border-[C7C7C7] bg-[#FFF9E2]">
            <div className="flex md:px-8 px-2 md:py-[1.75rem] py-4 md:gap-[1.25rem] gap-2 items-center">
              <img
                src={Checkbox}
                alt="checkbox"
                className="md:w-[1.75rem] w-6 md:h-[1.75rem] h-6"
              />
              <h1 className="md:text-lg text-sm text-[#111726]">
                Lesson Plan Dashboard
              </h1>
            </div>
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Dash}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto py-auto"
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
          <td className="md:w-[21.938rem] border-[C7C7C7] text-lg md:px-4 bg-[#FFF9E2]">
            <img
              src={Check}
              alt="dash"
              className="md:w-[2.75rem] md:h-[2.75rem] w-8 h-8 mx-auto my-auto "
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PricingTable;
