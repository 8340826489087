import rightArrow from "../../assets/right-arrow.svg";
import secondCardImage from "../../assets/add-ons/second-card-img.png";
import whiteRightArrow from "../../assets/project-menu/oem/white-right-arrow.svg";
import { Link } from "react-router-dom";
import React, {useState} from "react";
import blackRightArrow from "../../assets/project-menu/oem/blackRightArrow.svg";

const Cards = () => {
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);
  return (
    <div className="flex s:flex-col xmd:flex-row gap-[20px] s:px-[25px] sm:px-[50px] xmd:px-[30px] s:py-[30px] sm:py-[45px] xmd:pt-[60px] xmd:pb-[60px] text-[#ffffff] ">
      {/* first card */}
      <div className="flex flex-col s:w-full xmd:w-4/6 h-full rounded-[12px] overflow-hidden">
        <div className="bg-[#922F10] pt-[40px] pb-[86px] s:pb-[32px] sm:pb-[50px] flex flex-col gap-[30px]">
          <h1 className="font-medium xmd:text-[28px] sm:pl-[40px] sm:pr-[100px] pl-[70px] pr-[210px] s:text-[19px] sm:text-[22px] lg:text-[48px] s:pr-[50px] s:pl-[18px] xmd:w-[332px] lg:w-[433px]">
            Try Wriety for <span className="text-[#F0AA94]">free</span>
          </h1>
          <a className="hover:no-underline" href="https://app.wriety.com/web" target="_blank" rel="noreferrer">
          <div
                    className=" s:ml-[20px] lg:ml-[40px] flex flex-row gap-[10px]  py-[9px] min-w-max rounded-[10px]"
                    style={{ width: "max-content" }}
                  >

                  <a href="https://app.wriety.com/web" rel="noreferrer"
                className="bg-[#ffffff] px-[18px] py-[9px] gap-[10px] rounded-[6px] flex flex-row items-center hover:no-underline hover-container"
              >
                <p className="text-[#302C2C] ">Get started</p>
                <div className="flex flex-row gap-[10px] relative overflow-hidden ">
                  <span className="absolute initial-slide-in">
                    <img
                      src={blackRightArrow}
                      alt="arrow"
                      className="w-[14px] h-[13px]"
                    />
                  </span>

                  <span className="initial-slide-out">
                    <img
                      src={blackRightArrow}
                      alt="arrow"
                      className="w-[14px] h-[13px]"
                    />
                  </span>
                </div>
              </a>
</div>
          </a> 
        </div>
        <div className="py-[28px] bg-[#C65835] px-[70px] s:px-[25px] sm:px-[40px]">
          <p className="text-[25px] ">Schedule a demo</p>

          <div className="w-[160px] mt-[20px] flex flex-row gap-[9px]">
              <div
                      className="flex flex-col gap-[5px] justify-center"
                      style={{ alignItems: "flex-start" }}
                      onMouseEnter={() => setIsHoverContactSales(true)}
                      onMouseLeave={() => setIsHoverContactSales(false)}
                    >
                      <Link
                        to="/contact-us"
                        className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                      >
                        <p className="leading-[12px] font-semibold">
                          Contact Sales
                        </p>
                        <span
                          className={`${isHoverContactSales ? "bounce-x" : ""}`}
                        >
                          <img
                            src={whiteRightArrow}
                            alt="Arrow"
                            className="w-[21px] h-[12px]"
                          />
                        </span>
                      </Link>
                      <div
                        className={`h-[2px] bg-[#fff] justify-end ${
                          isHoverContactSales
                            ? "w-[80%] transition-all"
                            : "w-[100%] transition-all"
                        }`}
                        style={{ willChange: "transform" }}
                      ></div>
                    </div>
              </div>
          </div>

      </div>
      {/* second card */}
      <div className="w-full bg-cover rounded-[12px] lg:h-[390px] ">
        <img
          src={secondCardImage}
          alt="second card"
          className="w-full s:h-full xmd:h-[110%]"
        />
      </div>
    </div>
  );
};

export default Cards;
