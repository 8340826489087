import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "./LandingPage.css";
import elementPlus from "../../assets/puzzel/Static-white.svg";
import lImg1 from "../../assets/puzzel/one-p.svg";
import lImg2 from "../../assets/puzzel/two-p.svg";
import lImg3 from "../../assets/puzzel/three-p.svg";
import lImg4 from "../../assets/puzzel/four-p.svg";
import lImg5 from "../../assets/puzzel/five-p.svg";
import lImg6 from "../../assets/puzzel/six-p.svg";
import lImg7 from "../../assets/puzzel/seven-p.svg";
import lImg8 from "../../assets/puzzel/eight-p.svg";
import rImg1 from "../../assets/puzzel/one-r-p.svg";
import rImg2 from "../../assets/puzzel/two-r-p.svg";
import rImg3 from "../../assets/puzzel/three-r-p.svg";
import rImg4 from "../../assets/puzzel/four-r-p.svg";
import rImg5 from "../../assets/puzzel/five-r-p.svg";
import rImg6 from "../../assets/puzzel/six-r-p.svg";
import rImg7 from "../../assets/puzzel/seven-r-p.svg";
import './LandingPage.css'


const PuzzleAnimation = () => {
  const isInitialized = useRef(false); // Create a ref to track initialization

  useEffect(() => {
    if (isInitialized.current) return; // Exit if already initialized

    isInitialized.current = true; // Mark as initialized
    const images = [lImg1, lImg2, lImg3, lImg4, lImg5, lImg6, lImg7, lImg8];
    const images1 = [rImg1, rImg2, rImg3, rImg4, rImg5, rImg6, rImg7];

    let movePuzzle1 = true;

    const getTransformStyle = (index) => {
      const row = Math.floor(index / 3);
      const col = index % 3;
      var divWidth = $(".puzzle-container").width();
      var PositonMutiplier = divWidth / 3 + 5;
      return `translate(${col * PositonMutiplier}px, ${
        row * PositonMutiplier
      }px)`;
    };

    const movePiece = (container) => {
      if (container.isMoving) return;

      const emptyBlock = container.querySelector(".empty");
      if (!emptyBlock) {
        console.error("No empty block found");
        return;
      }

      container.isMoving = true;
      const emptyBlockIndex = parseInt(emptyBlock.dataset.index);
      const neighbors = [];
      if (emptyBlockIndex % 3 !== 0) neighbors.push(emptyBlockIndex - 1);
      if (emptyBlockIndex % 3 !== 2) neighbors.push(emptyBlockIndex + 1);
      if (emptyBlockIndex - 3 >= 0) neighbors.push(emptyBlockIndex - 3);
      if (emptyBlockIndex + 3 <= 8) neighbors.push(emptyBlockIndex + 3);

      const randomNeighbor =
        neighbors[Math.floor(Math.random() * neighbors.length)];
      const pieceIndex = parseInt(
        container.children[randomNeighbor].dataset.index
      );
      swapPieces(container, pieceIndex, emptyBlockIndex);
    };

    const swapPieces = (container, pieceIndex, emptyBlockIndex) => {
      const piece = container.children[pieceIndex];
      const emptyPiece = container.querySelector(".empty");

      [piece.dataset.index, emptyPiece.dataset.index] = [
        emptyPiece.dataset.index,
        piece.dataset.index,
      ];

      // piece.classList.add("empty");
      // emptyPiece.classList.remove("empty");

      piece.style.transform = getTransformStyle(emptyBlockIndex);
      emptyPiece.style.transform = getTransformStyle(pieceIndex);

      setTimeout(() => {
        container.isMoving = false;
      }, 300); // Delay matches the transition duration
    };

    const createPuzzle = (container, startIndex, images, side) => {
      const positions = [...Array(9).keys()];

      for (let i = positions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [positions[i], positions[j]] = [positions[j], positions[i]];
      }

      positions.forEach((pos, index) => {
        const piece = document.createElement("div");
        piece.className = "puzzle-piece";
        piece.dataset.index = index;

        if (pos === 0 || (pos === 8 && side === "r")) {
          piece.classList.add("empty");
        } else {
          piece.innerHTML =
            '<img src="' +
            `${images[(startIndex + pos) % images.length]}` +
            '">';
          piece.classList.add("puzzle-card-size")
        }

        container.appendChild(piece);
        piece.style.transform = getTransformStyle(index);
      });

      container.isMoving = false; // Initialize the moving flag
    };

    const puzzleContainer1 = document.getElementById("puzzle1");
    createPuzzle(puzzleContainer1, 0, images, "l");

    const puzzleContainer2 = document.getElementById("puzzle2");
    createPuzzle(puzzleContainer2, 0, images1, "r");

    setInterval(() => {
      if (movePuzzle1) {
        movePiece(puzzleContainer1);
      } else {
        movePiece(puzzleContainer2);
      }
      movePuzzle1 = !movePuzzle1;
    }, 1000);
  }, []);

  return (
    <div className="container-fluid bg-[#ffffff] py-[12px] lg:w-full puzzle-sectionss">
      <div className="col-md-12 col-sm-12 col-xs-12" style={{paddingLeft:"0px"}}>
        <div className="PuzzleDiv">
          <div className="col-md-4 col-sm-12 col-xs-12 puzzle-sec-inner-one" style={{paddingLeft : "0px"}}>
            <div className="puzzle-container" id="puzzle1"></div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 pl-[15px] pr-[0px] puzzle-sec-inner-two">
            <div className="separator">
              <div>
                <div className="flex items-center justify-center rounded-xl bg-[#252222]">
                  {/* <svg
                    className="w-12 md:w-[84px]"
                    viewBox="0 0 83 84"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="10.3498"
                      cy="41.7046"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      cx="41.3996"
                      cy="41.7046"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      opacity="0.2"
                      cx="10.3498"
                      cy="72.6773"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      opacity="0.2"
                      cx="72.4474"
                      cy="72.6773"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      cx="41.3996"
                      cy="72.6773"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      cx="72.4474"
                      cy="41.7046"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      opacity="0.2"
                      cx="10.3498"
                      cy="10.729"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      opacity="0.2"
                      cx="41.3996"
                      cy="10.729"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                    <ellipse
                      opacity="0.2"
                      cx="72.4474"
                      cy="10.729"
                      rx="10.3498"
                      ry="10.3247"
                      fill="white"
                    ></ellipse>
                  </svg> */}
                  <img src={elementPlus} alt="element-plus" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 puzzle-sec-inner-three">
            <div className="puzzle-container" id="puzzle2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuzzleAnimation;
