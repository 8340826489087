import React from "react";

const WhyChoseUsCard = ({ title, image, content }) => {
  return (
    <div className="flex flex-col items-center md:gap-8 gap-4 border border-[#DADADA] bg-white rounded-3xl py-[1.875rem] px-6">
      {/* image */}
      <img src={image} alt="image" className="w-[4.5rem] h-[4.5rem]" />

      {/* content */}
      <div className="flex flex-col gap-4 items-center">
        <h1 className="md:text-[1.75rem] text-xl font-semibold text-[#202020]">
          {title}
        </h1>

        <p className="text-center text-[#4B465C] md:text-[1.125rem] text-sm xl:w-[23.75rem]">
          {content}
        </p>
      </div>
    </div>
  );
};

export default WhyChoseUsCard;
