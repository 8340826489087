import React from "react";

const PricingPlan = ({
  logo,
  name,
  decription,
  price,
  id,
  active,
  setActive,
}) => {
  return (
    <div className="relative py-[3.75rem] px-[2.5rem] flex flex-col gap-4 items-center">
      {/* logo */}
      <div className="flex items-center justify-center h-[2.75rem] w-[2.75rem] rounded-full bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]">
        <img src={logo} alt="logo" />
      </div>

      <h1 className="text-2xl font-medium">{name} Plan</h1>
      <h2 className="text-lg">{decription}</h2>
      <h3 className="text-[2rem]">
        ₹{price} <span className="text-lg">/ Year</span>
      </h3>
    <a href="https://app.wriety.com/web" target="_blank" rel="noreferrer">  <button
        type="submit"
        className="text-[#202020] text-lg font-medium w-[16.875rem] rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-[1.25rem] px-[1.75rem]"
      >
        Get Started
      </button></a>

      <button
        onClick={() => setActive(id)}
        className={`${
          active === id && "border-2 border-[#111726]"
        } xl:block hidden top-0 left-0 absolute rounded-[1.25rem] w-[21.8rem] h-[120rem]`}
      />
    </div>
  );
};

export default PricingPlan;
