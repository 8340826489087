import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForArtists from "../../assets/usecases/for-artists.svg";
import DesigningIllustration from "../../assets/usecases/designing-illustration.svg";

const Usecase6 = () => {
  return (
    <div className="w-full flex xl:flex-row flex-col xl:px-[6.25rem] px-[1.25rem] bg-[#FEFEFE] xl:py-[5rem] xl:gap-[3.313rem] gap-6 py-6 justify-center">
      {/* illustration */}
      <img
        src={DesigningIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] z-50 xl:mt-2 xl:mx-0 md:mx-auto mx-0"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[41.938rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-[37.6px]">
            Designing
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Digital Art and Design
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:w-[40rem]">
          Artists and designers can create digital artwork using the app's
          drawing tools.
          <br />
          Layering, pen options, and 3D shapes support creative expression.
        </p>

        {/* cards */}
        <div className="xl:block hidden absolute bottom-8 -left-24">
          <UsecaseCard
            colored
            title="Artists and Designers"
            image={ForArtists}
            content="Artists and designers can create digital artwork using the app's drawing tools."
          />
        </div>
      </div>

      <div className="xl:hidden">
        <UsecaseCard
          colored
          title="Artists and Designers"
          image={ForArtists}
          content="Artists and designers can create digital artwork using the app's drawing tools."
        />
      </div>
    </div>
  );
};

export default Usecase6;
