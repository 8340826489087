import React from "react";

import Usecase1 from "../components/usecases/Usecase1";
import Usecase2 from "../components/usecases/Usecase2";
import Usecase3 from "../components/usecases/Usecase3";

import ContactUs from "../components/ContactUs";
import Usecase4 from "../components/usecases/Usecase4";
import Usecase5 from "../components/usecases/Usecase5";
import Usecase6 from "../components/usecases/Usecase6";
import Usecase7 from "../components/usecases/Usecase7";
import Usecase8 from "../components/usecases/Usecase8";
import Usecase9 from "../components/usecases/Usecase9";
import Usecase10 from "../components/usecases/Usecase10";
import Usecase11 from "../components/usecases/Usecase11";
import Usecase12 from "../components/usecases/Usecase12";

const UseCases = () => {
  return (
    <div className="md:pt-[12.313rem] pt-28 flex flex-col items-center bg-[#FFFBEB]">
      {/* heading */}
      <div className="md:h-[3.75rem]">
        <div className="md:block hidden relative h-[1.875rem] w-[17.7rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]">
          <h1 className="absolute -right-1 w-[18.5rem] -top-[1.9rem] z-40 text-[2.5rem] font-semibold">
            Our Use Cases
          </h1>
        </div>
        <h1 className="md:hidden w-[18.5rem] z-40  text-[1.5rem] font-semibold text-center">
          Our Use Cases
        </h1>
      </div>

      {/* main */}
      <Usecase1 />
      <Usecase2 />
      <Usecase3 />
      <Usecase4 />
      <Usecase5 />
      <Usecase6 />
      <Usecase7 />
      <Usecase8 />
      <Usecase9 />
      <Usecase10 />
      <Usecase11 />
      <Usecase12 />

      {/* contact us */}
      <ContactUs />
    </div>
  );
};

export default UseCases;
