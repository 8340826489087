import Cards from "./add-ons/Cards";
import BlackPoint from "../assets/black-point.svg";
import companyPageImg from "../assets/company-page-img.png"
import "./EmblaCarousel.css"

const Company = () => {
  return (
    <div className="w-full">
      <div className="bg-[#FFFFFF] flex items-center justify-center">
        <div className="w-[96%] lg:w-[1570px] px-[20px] lg:px-[150px]  pt-[100px] lg:pt-[200px] pb-[60px] flex flex-col gap-[40px] fix-container padding-x">
          <div className="flex flex-col lg:flex-row gap-[40px] items-start justify-between">
            <div className="flex flex-col gap-[40px]">
              <div className="flex flex-col gap-[20px] lg:w-[550px] w-[100%] items-start">
                <h1 className="text-[35px] font-semibold text-[#302C2C]">
                  Our Mission
                </h1>
                <p className="text-[17px] leading-[27px] text-[#787676]">
                  We redefine the way our customers communicate, teach, meet,
                  and collaborate by fostering impactful human interactions.
                </p>
              </div>

              <div className="flex flex-col gap-[20px]  lg:w-[550px] w-[100%] items-start">
                <h1 className="text-[35px] font-semibold text-[#302C2C]">
                  Rethink Collaboration
                </h1>
                <div className="flex flex-col gap-[8px]">
                  <p className="text-[17px] leading-[27px] text-[#787676]">
                    In today's rapidly evolving environment, we focus on:
                  </p>
                  <ul className="list-inside list-disc">
                    <li className="text-[#787676]">
                      Enhancing in-classroom teaching experiences
                    </li>
                    <li className="text-[#787676]">
                      Exploring hybrid collaboration options
                    </li>
                    <li className="text-[#787676]">
                      Embracing advanced technology solutions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <img src={companyPageImg} alt="companyImg" className="w-[490px] "/>
          </div>

          <div className="flex flex-col gap-[40px] items-start">
            <h1 className="text-[35px] font-semibold text-[#302C2C]">
              What Got us Here
            </h1>

            <div className="bg-[#302C2CA6] h-[1px] w-[98%]"></div>


            <div className="absolute flex flex-row items-start mt-[50px] dis-none">
              <div className="flex flex-col absolute gap-[6px] ">
                <p className="text-[14px] font-semibold">1</p>
                <img
                  src={BlackPoint}
                  alt="black-point"
                  className="w-[9px] h-[9px]"
                />
              </div>
              <div className="flex flex-col absolute gap-[6px] ml-[260px]">
                <p className="text-[14px] font-semibold">2</p>
                <img
                  src={BlackPoint}
                  alt="black-point"
                  className="w-[9px] h-[9px]"
                />
              </div>
              <div className="flex flex-col absolute gap-[6px] ml-[510px]">
                <p className="text-[14px] font-semibold">3</p>
                <img
                  src={BlackPoint}
                  alt="black-point"
                  className="w-[9px] h-[9px]"
                />
              </div>
              <div className="flex flex-col absolute gap-[6px] ml-[770px]">
                <p className="text-[14px] font-semibold">4</p>
                <img
                  src={BlackPoint}
                  alt="black-point"
                  className="w-[9px] h-[9px]"
                />
              </div>
              <div className="flex flex-col absolute gap-[6px] ml-[1028px]">
                <p className="text-[14px] font-semibold">5</p>
                <img
                  src={BlackPoint}
                  alt="black-point"
                  className="w-[9px] h-[9px]"
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row  gap-[20px] items-start">
              <div className="flex flex-col gap-[20px] items-start  w-[100%]">
                <p className="text-[17px] text-[#302C2C] md:min-h-[76px]">
                  Post-Covid Market Shift
                </p>
                <p className="text-[#787676]">
                  The shift in market dynamics post-Covid has driven the need
                  for more versatile communication and collaboration tools.
                </p>
              </div>
              <div className="flex flex-col gap-[20px] items-start  w-[100%]">
                <p className="text-[17px] text-[#302C2C] md:min-h-[76px]">
                  Investments in Classroom Tech Globally
                </p>
                <p className="text-[#787676]">
                  Increased investments in educational technology worldwide have
                  paved the way for more interactive and effective teaching
                  methods.
                </p>
              </div>
              <div className="flex flex-col gap-[20px] items-start  w-[100%]">
                <p className="text-[17px] text-[#302C2C] md:min-h-[76px]">
                  Interactive Flat Panel Display on High CAGR
                </p>
                <p className="text-[#787676]">
                  The growing demand for interactive flat panel displays is
                  evident in their high compound annual growth rate (CAGR).
                </p>
              </div>
              <div className="flex flex-col gap-[20px] items-start w-[100%]">
                <p className="text-[17px] text-[#302C2C] md:min-h-[76px]">
                  Opportunity to Disrupt with AI
                </p>
                <p className="text-[#787676]">
                  The integration of AI offers new opportunities to
                  revolutionize communication and collaboration tools.
                </p>
              </div>
              <div className="flex flex-col gap-[20px] items-start  w-[100%]">
                <p className="text-[17px] text-[#302C2C]">
                  Willingness to Pay for Productivity & Collaboration Tools
                </p>
                <p className="text-[#787676]">
                  There is a growing recognition of the value these tools bring,
                  leading to a greater willingness to invest in them.
                </p>
              </div>
            </div>


            <div className="flex flex-col gap-[20px] items-start">
              <h1 className="text-[35px] font-semibold text-[#302C2C]">
                Our Solution
              </h1>
              <div className="flex lg:flex-row  justify-between lg:flex-nowrap flex-wrap flex-row md:gap-[0px] items-start gp-20">
                <div className="flex flex-col gap-[15px] bg-[#F6F4F2] items-start lg:w-[32%] w-[100%] rounded-[9px] px-[20px] py-[16px]">
                  <h2 className="text-[20px] text-[#302C2C]">
                    In-Classroom Teaching Focus
                  </h2>
                  <p className="text-[#787676] text-[15px]">
                    We provide state-of-the-art tools designed to enhance the
                    traditional classroom setting, making learning more engaging
                    and effective.
                  </p>
                </div>
                <div className="flex flex-col gap-[15px] bg-[#F6F4F2] items-start  lg:w-[32%] w-[100%] rounded-[9px] px-[20px] py-[16px]">
                  <h2 className="text-[20px] text-[#302C2C]">
                    Hybrid Collaboration Options
                  </h2>
                  <p className="text-[#787676] text-[15px]">
                    Our solutions cater to the needs of hybrid environments,
                    ensuring seamless communication and collaboration regardless
                    of location.
                  </p>
                </div>
                <div className="flex flex-col gap-[15px] bg-[#F6F4F2] items-start  lg:w-[32%] w-[100%] rounded-[9px] px-[20px] py-[16px]">
                  <h2 className="text-[20px] text-[#302C2C]">
                    Technology-Driven Innovation
                  </h2>
                  <p className="text-[#787676] text-[15px]">
                    We leverage the latest technological advancements to deliver
                    cutting-edge solutions that meet the evolving needs of our
                    customers.
                  </p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="bg-[#FAF9F8] flex items-center justify-center">
        <div className="lg:w-[1570px] w-[100%] lg:px-[120px] px-[0px] fix-container padding-x">
          <Cards />
        </div>
      </div>
    </div>
  );
};

export default Company;
