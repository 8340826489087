import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import blogs from "./../../data/blog.json";
import { Link } from "react-router-dom";
import whiteRightArrow from "./../../assets/project-menu/oem/white-right-arrow.svg";
import secondCardImage from "./../../assets/project-menu/advanced/single.png";
import rightArrow from "./../../assets/right-arrow.svg";
import Sticky from "react-stickynode";
import "./blog.css";
import blackRightArrow from "../../assets/project-menu/oem/blackRightArrow.svg";

const BlogSingle = () => {
  const { slug } = useParams();
  const blog = blogs.find((b) => b.slug === slug);
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef({});
  const [recentPosts, setRecentPosts] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);

  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsStickyEnabled(false);
      } else {
        setIsStickyEnabled(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setRecentPosts(blogs);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.25,
      }
    );

    Object.values(sectionsRef.current).forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      Object.values(sectionsRef.current).forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <>
    <section className=" bg-[#FFF!important]">
      <section className="md:pt-[100px] pt-[80px] md:pb-[60px] pb-[80px] md:mb-[80px] parent-div">
        <div className="md:ps-[6%] ps-[3%]">
          <span className="w-[150px] block">
            {" "}
            <Link
              className="w-[200px] hover:no-underline hover:text-[#212529] ms-[6%]"
              to="/blogs"
            >
              {" "}
              <button className="flex items-center gap-[10px] ">
                <img className="w-[10px]" src="/arrow-black.svg" alt="" /> Go
                back{" "}
              </button>{" "}
            </Link>
          </span>
        </div>
        <div className="s:max-w-[94%] xl:max-w-[1148px] mx-auto">
          <div className="md:py-[120px] py-[40px]">
            <h2 className="md:text-[72px] text-[36px] md:leading-[84px]">
              {blog.title}
            </h2>
            <p className="md:text-[16px] text-[#787676;] md:mt-[30px] mt-[10px] md:ms-[10px]">
              {" "}
              {blog.date}
            </p>
          </div>

          <div className="flex md:flex-row flex-col md:gap-[100px] ">
            <Sticky
              enabled={isStickyEnabled}
              top={0}
              bottomBoundary=".parent-div"
              onStateChange={handleStateChange}
            >
              <div className="leftside md:w-[100%] w-[100%] sticky-nav relative md:pb-[0] pb-[40px] mt-[0px] ">
                <Link
                  className="back-bt ms-[0] mb-[20px] hover:no-underline hover:text-[#212529]"
                  to="/blogs"
                >
                  {" "}
                  <button className="flex items-center gap-[10px] md:ml-[-20px]">
                    <img className="w-[10px]" src="/arrow-black.svg" alt="" />{" "}
                    Go back{" "}
                  </button>{" "}
                </Link>

                <nav className="leftside sticky-nav">
                  <ul>
                    {isStickyEnabled}
                    {blog["Key Features and Benefits"] && (
                      <li
                        className={
                          activeSection === "features"
                            ? "active mb-2 "
                            : " mb-2"
                        }
                      >
                        <a
                          className=" hover:no-underline hover:text-[#212529]"
                          href="#features"
                          rel="noreferrer"
                        >
                          Key Features and Benefits
                        </a>
                      </li>
                    )}
                    {blog["How Wriety Elevates IWB Sales"] && (
                      <li
                        className={
                          activeSection === "sales" ? "active mb-2" : " mb-2"
                        }
                      >
                        <a
                          className=" hover:no-underline hover:text-[#212529]"
                          href="#sales"
                          rel="noreferrer"
                        >
                          How Wriety Elevates IWB Sales
                        </a>
                      </li>
                    )}
                    {blog["Use Cases"] && (
                      <li
                        className={
                          activeSection === "use-cases"
                            ? "active mb-2"
                            : " mb-2"
                        }
                      >
                        <a
                          className=" hover:no-underline hover:text-[#212529]"
                          href="#use-cases"
                          rel="noreferrer"
                        >
                          Use Cases
                        </a>
                      </li>
                    )}
                    {blog.Conclusion && (
                      <li
                        className={
                          activeSection === "conclusion"
                            ? "active mb-2"
                            : " mb-2"
                        }
                      >
                        <a
                          className=" hover:no-underline hover:text-[#212529]"
                          href="#conclusion"
                          rel="noreferrer"
                        >
                          Conclusion
                        </a>
                      </li>
                    )}
                    {blog["Call to Action"] && (
                      <li
                        className={
                          activeSection === "cta" ? "active mb-2" : " mb-2"
                        }
                      >
                        <a
                          className="hover:no-underline hover:text-[#212529]"
                          href="#cta"
                          rel="noreferrer"
                        >
                          Call to Action
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>

                {/* <p className=" md:mt-[60px] mt-[40px] mb-[10px]">
                  Share Article
                </p>
                <div className="flex gap-[10px]">
                  <img src="/twitters.svg" alt="" />
                  <img src="/facebooks.svg" alt="" />
                  <img src="/linkedin.svg" alt="" />
                </div> */}
              </div>
            </Sticky>
            <div
              className={`rightside md:w-[80%] w-[100%] ml-auto ${
                isSticky ? "is-sticky" : ""
              }`}
            >
              <div
                id="description"
                ref={(el) => (sectionsRef.current["description"] = el)}
              >
                <p>{blog.description}</p>
              </div>

              {blog["Key Features and Benefits"] && (
                <div
                  id="features"
                  ref={(el) => (sectionsRef.current["features"] = el)}
                >
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    Key Features and Benefits
                  </h3>
                  <ul>
                    {Object.entries(blog["Key Features and Benefits"]).map(
                      ([key, value]) => (
                        <li className="pb-[10px]" key={key}>
                          <strong>{key}:</strong> {value}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

              {blog["How Wriety Elevates IWB Sales"] && (
                <div
                  id="sales"
                  ref={(el) => (sectionsRef.current["sales"] = el)}
                >
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    How Wriety Elevates IWB Sales
                  </h3>
                  <ul>
                    {Object.entries(blog["How Wriety Elevates IWB Sales"]).map(
                      ([key, value]) => (
                        <li className="pb-[10px]" key={key}>
                          <strong>{key}:</strong> {value}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

              {blog["Use Cases"] && (
                <div
                  id="use-cases"
                  ref={(el) => (sectionsRef.current["use-cases"] = el)}
                >
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    Use Cases
                  </h3>
                  <ul>
                    {Object.entries(blog["Use Cases"]).map(([key, value]) => (
                      <li className="pb-[10px]" key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {blog.Conclusion && (
                <div
                  id="conclusion"
                  ref={(el) => (sectionsRef.current["conclusion"] = el)}
                >
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    Conclusion
                  </h3>
                  <p>{blog.Conclusion}</p>
                </div>
              )}

              {blog["Call to Action"] && (
                <div id="cta" ref={(el) => (sectionsRef.current["cta"] = el)}>
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    Call to Action
                  </h3>
                  <p>{blog["Call to Action"]}</p>
                </div>
              )}

              {blog.Testimonial && (
                <div
                  id="testimonial"
                  ref={(el) => (sectionsRef.current["testimonial"] = el)}
                >
                  <h3 className=" md:pb-[20px] pb-[10px] md:pt-[40px] pt-[30px] tracking-[-.72px] md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500]">
                    Testimonial
                  </h3>
                  <blockquote>
                    <p>"{blog.Testimonial.quote}"</p>
                    <footer>- {blog.Testimonial.author}</footer>
                  </blockquote>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#FAF9F8!important] ">
        <div className=" s:max-w-[94%] xl:max-w-[1280px] mx-auto">
          <div className="flex justify-between md:pt-[100px] pt-[60px] ">
            <h2 className="md:text-[36px] text-[26px] leading-[32px] md:leading-[42px] font-[500] tracking-[-.72px] text-[#000]">
              More articles
            </h2>
            <span></span>
          </div>

          <div className="flex flex-wrap md:gap-[20px] gap-[20px] md:mt-[40px] mt-[40px] md:pb-[40px] pb-[50px] ">
            {recentPosts.map((blog) => (
              <Link
                className="hover:no-underline hover:text-[#000] md:w-[48%]"
                to={`/blog/${blog.slug}`}
                key={blog.id}
              >
                <div className="min-h-[400px] box-card md:w-[100%] w-[100%] bg-[#F6F4F2] rounded-[10px]">
                  <div className="md:p-[40px] p-[20px]">
                    <h2 className="md:pb-[20px] tracking-[-.72px] md:text-[36px] text-[26] leading-[32px] md:leading-[42px] font-[500]">
                      {blog.title}
                    </h2>
                    <p>{blog.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="bg md:py-[100px] py-[60px] s:max-w-[94%] xl:max-w-[1280px] mx-auto">
          <div className="flex items-center justify-center w-full ">
            <div className="w-full max-w-[1570px] flex s:flex-col xmd:flex-row gap-[20px] text-[#ffffff]">
              <div className="flex flex-col s:w-full xmd:w-4/6 h-full rounded-[12px] overflow-hidden">
                <div className="bg-[#922F10] pt-[40px] pb-[40px] s:pb-[32px] sm:pb-[20px] h-[320px]">
                  <h1 className="font-medium xmd:text-[28px] sm:pl-[40px] sm:pr-[100px] pl-[70px] pr-[210px] s:text-[19px] sm:text-[22px] lg:text-[48px] s:pr-[50px] s:pl-[18px] xmd:w-[332px] lg:w-[433px]">
                    Try Wriety for <span className="text-[#F0AA94]">free</span>
                  </h1>
                  {/* <div
                    className="mt-[20px] s:ml-[20px] lg:ml-[40px] flex flex-row gap-[10px] px-[18px] py-[9px] bg-[#FFFFFF] min-w-max rounded-[10px]"
                    style={{ width: "max-content" }}
                  >
                   <a href="https://app.wriety.com/web" target="_blank" rel="noreferrer"> <p className="text-[#302C2C] ">Get started</p></a> 
                    <img src={rightArrow} alt="right" />
                  </div> */}

                  <div
                    className="mt-[20px] s:ml-[20px] lg:ml-[40px] flex flex-row gap-[10px]  py-[9px] min-w-max rounded-[10px]"
                    style={{ width: "max-content" }}
                  >
                    <a
                      href="https://app.wriety.com/web"
                      rel="noreferrer"
                      className="bg-[#ffffff] px-[18px] py-[9px] gap-[10px] rounded-[6px] flex flex-row items-center hover:no-underline hover-container"
                    >
                      <p className="text-[#302C2C] ">Get started</p>
                      <div className="flex flex-row gap-[10px] relative overflow-hidden ">
                        <span className="absolute initial-slide-in">
                          <img
                            src={blackRightArrow}
                            alt="arrow"
                            className="w-[14px] h-[13px]"
                          />
                        </span>

                        <span className="initial-slide-out">
                          <img
                            src={blackRightArrow}
                            alt="arrow"
                            className="w-[14px] h-[13px]"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="py-[30px] bg-[#C65835] px-[70px] s:px-[25px] sm:px-[40px]">
                  <p className="text-[26px] text-[18px]">Schedule a demo</p>
                  <div className="w-[180px] mt-[20px]  flex flex-row gap-[9px]">
                    <div
                      className="flex flex-col gap-[5px] justify-center"
                      style={{ alignItems: "flex-start" }}
                      onMouseEnter={() => setIsHoverContactSales(true)}
                      onMouseLeave={() => setIsHoverContactSales(false)}
                    >
                      <Link
                        to="/contact-us"
                        className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                      >
                        <p className="leading-[12px] font-semibold">
                          Contact Sales
                        </p>
                        <span
                          className={`${isHoverContactSales ? "bounce-x" : ""}`}
                        >
                          <img
                            src={whiteRightArrow}
                            alt="Arrow"
                            className="w-[21px] h-[12px]"
                          />
                        </span>
                      </Link>
                      <div
                        className={`h-[2px] bg-[#fff] justify-end ${
                          isHoverContactSales
                            ? "w-[80%] transition-all"
                            : "w-[100%] transition-all"
                        }`}
                        style={{ willChange: "transform" }}
                      ></div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="w-full bg-cover rounded-[12px]">
                <img
                  src={secondCardImage}
                  alt="second card"
                  className="w-full s:h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      </section>
    </>
  );
};

export default BlogSingle;
