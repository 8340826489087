import React from "react";

import BlogCardImage from "../../assets/blog/blog-image.png";
import Calender from "../../assets/blog/calender.svg";
import Share from "../../assets/blog/share.svg";
import { Link } from "react-router-dom";

const BlogCard = ({ img, title, linkTo }) => {
  if (title === "") {
    title = "Lorem Ipsum dolor sit amet, Lorem ips ipsum dolor sit amet";
  }
  return (
    <div className="xl:w-[23.75rem] flex flex-col border-[#FCDE7D] border-2 rounded">
      <img
        src={img}
        alt="blog card image"
        className="xl:w-[23.75rem] xl:h-[16.25rem] rounded-sm"
      />

      {/* details */}
      <div className="p-6 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {/* datte and category */}
          <div className="flex gap-2 items-center">
            <div className="flex gap-1 items-center">
              <img
                src={Calender}
                alt="calender"
                className="w-[0.875rem] h-[0.875rem]"
              />

              <h1 className="text-xs text-[#8D98A4]">12, June 2023</h1>
            </div>

            <button className="text-xs font-medium bg-[#FBE29A] rounded-lg py-[0.188rem] px-[0.625rem]">
              Creative
            </button>
          </div>

          <p className="xl:text-lg text-[#1A1C1F]">{title}</p>
        </div>

        {/* buttons */}
        <div className="flex gap-4">
          <Link to={linkTo}>
            <button
              type="submit"
              className="text-sm text-[#202020] font-medium rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-3 px-[1.25rem]"
            >
              Read More
            </button>
          </Link>

          <button
            type="submit"
            className="flex gap-[0.625rem] items-center text-sm text-[#202020] font-medium rounded-lg bg-[#FFF9E2] py-3 px-[1.25rem]"
          >
            <img
              src={Share}
              alt="share icon"
              className="w-[0.875rem] h-[0.875rem]"
            />
            <h1>Share</h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
