import React from "react";
import DownloadCenter from "../components/download/DownloadCenter";
import TrustSection from "../components/TrustSection";

const DownloadPage = () => {
  return (
    <div>
      <DownloadCenter />
      <TrustSection />
    </div>
  );
};

export default DownloadPage;
