import React from "react";

import PlayStore from "../../assets/home/google-play.svg";
import AppStore from "../../assets/home/Appstore_comingsoon.svg";
import MsStore from "../../assets/home/ms-store.svg";
import Ipad from "../../assets/download/ipad.png";
import Rocket from "../../assets/download/rocket.png";
import HighlightsIllustration from "../../assets/download/highlights-illustration.svg";
import HighlightCard from "./HighlightCard";
import OurMissionCard from "../about/OurMissionCard";

const DownloadCenter = () => {
  return (
    <div className="w-full flex flex-col px-[1.25rem] xl:pt-[12.313rem] pt-16 items-center pb-[1.25rem]">
      <div className="xl:pl-[6.938rem] xl:pr-[7rem] px-[1.25rem] flex xl:flex-row flex-col xl:gap-0 gap-8 justify-center pb-[2.375rem] ">
        {/* content */}
        <div className="relative flex flex-col xl:w-[41.938rem] mt-10">
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-[1.5rem] font-medium">
            Download the best Smartboard app
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[2rem] font-semibold leading-tight">
            Helping Dreamers
            <br />
            Become Doers
          </h2>

          {/* ipad */}
          <img
            src={Ipad}
            alt="ilustration"
            className="xl:hidden xl:h-[22.75rem] xl:w-[34.125rem] z-1 mt-4"
          />

          {/* cards */}
          <div className="mt-6 mb-10">
            <OurMissionCard
              title="Our Mission"
              image={Rocket}
              content="
                Lorem ipsum dolor sit amet, euismod, nec varius urna fermentum. Duis lacinia libero eget lectus fringilla, id euismod dolor vehicula. Suspendisse euismod"
            />
          </div>

          {/* stores */}
          <div className="flex xl:gap-[2.188rem] gap-4">
            <button>
              <img src={PlayStore} alt="playstore" />
            </button>
            <button>
              <img src={AppStore} alt="AppStore" />
            </button>
            <button>
              <img src={MsStore} alt="MsStore" />
            </button>
          </div>
        </div>

        {/* ipad */}
        <img
          src={Ipad}
          alt="ilustration"
          className="xl:block hidden xl:h-[22.75rem] xl:w-[34.125rem] z-50"
        />
      </div>

      {/* highlights */}
      <div className="flex xl:flex-row flex-col gap-[1.875rem] items-center">
        {/* illustration */}
        <img
          src={HighlightsIllustration}
          alt="Highlights Illustration"
          className="xl:w-[32rem] xl:h-[32rem]"
        />

        {/* highlight cards */}
        <div className="w-full grid grid-cols-2 gap-x-6 gap-y-6">
          <HighlightCard heading="99%" subheading="Client Satisfaction" />
          <HighlightCard heading="1M" subheading="Pro users" />
          <HighlightCard heading="180+" subheading="Features" />
          <HighlightCard heading="800k" subheading="Active Users" />
        </div>
      </div>
    </div>
  );
};

export default DownloadCenter;
