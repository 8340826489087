import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Layout from "./Layout";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import UseCases from "./pages/UseCases";
import BlogPage from "./pages/BlogPage";
import Blogs from "./pages/Blogs";
import PartnersPage from "./pages/PartnersPage";
import DownloadPage from "./pages/DownloadPage";
import GetInTouch from "./pages/GetInTouch";
import HowItWorksPage from "./pages/HowItWorksPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Marketplace from "./pages/Marketplace";
import WrietyOS from "./pages/WrietyOS";
import WrietyMDM from "./pages/WrietyMDM";
import Blog2 from "./components/blog/Blog2";
import OemPage from "./components/project-menu/oem/OemPage";
import LandingPage from "./components/landing-page/LandingPage";
import AdvancedPage from "./components/project-menu/advanced/AdvancedPage";
import AndroidPage from "./components/project-menu/android/AndroidPage";
import DMSPage from "./components/project-menu/dms/DMSPage";
import OnlineCollab from "./components/project-menu/online-collab/OnlineCollab";
import ProductivityPage from "./components/project-menu/productivity/ProductivityPage";
import AddOns1 from "./components/add-ons/AddOns1";
import AddOns2 from "./components/add-ons/AddOns2";
import MarketPlace1 from "./components/market-place/MarketPlace1";
import Company from "./components/Company";
import BlogList from "./components/blog/BlogList";
import BlogSingle from "./components/blog/BlogSingle";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <LandingPage /> },
      { path: "market-place", element: <MarketPlace1 /> },
      { path: "wriety-os", element: <WrietyOS /> },
      { path: "wriety-mdm", element: <WrietyMDM /> },
      { path: "features", element: <Features /> },
      { path: "pricing", element: <Pricing /> },
      { path: "use-cases", element: <UseCases /> },
      { path: "about", element: <About /> },
      { path: "blogs", element: <BlogList /> },
      { path:"/blog/:slug", element: <BlogSingle /> },
      // { path: "blogs", element: <Blogs /> },
      // { path: "blogs/blog", element: <BlogPage /> },
      // { path: "blogs/blog2", element: <Blog2 /> },
      { path: "partners", element: <PartnersPage /> },
      { path: "download", element: <DownloadPage /> },
      { path: "contact-us", element: <GetInTouch /> },
      { path: "how-it-works", element: <HowItWorksPage /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "oem", element: <OemPage /> },
      { path: "landing-page", element: <LandingPage /> },
      { path: "advanced", element: <AdvancedPage /> },
      { path: "android", element: <AndroidPage /> },
      { path: "dms", element: <DMSPage /> },
      { path: "online-collaborations", element: <OnlineCollab /> },
      { path: "productivity", element: <ProductivityPage /> },
      { path: "add-ons-2", element: <AddOns2 /> },
      { path: "add-ons-1", element: <AddOns1 /> },
      { path: "company", element: <Company /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
