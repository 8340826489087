import cloudStorage from "../../assets/add-ons/cloud-storage-img.svg";
import cloudRecording from "../../assets/add-ons/cloud-recording-img.svg";
import videoConfrenceing from "../../assets/add-ons/video-confrencing-img.svg";
import JungleeAI from "../../assets/add-ons/junglee-ai-img.svg";
import basketImg from "../../assets/add-ons/basket-img.svg";
import Cards from "./Cards";
import ComingSoonModal from "./ComingSoonModal";
import { useState } from "react";
const AddOns1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="flex items-center justify-center">
      <div className="xxl:w-[1570px]  w-[96%] lg:px-[120px] px-[0px] pt-[100px] lg:pt-[140px] md:pt-[100px] sm:pt-[80px] ssx-pt-[50px] pb-[30px] xxl:pb-[150px] lg:pb-[0px] md:pb-[100px] sm:pb-[80px] s-pb-[50px]">
        <div
          className="flex flex-col items-start justify-center lg:px-[100px] px-[20px]  lg:gap-[100px] gap-[30px] padding-x"
          style={{ alignItems: "flex-start" }}
        >
          <h1 className="lg:text-[50px] md:text-[50px] sm:text-[35px] text-[25px] font-semibold text-[#302C2C] lg:w-[580px] w-[95%]">
            Add-ons to enhance your{" "}
            <span className="text-[#A0A0A0]">workflows</span>
          </h1>

          <div className="flex flex-col items-center justify-center xxl:gap-[80px] lg:gap-[50px] gap-[20px]">
            <div
              className="flex lg:flex-row md:flex-col sm:flex-col flex-col gap-[20px]  justify-center flexbox-sec"
              style={{ alignItems: "flex-start" }}
            >
              <div
                className="flexbox-sec-inner flex flex-col gap-[10px] items-start justify-center bg-[#ffffff] pl-[10px] pt-[10px] pr-[10px] rounded-[10px] lg:w-[275px] md:w-[90%] sm:w-[90%] w-[100%]"
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <img src={cloudStorage} alt="cloud-storage" />
                <h3 className="lg:text-[27px] text-[22px]"> Cloud Storage</h3>
                <p className="text-[#302C2CCC] text-[13px] leading-[20px] w-[245px] pb-[28px]">
                  Collaboration requires central storage of files to sync data
                  between presenters and participants. Cloud recording also
                  depends on availability of cloud storage space. Presenters can
                  prepare for their presentation by pre uploading presentation
                  material instead of losing time during presentation.
                </p>
              </div>

              <div
                className="flexbox-sec-inner flex flex-col gap-[10px] items-start justify-center bg-[#ffffff] pl-[10px] pt-[10px] pr-[10px] rounded-[10px] lg:w-[275px] md:w-[90%] sm:w-[90%] w-[100%]"
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <img src={cloudRecording} alt="cloud-recording" />
                <h3 className="text-[27px] ">Cloud Recording</h3>
                <p className="text-[#302C2CCC] text-[13px] leading-[20px] w-[245px] pb-[89px]">
                  Record your lessons and presentations without hassle. Let your
                  participants review recorded presentations at their
                  convenience from device of their choice
                </p>
              </div>

              <div
                className="flexbox-sec-inner flex flex-col gap-[10px] items-start justify-center bg-[#ffffff] pl-[10px] pt-[10px] pr-[10px] rounded-[10px] lg:w-[275px] md:w-[90%] sm:w-[90%] w-[100%] pb-[47px]"
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <img src={videoConfrenceing} alt="video-confrence" />
                <h3 className="text-[27px] ">Video Conferencing</h3>
                <p className="text-[#302C2CCC] text-[13px] leading-[20px] w-[245px]">
                  Collaborative video conferencing with 1:50 and 1:100
                  configurations. Gallery feature and emotional intelligence to
                  proctor assessments and assignments. Add interactive Webinar
                  feature to broadcast to large number of participants.
                </p>
              </div>
              <div
                className="flexbox-sec-inner flex flex-col gap-[10px] items-start justify-center bg-[#ffffff] pl-[10px] pt-[10px] pr-[10px] rounded-[10px] lg:w-[275px] md:w-[90%] sm:w-[90%] w-[100%] pb-[66px]"
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <img src={JungleeAI} alt="AI" />
                <h3 className="text-[27px] ">Junglee AI</h3>
                <p className="text-[#302C2CCC] text-[13px] leading-[20px] w-[245px]">
                  State of the art LLM system with patented intent management to
                  boost your productivity. A wide range of productivity
                  enhancing tools at your disposal. Top up as needed and be on
                  cutting-edge effortlessly
                </p>
              </div>
            </div>

            <button 
              className="flex flex-row gap-[16px] px-[18px] py-[15px] bg-[#302C2C] rounded-[12px] items-center justify-center"
              onClick={openModal}
            >
              <p className="text-[#FFFFFF]">Purchase Add-ons </p>
              <img src={basketImg} alt="basketImg" />
            </button>
          </div>
        </div>
        <Cards />
      </div>
      <ComingSoonModal isOpen={isModalOpen} onClose={closeModal} />

    </div>
  );
};

export default AddOns1;
