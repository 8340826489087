import React from "react";
import ContactUs from "../components/ContactUs";
import Partners from "../components/Partners";

const PartnersPage = () => {
  return (
    <div>
      <Partners />
      <ContactUs />
    </div>
  );
};

export default PartnersPage;
