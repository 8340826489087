import React from "react";

import SearchIcon from "../../assets/search-icon.svg";
import Thumbnail from "../../assets/how-it-works/video-thumbnail.png";

const HowItWorks = () => {
  return (
    <div className="flex flex-col items-center pt-[5.625rem] lg:px-[6.25rem] px-[1.25rem]">
      {/* search bar */}
      <div className="lg:w-[24.625rem] rounded-lg border border-[#4B465C] px-8 py-[1.125rem] flex">
        <img src={SearchIcon} alt="search icon" className="w-6" />

        <input
          type="text"
          className="bg-[#FCFCFC] text-lg text-[#4B465C] placeholder:text-[#4B465C] px-4 w-full"
          placeholder="Search.."
        />
      </div>

      {/* video */}
      <div className="flex flex-col gap-[1.25rem] mt-10 items-center">
        {/* heading */}
        <div className="relative">
          <h1 className="text-[2.5rem] font-semibold">What is Wriety</h1>

          <div className="-z-10 absolute top-[1.9rem] left-2 h-[1.875rem] w-[17.5rem] bg-[#FFF9E2]" />
        </div>

        {/* player */}
        <div>
          <img
            src={Thumbnail}
            alt="thumbnail"
            className="lg:w-[47.438rem] lg:h-[27.313rem] thumbnail-shadow"
          />
        </div>
      </div>

      {/* body */}
      <div className="flex flex-col gap-[1.375rem] mt-[2.563rem] mb-8">
        <p className="text-[#202020] lg:text-xl">
          At Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate and engage with content.At
          Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate andand engage with content.
          <br />
          <br />
          We believe that education should be dynamic, interactive, and
          accessible to all. With this vision in mind, we've developed a
          comprehen prehensive smart whiteboard platform that offers an array of
          innovative features to elevate the teaching and learning experien
          ce.We believe that education should be dynamic, interactive, and
          accessible to all. With this vision in mind, we've developed a
          comprehensive smart whiteboard platform that offers an array of
          innovative features to elevate the
        </p>
        <p className="text-[#202020] lg:text-xl">
          At Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate and engage with content.At
          Wriety, we're on a mission to transform the educational landscape
          through cutting-edge technology. Our smart whiteboard solution is
          designed to empower schools, colleges, and institutions by redefining
          the way educators and students collaborate andand engage with content.
          <br />
          <br />
          We believe that education should be dynamic, interactive, and
          accessible to all. With this vision in mind, we've developed a
          comprehen prehensive smart whiteboard platform that offers an array of
          innovative features to elevate the teaching and learning experien
          ce.We believe that education should be dynamic, interactive, and
          accessible to all. With this vision in mind, we've developed a
          comprehensive smart whiteboard platform that offers an array of
          innovative features to elevate the
        </p>
      </div>

      {/* faq */}
      <div className="w-full flex flex-col gap-10 mb-[0.625rem]">
        {/* common questions */}
        <div className="w-full flex flex-col gap-6">
          {/* heading */}
          <div className="relative">
            <h1 className="lg:text-[2rem] text-[1.5rem] font-semibold">
              Common Questions
            </h1>

            <div className="lg:block hidden -z-10 absolute top-[2.4rem] -left-1 h-[0.813rem] w-[20.7rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
          </div>

          {/* content */}
          <div className="grid grid-cols-2 lg:gap-y-6 gap-y-4 lg:gap-x-[6.625rem] gap-x-4 text-[#4B465C] lg:text-xl text-sm">
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
          </div>
        </div>

        {/* Integrations and Applications */}
        <div className="w-full flex flex-col gap-6">
          {/* heading */}
          <div className="relative">
            <h1 className="lg:text-[2rem] text-[1.5rem] font-semibold">
              Integrations and Applications
            </h1>

            <div className="lg:block hidden -z-10 absolute top-[2.4rem] -left-1 h-[0.813rem] w-[30.5rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
          </div>

          {/* content */}
          <div className="grid grid-cols-2 lg:gap-y-6 gap-y-4 lg:gap-x-[6.625rem] gap-x-4 text-[#4B465C] lg:text-xl text-sm">
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
          </div>
        </div>

        {/* Troubleshooting */}
        <div className="w-full flex flex-col gap-6">
          {/* heading */}
          <div className="relative">
            <h1 className="lg:text-[2rem] text-[1.5rem] font-semibold">
              Troubleshooting
            </h1>

            <div className="lg:block hidden -z-10 absolute top-[2.4rem] -left-1 h-[0.813rem] w-[17rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
          </div>

          {/* content */}
          <div className="grid grid-cols-2 lg:gap-y-6 gap-y-4 lg:gap-x-[6.625rem] gap-x-4 text-[#4B465C] lg:text-xl text-sm">
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
          </div>
        </div>

        {/* Improt and Export in Wriety */}
        <div className="w-full flex flex-col gap-6">
          {/* heading */}
          <div className="relative">
            <h1 className="lg:text-[2rem] text-[1.5rem] font-semibold">
              Improt and Export in Wriety
            </h1>

            <div className="lg:block hidden -z-10 absolute top-[2.4rem] -left-1 h-[0.813rem] w-[28rem] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547]" />
          </div>

          {/* content */}
          <div className="grid grid-cols-2 lg:gap-y-6 gap-y-4 lg:gap-x-[6.625rem] gap-x-4 text-[#4B465C] lg:text-xl text-sm">
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod, nec varius urna?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
            <h1>Lorem ipsum dolor sit amet, euismod?</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
