import threedots from "../../assets/market-place/three-dots.svg";
import playButton from "../../assets/market-place/play-button.svg";
import point from "../../assets/market-place/point.svg";
import yellowStar from "../../assets/market-place/yellow-star.svg";
import { useState } from "react";

const MarketCard = (props) => {
  const {
    img,
    title,
    grade,
    subject,
    topic,
    views,
    date,
    time,
    name,
    // handleHover,
    // handleHoverOnMouseLeave,
  } = props;
  // const onHover = () => {
  //   handleHover();
  // };

  // const onHoverLeave = () => {
  //   handleHoverOnMouseLeave();
  // };
  return (
    <div
      className="flex flex-col justify-start s:w-[260px] xmd:w-[200px] lg:w-[260px] shadow-xl rounded-[8px]"
      // onMouseEnter={onHover}
      // onMouseLeave={onHoverLeave}
      style={{ alignItems: "flex-start" }}
    >
      <img src={img} alt="relatedImg" />
      <div
        className="flex flex-col items-start justify-center py-[5px]"
        style={{ alignItems: "flex-start" }}
      >
        <div className="flex flex-row px-[5px] items-center justify-between s:w-[258px] xmd:w-[198px] lg:w-[258px]">
          <p className="text-[14px] font-semibold ">{title}</p>
          <img src={threedots} alt="three-dots" />
        </div>

        <div className="flex flex-row gap-[4px] items-center justify-start px-[5px]">
          <p className="text-[#3D3D3D] text-[10px] ">{grade}</p>
          <img src={playButton} alt="play-button" className="w-[7px] h-[8px]" />
          <p className="text-[#3D3D3D] text-[10px] ">{subject}</p>
          <img src={playButton} alt="play-button" className="w-[7px] h-[8px]" />
          <p className="text-[#3D3D3D] text-[10px] ">{topic}</p>
        </div>

        <div className="flex flex-row gap-[4px] items-center justify-start px-[5px]">
          <p className="text-[#6A6A6A] text-[10px] opacity-[56%]">{views}</p>
          <img src={point} alt="point" className="w-[5px] h-[5px]" />
          <p className="text-[#6A6A6A] text-[10px] opacity-[56%]">{date}</p>
          <img src={point} alt="point" className="w-[5px] h-[5px]" />
          <p className="text-[#6A6A6A] text-[10px]  opacity-[56%]">{time}</p>
        </div>

        <div className="flex flex-row px-[5px] items-center justify-between s:w-[258px] xmd:w-[198px] lg:w-[258px]">
          <p className="text-[10px] text-[#6A6A6A] opacity-[56%]">{name}</p>
          <div className="flex flex-row gap-[4px] items-center justify-center">
            <img
              src={yellowStar}
              alt="yellow-star"
              className="w-[10px] h-[10px]"
            />
            <p className="text-[10px] text-[#A1A1A1]">4.7</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketCard;
