import React from "react";

import UsecaseCard from "./UsecaseCard";

import ForCompanies from "../../assets/usecases/for-companies.png";
import ForContentCreators from "../../assets/usecases/for-contentcreators.png";
import ContentCreationIllustration from "../../assets/usecases/contentcreation-illustration.svg";

const Usecase12 = () => {
  return (
    <div className="border-b border-black w-full flex xl:flex-row flex-col xl:gap-[5rem] gap-6 xl:pl-[4rem] xl:pr-[6.25rem] px-[1.25rem] bg-[#FEFEFE] xl:pt-[5.2rem] xl:pb-[5rem] py-6 justify-center">
      {/* illustration */}
      <img
        src={ContentCreationIllustration}
        alt="ilustration"
        className="xl:w-[31.25rem] xl:h-[31.25rem] max-w-[31.25rem] max-h-[31.25rem] z-50 xl:mt-[0.55rem] xl:mx-0 md:mx-auto mx-0"
      />

      {/* content */}
      <div className="relative flex flex-col gap-[1rem] xl:w-[47rem]">
        <div>
          <h1 className="text-[#E2B00B] xl:text-[2rem] text-xl font-medium leading-tight">
            Translation & communication
          </h1>
          <h2 className="text-[#202020] xl:text-[3.25rem] text-[1.5rem] font-semibold leading-tight">
            Language translation and
            <br />
            Communication
          </h2>
        </div>

        <p className="xl:text-[1.5rem] text-[#202020] xl:leading-snug tracking-tight">
          Wriety's language selection and translation tools can facilitate
          communication between multilingual users.
          <br />
          It can be used for language exchange or multilingual meetings.
        </p>

        {/* cards */}
        <div className="hidden absolute bottom-8 right-12 xl:flex flex-col gap-6">
          <UsecaseCard
            colored
            title="Content creator"
            image={ForContentCreators}
            content="Companies can use Wriety to develop training materials and interactive modules."
          />
        </div>
      </div>

      <div className="xl:hidden gap-4">
        <UsecaseCard
          colored
          title="Content creator"
          image={ForContentCreators}
          content="Companies can use Wriety to develop training materials and interactive modules."
        />
      </div>
    </div>
  );
};

export default Usecase12;
