import React from "react";

import Logo from "../../assets/features/narrow-logo.png";

import TableBody1 from "./TableBody1";
import TableBody2 from "./TableBody2";
import TableBody3 from "./TableBody3";
import TableBody4 from "./TableBody4";
import TableBody5 from "./TableBody5";
import TableBody6 from "./TableBody6";

const Comparision = () => {
  return (
    <div className="md:mt-[8.438rem] md:my-[6rem] mt-[6rem] mb-12 xl:px-[5rem] px-[1.25rem] flex flex-col items-center">
      {/* heading */}
      <h1 className="md:block hidden w-full text-[4rem] font-bold">
        The most Powerful Whiteboard <br /> tool on
      </h1>

      <h1 className="md:hidden w-full text-[2.2rem] font-bold">
        The most Powerful Whiteboard tool on the market
      </h1>

      {/* table labels */}
      <table className="md:mt-0 mt-8 w-full table-fixed border-collapse">
        <thead className="min-h-[6.5rem]">
          <tr>
            <th className="md:w-[24.8rem] w-[8rem] text-start align-top">
              <h1 className="md:block hidden text-[4rem] font-bold">
                the market
              </h1>
            </th>

            <th className="border border-[C7C7C7]">
              <div className="flex flex-col items-center md:gap-2 gap-1 md:pb-[1rem]">
                <img
                  src={Logo}
                  alt="logo"
                  className="md:h-[2.875rem] md:w-[9.063rem] w-[4rem]"
                />
                <div className="relative">
                  <h1 className="md:text-[1.75rem] text-xs font-medium">OEM</h1>

                  <div className="md:block hidden -z-10 absolute top-3 -left-0.5 h-[1.25rem] w-[4.125rem] bg-[#FFF9E2]" />
                </div>
              </div>
            </th>

            <th className="border border-[C7C7C7]">
              <div className="flex flex-col items-center md:gap-2 gap-1 md:pb-[1rem]">
                <img
                  src={Logo}
                  alt="logo"
                  className="md:h-[2.875rem] md:w-[9.063rem] w-[4rem]"
                />
                <div className="relative">
                  <h1 className="md:text-[1.75rem] text-xs font-medium">
                    OS                  
                  </h1>

                  <div className="md:block hidden -z-10 absolute top-3 -left-0.5 h-[1.25rem] w-[8.2rem] bg-[#FFF9E2]" />
                </div>
              </div>
            </th>

            <th className="border border-[C7C7C7]">
              <div className="flex flex-col items-center md:gap-2 gap-1 md:pb-[1rem]">
                <img
                  src={Logo}
                  alt="logo"
                  className="md:h-[2.875rem] md:w-[9.063rem] w-[4rem]"
                />
                <div className="relative">
                  <h1 className="md:text-[1.75rem] text-xs font-medium">                    
                    Advanced
                  </h1>

                  <div className="md:block hidden -z-10 absolute top-3 -left-0.5 h-[1.25rem] w-[3.125rem] bg-[#FFF9E2]" />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <TableBody1 />
        <TableBody2 />
        <TableBody3 />
        <TableBody4 />
        <TableBody5 />
        <TableBody6 />
      </table>
    </div>
  );
};

export default Comparision;
