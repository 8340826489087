import whiteRightArrow from "../../assets/white-right-arrow.svg";
import blackRightArrow from "../../assets/landing-page/blackRightArrow.svg";
import WheelAnimation from "./WheelAnimation.jsx";
import JungleeAI from "./JungleeAI.jsx";
import GreenAnimators from "./GreenAnimators.jsx";
import PuzzleAnimation from "./PuzzleAnimation.jsx";
import CollaborationImg1 from "../../assets/landing-page/collaboration-img-1-update.png";
import CollaborationImg2 from "../../assets/landing-page/collaboration-img-2-update.png";
import CollaborationImg3 from "../../assets/landing-page/collaboration-img-3-update.png";
import CollaborationImg4 from "../../assets/landing-page/collaboration-img-4-update.png";
import CollaborationImg5 from "../../assets/landing-page/collaboration-img-5-update.png";
import CollaborationImg6 from "../../assets/landing-page/collaboration-img-6-update.png";
import activitiesImg from "../../assets/landing-page/activities-img.png";
// import interactiveCollab from "../../assets/landing-page/interactive-collab.png";
import breakOutImg from "../../assets/landing-page/breakout.svg";
import chatImgA from "../../assets/landing-page/team-a-img.svg";
import chatImgB from "../../assets/landing-page/team-b-img.svg";
import chatImgC from "../../assets/landing-page/team-c-img.svg";
import chatImgD from "../../assets/landing-page/team-d-img.svg";
import googleClassroom from "../../assets/landing-page/google-classroom.svg";
import practicallyLogo from "../../assets/landing-page/practically-logo.svg";
import edLink from "../../assets/landing-page/ed-link-logo.svg";
import teamsClasses from "../../assets/landing-page/teams-classes.svg";
import canvasLogo from "../../assets/landing-page/canvas.svg";
import yourLMS from "../../assets/landing-page/your-lms.svg";
import youtube from "../../assets/landing-page/youtube-logo.svg";
import simulations from "../../assets/landing-page/simulations-img.svg";
import objects from "../../assets/landing-page/3d-objects.svg";
import googlePlay from "../../assets/landing-page/get-on-google.svg";
import appStore from "../../assets/landing-page/download-on-app-store.svg";
import microsoft from "../../assets/landing-page/from-microsoft.svg";
import web from "../../assets/landing-page/open-on-web.svg";
import paintingGIF from "../../assets/landing-page/board-painting.gif";
import canvasGIF from "../../assets/landing-page/canvas-min.gif";
import alhuaLogo from "../../assets/landing-page/aluha-logo.svg";
import qtouchLogo from "../../assets/landing-page/qtouch-logo.svg";
import horizonLogo from "../../assets/landing-page/horizon-logo.svg";
import newlineLogo from "../../assets/landing-page/newline-logo.svg";
import msdLogo from "../../assets/landing-page/msd-logo.svg";
// import electronics from "../../assets/landing-page/electronics.png";
import crypto1 from "../../assets/landing-page/crypto-1-svg.svg";
import crypto2 from "../../assets/landing-page/crypto-2-svg.svg";
import chem1 from "../../assets/landing-page/chem-1-svg.svg";
import chem2 from "../../assets/landing-page/chem-2-svg.svg";
import equation1 from "../../assets/landing-page/equation-1-svg.svg";
import equation2 from "../../assets/landing-page/equation-2-svg.svg";
import boardInCanvas from "../../assets/landing-page/board-in-canvas.png";
import tabletScreen from "../../assets/landing-page/tablet-screen.png";
import laptopScreen from "../../assets/landing-page/laptop-screen.png";

import "./SliderAnimation.jsx";
import { useEffect, useState } from "react";
import "./LandingPage.css";
import SliderAnimation from "./SliderAnimation.jsx";
import { Link } from "react-router-dom";
import Hometab from "../hometab/Homeanimation.jsx";


const imagesnew = {
  IFPs: CollaborationImg1,
  Projectors: CollaborationImg2,
  Education: CollaborationImg3,
  Tutors: CollaborationImg4,
  Meetings: CollaborationImg5,
  Productivity: CollaborationImg6
};

const LandingPage = () => {
  const [highlightedText, setHighlightedText] = useState("IFPs");
  const [imageSrc, setImageSrc] = useState(CollaborationImg1);
  const [backgroundColor, setBackgroundColor] = useState("bg-[#88351C]");
  const [isGenAIVisible, setIsGenAIVisible] = useState(false);
  const [isJungleeAIVisible, setIsJungleeAIVisible] = useState(false);
  const [isFlowSheetVisible, setIsFlowSheetVisible] = useState(false);
  const [isActivityVisible, setIsActivityVisible] = useState(false);
  const [isCollabVisible, setIsCollabVisible] = useState(false);
  const [isTopTierVisible, setIsTopTierVisible] = useState(false);
  const [isMultiPlatformVisible, setIsMultiPlatformVisible] = useState(false);
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);
  const images = [
    '/landing-page/crypto-1-svg.svg',
    '/landing-page/chem-1-svg.svg',
    '/landing-page/equation-1-svg.svg'
  ];
  const images2 = [
    '/landing-page/crypto-2-svg.svg',
    '/landing-page/chem-2-svg.svg',
    '/landing-page/equation-2-svg.svg'
  ];

  useEffect(() => {
    // Preload images
    Object.values(imagesnew).forEach((src) => {
      const img = new Image();
      img.src = src;
    });

    const handleTextAndImageChange = () => {
      switch (highlightedText) {
        case "IFPs":
          setHighlightedText("Projectors");
          setImageSrc(imagesnew.Projectors);
          setBackgroundColor("bg-[#175134]");
          break;
        case "Projectors":
          setHighlightedText("Education");
          setImageSrc(imagesnew.Education);
          setBackgroundColor("bg-[#1B2E58]");
          break;
        case "Education":
          setHighlightedText("Tutors");
          setImageSrc(imagesnew.Tutors);
          setBackgroundColor("bg-[#583C1B]");
          break;
        case "Tutors":
          setHighlightedText("Meetings");
          setImageSrc(imagesnew.Meetings);
          setBackgroundColor("bg-[#302C2C]");
          break;
        case "Meetings":
          setHighlightedText("Productivity");
          setImageSrc(imagesnew.Productivity);
          setBackgroundColor("bg-[#581B56]");
          break;
        default:
          setHighlightedText("IFPs");
          setImageSrc(imagesnew.IFPs);
          setBackgroundColor("bg-[#88351C]");
          break;
      }
    };

    const intervalId = setInterval(handleTextAndImageChange, 2500);

    return () => {
      clearInterval(intervalId);
    };
  }, [highlightedText]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndexo, setCurrentImageIndexo] = useState(0);


  useEffect(() => {
    const interval1 = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000);

    const interval2 = setInterval(() => {
      setCurrentImageIndexo(prevIndex => (prevIndex + 1) % images2.length);
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);


  useEffect(() => {
    const genAIElement = document.getElementById("gen-ai");
    const jungleeAIElement = document.getElementById("junglee-ai");
    const flowSheetElement = document.getElementById("flowsheet");
    const activityElement = document.getElementById("activity");
    const collabElement = document.getElementById("collab");
    const topTierElement = document.getElementById("top-tier");
    const multiPlatformElement = document.getElementById("multi-platform");

    function checkVisible() {
      const genAIRect = genAIElement.getBoundingClientRect();
      const jungleeAIRect = jungleeAIElement.getBoundingClientRect();
      const flowSheetRect = flowSheetElement.getBoundingClientRect();
      const activityRect = activityElement.getBoundingClientRect();
      const collabRect = collabElement.getBoundingClientRect();
      const topTierRect = topTierElement.getBoundingClientRect();
      const multiPlatformRect = multiPlatformElement.getBoundingClientRect();

      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      // console.log(genAIRect.bottom, genAIRect.top, viewHeight);
      // console.log("isGenAIVisible: ", isGenAIVisible);
      const isVisibleGenAI = genAIRect.top <= viewHeight;
      const isVisibleJungleeAI = jungleeAIRect.top <= viewHeight;
      const isVisibleFlowSheet = flowSheetRect.top <= viewHeight;
      const isVisibleActivity = activityRect.top <= viewHeight;
      const isVisibleCollab = collabRect.top <= viewHeight;
      const isVisibleTopTier = topTierRect.top <= viewHeight;
      const isVisibleMultiPlatform = multiPlatformRect.top <= viewHeight;

      // genAI
      if (isVisibleGenAI && !isGenAIVisible) {
        console.log("Element is fully visible. Adding the class.");
        genAIElement.classList.add("translateTitle");
        setIsGenAIVisible(true);
      } else if (!isVisibleGenAI && isGenAIVisible) {
        console.log("Element is not fully visible. Removing the class.");
        genAIElement.classList.remove("translateTitle");
        setIsGenAIVisible(false);
      }
      // jungleeAI
      else if (isVisibleJungleeAI && !isJungleeAIVisible) {
        jungleeAIElement.classList.add("translateTitle");
        setIsJungleeAIVisible(true);
      } else if (!isVisibleJungleeAI && isJungleeAIVisible) {
        jungleeAIElement.classList.remove("translateTitle");
        setIsJungleeAIVisible(false);
      }
      //flowsheet
      else if (isVisibleFlowSheet && !isFlowSheetVisible) {
        flowSheetElement.classList.add("translateTitle");
        setIsFlowSheetVisible(true);
      } else if (!isVisibleFlowSheet && isFlowSheetVisible) {
        flowSheetElement.classList.remove("translateTitle");
        setIsFlowSheetVisible(false);
      }
      //activity
      else if (isVisibleActivity && !isActivityVisible) {
        activityElement.classList.add("translateTitle");
        setIsActivityVisible(true);
      } else if (!isVisibleActivity && isActivityVisible) {
        activityElement.classList.remove("translateTitle");
        setIsActivityVisible(false);
      }
      //collab
      else if (isVisibleCollab && !isCollabVisible) {
        collabElement.classList.add("translateTitle");
        setIsCollabVisible(true);
      } else if (!isVisibleCollab && isCollabVisible) {
        collabElement.classList.remove("translateTitle");
        setIsCollabVisible(false);
      }
      // top-tier
      else if (isVisibleTopTier && !isTopTierVisible) {
        topTierElement.classList.add("translateTitle");
        setIsTopTierVisible(true);
      } else if (!isVisibleTopTier && isTopTierVisible) {
        topTierElement.classList.remove("translateTitle");
        setIsTopTierVisible(false);
      }
      // multi-platform
      else if (isVisibleMultiPlatform && !isMultiPlatformVisible) {
        multiPlatformElement.classList.add("translateTitle");
        setIsMultiPlatformVisible(true);
      } else if (!isVisibleMultiPlatform && isMultiPlatformVisible) {
        multiPlatformElement.classList.remove("translateTitle");
        setIsMultiPlatformVisible(false);
      }
    }

    window.addEventListener("scroll", checkVisible);
    return () => {
      window.removeEventListener("scroll", checkVisible);
    };
  }, [
    isGenAIVisible,
    isJungleeAIVisible,
    isFlowSheetVisible,
    isActivityVisible,
    isCollabVisible,
    isTopTierVisible,
    isMultiPlatformVisible,
  ]);

  return (
    <div className="mt-[120px]">
      {/* first section */}
      <div className="flex flex-col gap-[50px] lg:px-[100px] md:px-[20px] px-[10px] pb-[60px] items-center justify-center padding-x">
        <div className="flex flex-col md:flex-row lg:flex-row  lg:w-[1080px] md:w-[96%] w-[96%] fix-container">
          <h1 className="lg:text-[49px] text-[30px] text-[#302C2C] lg:leading-[69px]  leading-[45px] font-inter font-semibold lg:w-[65%] md:w-[65%] w-[100%]">
            Interactive Collaborations, multiplatform capabilities for
            <span
              className={` ${backgroundColor} rounded-[6px] px-[20px] text-white ml-[10px]`}
            >
              {highlightedText}
            </span>
          </h1>
          <div
            className="flex flex-col items-start justify-around lg:w-[35%] md:w-[35%] w-[100%] mt-[20px] md:mt-[0px]"
            style={{ alignItems: "flex-start" }}
          >
            <p className="text-[#302C2CA6] leading-[24px]">
              Whatever your use case may be, we guarantee measurable performance
              and productivity improvements
            </p>
            <div className="flex flex-row gap-[30px] lg:mt-[0px] mt-[20px] ">
              <a
                href="https://app.wriety.com/web"
                target="_blank"
                rel="noreferrer"
                className="bg-[#302C2C] hover:no-underline hover:text-inherit px-[18px] py-[9px] gap-[11px] rounded-[8px] flex flex-row items-center hover-container"
              >
              <p className="text-[#FFFFFF] ">Get Started</p>
                <div className="flex flex-row gap-[10px] relative overflow-hidden ">
                  <span className="absolute initial-slide-in">
                    <img src={whiteRightArrow} alt="right arrow" />
                  </span>

                  <span className="initial-slide-out">
                    <img src={whiteRightArrow} alt="right arrow" />
                  </span>
                </div>
              </a>


              <div
                className="flex flex-col gap-[5px] justify-center"
                style={{ alignItems: "flex-start" }}
                onMouseEnter={() => setIsHoverContactSales(true)}
                onMouseLeave={() => setIsHoverContactSales(false)}
              >
                <Link
                  to="contact-us"
                  className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                >
                  <p className="leading-[12px] font-semibold">Contact Sales</p>
                  <span className={`${isHoverContactSales ? "bounce-x" : ""}`}>
                    <img
                      src={blackRightArrow}
                      alt="Arrow"
                      className="w-[21px] h-[12px]"
                    />
                  </span>
                </Link>
                <div
                  className={`h-[2px] bg-[#000000] justify-end ${
                    isHoverContactSales ? "w-[80%] transition-all" : "w-[100%] transition-all"
                  }`}
                  style={{ willChange: "transform" }}
                ></div>
              </div>
              
            </div>
          </div>
        </div>

        <img
          src={imageSrc}
          alt="collaboration"
          className="object-cover object-top w-[1160px] lg:h-[550px] md:h-[520px] h-[auto] rounded-[10px]"
        />
        <div className="flex flex-col gap-[35px] justify-center items-center">
          <p className="text-[19px] font-semibold text-[#302C2CA6]">
            Trusted by Reputed brands
          </p>
          <div className="blur-edge flex flex-row justify-center gap-[100px] items-center overflow-hidden w-[1200px]">
            <div className="animated-container gap-[110px]">
              <img
                className="animated-img w-[130px] h-[35px]"
                src={alhuaLogo}
                alt="alhuaLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={msdLogo}
                alt="msdLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={newlineLogo}
                alt="newlineLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={horizonLogo}
                alt="horizonLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={qtouchLogo}
                alt="qtouchLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={alhuaLogo}
                alt="alhuaLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={msdLogo}
                alt="msdLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={newlineLogo}
                alt="newlineLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={horizonLogo}
                alt="horizonLogo"
              />
              <img
                className="animated-img w-[130px] h-[35px]"
                src={qtouchLogo}
                alt="qtouchLogo"
              />
            </div>
          </div>
        </div>
      </div>

      {/* rotating wheel animation */}
      <WheelAnimation />

      {/* Generative AI */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff] px-[20px] lg:px-[120px] py-[80px] items-center lg:w-[100%] md:w-[100%] w-[100%] padding-x">
        <div
          id="gen-ai"
          className="flex flex-col gap-[20px] w-[auto] md:w-[96%] lg:w-[1270px] translateTitle fix-container"
        >
          <h1 className="lg:text-[48px] text-[30px]  font-semibold ">
            State of the art Generative AI
          </h1>
          <p className="text-[#302C2CA6] lg:pr-[300px] pr-[0px] text-[18px]">
            We have it all covered for you - Text, handwriting, and images, no
            problem - Bring your A game always!
            <br /> Prepared or not prepared, world is your canvas with wide
            range of use cases with our GEN AI capability.
          </p>
        </div>

        <div className="flex lg:flex-row md:flex-col flex-col gap-[25px] lg:w-[100%] w-[98%] justify-content-center fix-container ">
          <div className=" bg-[#000000] lg:w-[730px] w-[100%] h-[484px] rounded-[14px] flex flex-col items-center justify-center overflow-hidden relative">
          {images.map((image, index) => (
          <img
          key={index}
          src={image}
          className={`slider-image ${index === currentImageIndex ? 'active' : index === (currentImageIndex + 1) % images.length ? 'previous' : ''}`}
          alt={`Slide ${index}`}
        />
      ))}
          </div>
          <div className="bg-[#000000] lg:w-[510px] w-[100%] h-[484px] rounded-[14px] flex items-center justify-center overflow-hidden relative">
          {images2.map((image, index) => (
          <img
          key={index}
          src={image}
          className={`slider-image ${index === currentImageIndexo ? 'active' : index === (currentImageIndex + 1) % images2.length ? 'previous' : ''}`}
          alt={`Slide ${index}`}
        />
      ))}
          </div>
        </div>
      </div>

      {/* jungleeAI */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff] lg:px-[120px] px-[20px] lg:py-[80px] py-[20px] items-center lg:w-[100%] w-[98%] justify-content-center ">
        <div
          id="junglee-ai"
          className="flex flex-col gap-[20px] w-[96%] lg:w-[1270px]  "
        >
          <h1 className="lg:text-[48px] text-[30px] font-semibold ">
            Junglee AI supercharges productivity
          </h1>
          <p className="text-[#302C2CA6] lg:pr-[300px]  pr-[0px] text-[18px]">
            Advanced GEN AI Capabilities at your fingertips. Don’t waste your
            time with <br /> mundane tasks, do what you do best and leave the
            secretarial work to us.
          </p>
        </div>
        <JungleeAI />
      </div>

      {/* flowsheet and mind maps */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff] px-[20px] lg:px-[120px] lg:py-[80px] py-[20px] items-center padding-x">
        <div
          id="flowsheet"
          className="flex flex-col gap-[20px] w-[96%] lg:w-[1270px] fix-container"
        >
          <h1 className="lg:text-[48px] text-[30px] font-semibold ">
            Flowsheets and Mind maps, a canvas for <br /> your imagination
          </h1>
          <p className="text-[#302C2CA6] lg:pr-[300px] pr-[0] text-[18px]">
            Unlock your inner DaVinci : sketch with freedom, produce detailed
            flowsheets. Templates optimize workflow, while Junglee AI
            streamlines design automation.
          </p>
        </div>
        <div className="bg-[#202020] w-[96%] lg:w-[1270px] lg:h-[520px] h-[auto] rounded-[14px] flex items-center justify-center fix-container overflow-hidden">
          <img
            src={paintingGIF}
            alt="painting-gif"
            className="mt-[82px]"
          />
        </div>
      </div>

      {/* Activities */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff] px-[20px] lg:px-[120px] lg:py-[80px] py-[20px] items-center padding-x">
        <div
          id="activity"
          className="flex flex-col gap-[20px] w-[96%] lg:w-[1270px] fix-container"
        >
          <h1 className="lg:text-[48px] text-[30px] font-semibold ">
            Activities make sessions engaging <br /> and fun for all.
          </h1>
          <p className="text-[#302C2CA6] lg:pr-[300px] pr-[0] text-[18px]">
            Create yourself or use from marketplace interactive activities to
            make your sessions engaging and fun for all. No time? Use Junglee AI
            to automatically create the activity for you on the fly.
          </p>
        </div>
        <img
          src={activitiesImg}
          alt="activity img"
          className=" lg:w-[1270px] w-[100%] lg:h-[520px] h-[100%] rounded-[14px] fix-container"
        />
      </div>

      {/* Interactive Collab */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff]  px-[20px] lg:px-[120px] lg:py-[80px] py-[20px] items-center padding-x">
        <div
          id="collab"
          className="flex flex-col gap-[20px] w-[96%] lg:w-[1270px] fix-container"
        >
          <h1 className="text-[30px] lg:text-[48px] font-semibold ">
            Interactive Collaborations
          </h1>
          <p className="text-[#302C2CA6] lg:pr-[300px] pr-[0px] text-[18px]">
            An intuitive platform that streamlines teamwork and boosts
            productivity with real-time project and task synchronization.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row  justify-content-center gap-[25px]  w-[100%] fix-container">
          <div className="bg-[#183B6E] lg:w-[730px] w-[100%]  h-[484px] rounded-[14px] flex items-start overflow-hidden">
            <div className="w-[90%] h-[88%] mt-[60px] relative">
              <img
                src={boardInCanvas}
                alt="boardInCanvas"
                className="w-full h-full absolute z-15"
              />
              <img
                src={canvasGIF}
                alt="canvas-gif"
                className="z-20 w-full h-[81%] top-[18px] absolute"
              />
            </div>
          </div>
          <div className="bg-[#183B6E] lg:w-[510px] w-[100%] h-[484px] rounded-[14px] ">
            <div className="flex flex-col gap-[40px] items-center justify-center px-[50px] py-[45px] w-full h-full">
              <div className="lg:w-[68%] w-[100%] h-[100%] relative">
                <img
                  src={tabletScreen}
                  alt="tabletScreen"
                  className="w-full h-[100%] absolute"
                />
                <img
                  src={canvasGIF}
                  alt="canvas-gif"
                  className="z-20 w-full h-full p-[7px] absolute"
                />
              </div>
              <div className="w-[93%] h-[100%]  relative">
                <img
                  src={laptopScreen}
                  alt="laptopScreen"
                  className="w-full h-[100%] absolute"
                />
                <img
                  src={canvasGIF}
                  alt="canvas-gif"
                  className="z-20 w-full h-full absolute pl-[61px] pr-[54px] pt-[8px] pb-[18px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Breakout Rooms */}
      <div className="bg-[#A15151] w-full flex items-center justify-center">
        <div className="lg:w-[1570px] w-[96%] py-[60px] flex flex-col gap:[20px] lg:gap-[70px] items-center justify-center relative fix-container">
          <div className="flex flex-col gap-[30px] items-center justify-center w-[96%] lg:w-[1400px]">
            <h1 className="text-[#ffffff]  font-[400!important] lg:text-[48px] text-[35px] font-semibold">
              Breakout Rooms
            </h1>
            <p className="text-[#FFFFFFA6] font-[400] text-[18px] lg:px-[270px] px-[0px]  text-center">
              A great way to accelerate group dynamics and creativity. Monitor with moderators, return content to the main room and utilize a bird's-eye view of the breakout room boards from the main room
            </p>
            <img
              src={breakOutImg}
              alt="breakout"
              className="lg:w-[550px] w-[100%]    "
            />
          </div>
          <img
            src={chatImgD}
            alt="team D"
            className="ab-24 relative md:absolute lg:left-[150px] left-[0px]  lg:mb-[100px] mb-[0px]"
          />
          <img
            src={chatImgA}
            alt="team A"
            className="ab-24 relative md:absolute left-[0px]  lg:left-[250px] mt-[20px] lg:mt-[283px]"
          />
          <img
            src={chatImgC}
            alt="team C"
            className="ab-24 relative md:absolute right-[0px] lg:right-[150px]  mt-[20px]  mb-[0px] lg:mb-[100px]"
          />
          <img
            src={chatImgB}
            alt="team B"
            className="ab-24 relative md:absolute right-[0px] lg:right-[250px]  mt-[20px] lg:mt-[283px]"
          />
        </div>
      </div>

      {/* interactive and collaborative video confrencing */}
      <div className="flex items-center justify-center w-full bg-[#ffffff]">
        <div className="flex flex-col lg:gap-[150px] gap-[50px] items-center justify-center pt-[50px] lg:pt-[100px] bg-[#ffffff] lg:w-[1570px] w-[96%]">
          <div className="flex flex-col gap-[20px] items-center justify-center lg:w-[1250px] w-[96%] fix-container">
            <h1 className="lg:text-[48px] text-[30px] font-semibold text-center">
              Interactive and collaborative Video conferencing
            </h1>
            <p className="text-[#302C2CA6] text-center text-[18px]">
              Dump boring video conferencing, involve your participants in
              brainstorming with real-time collaboration. Engage your students
              with collaborative activities, showcase their achievements, and
              make your sessions fun for all.
            </p>
          </div>
          <GreenAnimators />
        </div>
      </div>

      {/* slider animation */}
      <div className="flex items-center justify-center bg-[#252222]">
        <SliderAnimation />
      </div>

      {/* facilitating your lms workflows */}
      <div className="flex lg:flex-row flex-col  items-center justify-between lg:gap-[10px] gap-[20px] px-[20px] lg:px-[0px] pt-[40px] pb-[40px] fix-container padding-x lg:max-w-[1150px] max-w-[96%]  mx-auto">
        <div className="flex flex-col gap-[24px] lg:w-[auto] w-[96%] ">
          <h1 className="lg:text-[56px] text-[30px] font-semibold ">
            Facilitating Your LMS <br /> Workflows
          </h1>
          <p className="text-[#302C2CA6] lg:text-[20px] text-[18px]  w-[100%] lg:w-[540px]">
            We work with industry leading LMS providers to integrate into
            workflows. EdLink integration powers single sign on to most if the
            popular LMS systems in the US. Have a custom LMS? Talk to us for
            integration.
          </p>
          <button className="bg-[#302C2C] px-[18px] max-w-max py-[9px] gap-[20px] rounded-[8px] flex flex-row items-center mt-[18px] hover-container">
            <p className="text-[#FFFFFF] ">Talk to us</p>
            <div className="flex flex-row gap-[10px] relative overflow-hidden ">
              <span className="absolute initial-slide-in">
                <img src={whiteRightArrow} alt="right arrow" />
              </span>

              <span className="initial-slide-out">
                <img src={whiteRightArrow} alt="right arrow" />
              </span>
            </div>
          </button>
        </div>
        <div className="flex flex-row items-start gap-[18px] justify-center">
          <div className="flex flex-col gap-[20px] pt-[85px]">
            <img
              src={googleClassroom}
              alt="classroom"
              className="w-[179px] h-[200px]"
            />
            <img
              src={practicallyLogo}
              alt="practically"
              className="w-[179px] h-[200px]"
            />
            <img src={edLink} alt="EdLink" className="w-[179px] h-[200px]" />
          </div>
          <div className="flex flex-col gap-[20px]">
            <img
              src={teamsClasses}
              alt="teamsClasses"
              className="w-[179px] h-[200px]"
            />
            <img
              src={canvasLogo}
              alt="canvasLogo"
              className="w-[179px] h-[200px]"
            />
            <img src={yourLMS} alt="your-lms" className="w-[179px] h-[200px]" />
          </div>
        </div>
      </div>

      {/* Top-tier video */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff]  px-[20px] lg:px-[120px] lg:py-[80px] py-[20px] items-center padding-x bg-[#ffffff]">
        <div className="flex flex-col gap-[60px] py-[60px] bg-[#ffffff] lg:px-[180px] px-[10px]  lg:w-[1570px] w-[96%] fix-container padding-x">
          <div
            id="top-tier"
            className="flex flex-col gap-[24px] lg:w-[620px] w-[100%]"
          >
            <h1 className="lg:text-[48px] text-[30px]  font-semibold ">
              Top-Tier Video and 3D Content at your fingertips
            </h1>
            <p className="text-[#302C2CA6] text-[18px]">
              We work with industry leading content providers for your classroom
              usage. Have a custom Content source? Talk to us for integration.
            </p>
          </div>

          <div className="flex flex-col lg:flex-row gap-[20px] justify-center items-center w-[96%] mx-auto">
            <div className="flex flex-col lg:gap-[80px] gap-[20px] items-center justify-center lg:w-[700px] w-[100%]">
              <div className="flex flex-col items-center justify-center gap-[10px] bg-[#F6F4F2] py-[60px] px-[10px] rounded-[14px] h-[350px]">
                <img
                  src={youtube}
                  alt="youtube"
                  className="w-[100px] h-[70px]"
                />
                <p className="text-[#787676] text-[24px] font-semibold">
                  Videos
                </p>
                <p className="text-[#302C2C] text-[16px] text-center">
                  Engaging 300 hours worth of content for enriching learning
                  experiences.
                </p>
              </div>
              <div className="flex flex-col lg:gap-[20px] gap-[05px] items-center justify-center">
                <h1 className="lg:text-[56px] text-[25px] font-semibold">
                  3000+
                </h1>
                <p className="text-[18px] text-[#787676]">Videos</p>
              </div>
            </div>

            <div className="flex flex-col lg:gap-[80px] gap-[20px] lg:mt-[0px] mt-[20px] items-center justify-center lg:w-[700px] w-[100%]">
              <div className="flex flex-col items-center justify-center gap-[10px] bg-[#F6F4F2] py-[60px] px-[10px] rounded-[14px] h-[350px]">
                <img
                  src={simulations}
                  alt="simulations"
                  className="w-[100px] h-[70px]"
                />
                <p className="text-[#787676] text-[24px] font-semibold">
                  Simulations
                </p>
                <p className="text-[#302C2C] text-[16px] text-center">
                  Interactive tools fostering experiential learning in virtual
                  environments.
                </p>
              </div>
              
              <div className="flex flex-col lg:gap-[20px] gap-[05px] items-center justify-center">
                <h1 className="lg:text-[56px] text-[25px] font-semibold">
                  1000+
                </h1>
                <p className="text-[18px] text-[#787676]">Simulations</p>
              </div>
            </div>

            <div className="flex flex-col lg:gap-[80px] gap-[20px] lg:mt-[0px] mt-[20px] items-center justify-center lg:w-[700px] w-[100%]">
              <div className="flex flex-col items-center justify-center gap-[10px] bg-[#F6F4F2] py-[60px] px-[10px] rounded-[14px] h-[350px] ">
                <img
                  src={objects}
                  alt="objects"
                  className="w-[100px] h-[70px]"
                />
                <p className="text-[#787676] text-[24px] font-semibold">
                  3D Objects
                </p>
                <p className="text-[#302C2C] text-[16px] text-center">
                  Dive into dynamic visuals, fostering deeper understanding
                  through hands-on exploration.
                </p>
              </div>
              <div className="flex flex-col lg:gap-[20px] gap-[05px] items-center justify-center">
                <h1 className="lg:text-[56px] text-[25px] font-semibold">
                  30k+
                </h1>
                <p className="text-[18px] text-[#787676]">3D Objects</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Enhance Session */}
      <div className="flex flex-col gap-[50px] bg-[#ffffff]  px-[20px] lg:px-[120px] lg:py-[80px] py-[20px] items-center padding-xbg-[#ffffff]">
        <div className="flex flex-col lg:gap-[60px] gap-[20px] lg:py-[100px] lg:py-[50px] bg-[#ffffff] lg:px-[190px] md:px-[20px] px-[0px] lg:w-[1570px] w-[96%] fix-container padding-x">
          <div className="flex flex-col gap-[20px] lg:w-[900px] w-[96%] pl-[11px]">
            <h1 className="lg:text-[48px] text-[30px]  font-semibold  lg:ps-[30px]">
              Enhance Your Sessions with Innovative Presentation and
              <span className="text-[#787676]"> whiteboard Tools</span>
            </h1>
          </div>
          <PuzzleAnimation />
        </div>
      </div>
      {/* <div className="w-full flex items-center justify-center bg-[#ffffff]">
        <div className="lg:w-[1401px] w-[95%] bg-[#ffffff]">
        </div>
      </div> */}

      {/* Multi-platform support */}
      <div className="flex items-center justify-center w-full bg-[#ffffff] multi-plateforms-sections mt-[40px] lg:mt-[0px] ">
        <div className="flex flex-col gap-[60px] lg:py-[140px] py-[60px]  bg-[#ffffff] lg:px-[100px] px-[20px] lg:w-[1570px] w-[96%] fix-container padding-x">
          <div
            id="multi-platform"
            className="flex flex-col gap-[20px] lg:w-[660px] w-[100%] lg:pl-[40px] pl-[0px]"
          >
            <h1 className="lg:text-[48px] text-[30px]  font-semibold">
              Multi-platform support 
            </h1>
            <p className="text-[#302C2CA6] text-[18px]">
              Experience seamless functionality across multiple platforms,
              ensuring versatility and accessibility for all users.
            </p>
          </div>



          <div className="flex lg:flex-row flex-col gap-[20px] items-center justify-around">
            <div className="md70 md:w-[60%] bg-[#F6F4F2] flex lg:flex-row flex-col gap-[35px] py-[40px] md:px-[30px] px-[10px] rounded-[14px]">
            <Hometab/>
            
              {/* <ul className="flex flex-col gap-[26px] items-start justify-start font-medium ">
                <button className="text-[18px] text-[#333333]">Phones</button>
                <button className="text-[18px] text-[#333333]">iPad</button>
                <button className="text-[18px] text-[#333333]">IFP</button>
                <button className="text-[18px] text-[#333333]">Mac</button>
                <button className="text-[18px] text-[#333333]">Laptop</button>
                <button className="text-[18px] text-[#333333]">
                  Android Tab
                </button>
                <button className="text-[18px] text-[#333333]">
                  Interactive Projectors
                </button>
                <button className="text-[18px] text-[#333333]">
                  Android Box
                </button>
              </ul>
              <div className="w-[2px] h-auto bg-[#413E3E1A]"></div>
              <img src={multiPlatform} alt="multi-platform" /> */}
            </div>

            {/* <img src={electronics} alt="electronics" className="w-[800px]" /> */}
            <div className="flex flex-col gap-[20px] justify-center">
              <a
                href="https://play.google.com/store/apps/details?id=play.wriety&hl=en_US"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlay} alt="google-play" />
                </a>
             
                <img src={appStore} alt="apple-store" />
        
              <a
                href="https://apps.microsoft.com/detail/9nmkgk7b36b3?hl=en-US&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img src={microsoft} alt="microsoft" />
                </a>
              <a
                href="https://app.wriety.com/web"
                target="_blank"
                rel="noreferrer"
              >
                <img src={web} alt="web" />
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
