import React, { useState, useEffect } from "react";

import Logo from "../assets/landing-page/wriety-logo.svg";
// import PrimaryButton from "./PrimaryButton";
import { Link } from "react-router-dom";
import downArrow from "../assets/downArrow.svg";
import rightArrowIcon from "../assets/rightArrowIcon.svg";
import Menu from "../assets/menu.svg";
import MobileNav from "./MobileNav";
import ArrowButton from "./ArrowButton";
import Cards from "./project-menu/Cards";
import "./NavBar.css";

// import ContactUs from "./ContactUs";

const Navbar = () => {
  let menuTimeout;
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [isHoverOnAddOn, setIsHoverOnAdd] = useState(false);
  const [isHoverOnMarket, setIsHoverOnMarket] = useState(false);
  const [isHoverOnBlog, setIsHoverOnBlog] = useState(false);
  const [isHoverOnCompany, setIsHoverOnCompany] = useState(false);
  const [isHoverOnSupport, setIsHoverOnSupport] = useState(false);
  // const [showCards, setShowCards] = useState(true);
  const [hoveredLabel, setHoveredLabel] = useState("");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleHoveredLabel = (label) => {
    // console.log(label);
    setHoveredLabel(label);
  };
  // const handleShowCards = (label) => {
  //   setShowCards(true);
  // };
  const handleProductMenu = () => {
    setOpenProductMenu((prev) => !prev);
  };

  const handleProductMenuEnter = () => {
    clearTimeout(menuTimeout); 
    setOpenProductMenu(true);
  };

  const handleProductMenuLeave = () => {
    menuTimeout = setTimeout(() => {
      setOpenProductMenu(false);
      setHoveredLabel("");
    }, 200); // Adjust the delay time as needed
  };

  const handleHoverAddOn = () => {
    if (!isHoverOnAddOn) setIsHoverOnAdd(true);
  };

  const handleLeaveAddOn = () => {
    if (isHoverOnAddOn) setIsHoverOnAdd(false);
  };

  const handleHoverMarket = () => {
    if (!isHoverOnMarket) setIsHoverOnMarket(true);
  };

  const handleLeaveMarket = () => {
    if (isHoverOnMarket) setIsHoverOnMarket(false);
  };

  const handleHoverBlog = () => {
    if (!isHoverOnBlog) setIsHoverOnBlog(true);
  };

  const handleLeaveBlog = () => {
    if (isHoverOnBlog) setIsHoverOnBlog(false);
  };

  const handleHoverCompany = () => {
    if (!isHoverOnCompany) setIsHoverOnCompany(true);
  };

  const handleLeaveCompany = () => {
    if (isHoverOnCompany) setIsHoverOnCompany(false);
  };

  const handleHoverSupport = () => {
    if (!isHoverOnSupport) setIsHoverOnSupport(true);
  };

  const handleLeaveSupport = () => {
    if (isHoverOnSupport) setIsHoverOnSupport(false);
  };
  return (
    <div className="">
      <div
        id="navbar"
        className="w-full top-0 fixed bg-[#FFFFFF] pb-[9px]"
        style={{ zIndex: "9999" }}
      >
        <div className="mt-[12px] xmd:mx-6 lg:mx-10 xl:mx-20 mx-[1.25rem] flex justify-between items-center ">
          {/* logo */}

          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="xmd:min-w-[7.5rem] lg:min-w-[10rem] w-40"
            />
          </Link>

          {window.innerWidth < 880 && (
            <button onClick={() => setOpenMobileMenu(true)}>
              <img className="xmd:hidden w-6 h-6" src={Menu} />
            </button>
          )}

          {openMobileMenu && (
            <MobileNav setOpenMobileMenu={setOpenMobileMenu} />
          )}

          <div className="xmd:flex hidden xmd:mr-[8px] s:mr-[0px] items-center">
            {/* links */}
            <div className="flex text-[#050038] s:text-[12px] xmd:text-base xl:text-base s:gap-[10px] xmd:gap-[1.25rem] xl:gap-[1.25rem]">
              <div className="flex flex-col justify-center items-center">
                <button
                  className="flex gap-[3px] items-baseline justify-center"
                  onClick={handleProductMenu}
                  onMouseEnter={handleProductMenuEnter}
                  onMouseLeave={handleProductMenuLeave}
                >
                  <h1 className="xmd:text-[10px] lg:text-[12px] xl:text-[16px] text-[#302C2CCC] hover:text-[#302C2CCC]">
                    Products
                  </h1>
                  {/* {openProductMenu ? (
                    <img
                      src={rightArrowIcon}
                      alt="right arrow"
                      className="w-[13px] h-[10px]"
                    />
                  ) : (
                    <img
                      src={downArrow}
                      alt="down arrow"
                      className="w-[13px] h-[7px]"
                    />
                  )} */}
                </button>
                {openProductMenu && (
                  <div className="absolute mt-[32px] rounded-[16px] bo-1 border-2 border-[#413E3E] w-[45px]"></div>
                )}
                {/* product menu */}
                {openProductMenu && (
                  <div
                    className={`top-[65px] rounded-2xl will-change-transform fixed bg-[#FFFFFF] shadow-xl `}
                    style={{ zIndex: "9999" }}
                    onMouseEnter={handleProductMenuEnter}
                    onMouseLeave={handleProductMenuLeave}
                  >
                    <div className="flex flex-row gap-[35px] px-[10px] py-[23px]">
                      <div className="flex flex-col justify-center items-center xmd:gap-[5px] lg:gap-[10px]">
                        <ArrowButton
                          label="OEM"
                          setOpenMobileMenu={setOpenMobileMenu}
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                        <ArrowButton
                          setOpenMobileMenu={setOpenMobileMenu}
                          label="Advanced"
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                        <ArrowButton
                          setOpenMobileMenu={setOpenMobileMenu}
                          label="Android Box"
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                        <ArrowButton
                          setOpenMobileMenu={setOpenMobileMenu}
                          label="DMS"
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                        <ArrowButton
                          setOpenMobileMenu={setOpenMobileMenu}
                          label="Online collaborations"
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                        <ArrowButton
                          setOpenMobileMenu={setOpenMobileMenu}
                          label="Productivity Board"
                          labelHandle={handleHoveredLabel}
                          currHoverLabel={hoveredLabel}
                        />
                      </div>
                      {/* <Cards label={hoveredLabel} /> */}
                    </div>
                  </div>
                )}
              </div>
              
              <button
                className="flex flex-col justify-end items-center"
                onMouseEnter={handleHoverAddOn}
                onMouseLeave={handleLeaveAddOn}
              >
                <Link
                  to="add-ons-1"
                  className="xmd:text-[10px] lg:text-[12px] xl:text-[16px] text-[#302C2CCC] hover:no-underline hover:text-[#302C2CCC]"
                >
                  Add on Features
                </Link>
                {isHoverOnAddOn && (
                  <div className="top-[46px] absolute rounded-[16px] bo-1 border-2 border-[#413E3E] w-[45px]"></div>
                )}
              </button>

              <button
                className="flex flex-col justify-end items-center"
                onMouseEnter={handleHoverMarket}
                onMouseLeave={handleLeaveMarket}
              >
                <Link
                  to="market-place"
                  className="text-[#302C2CCC] whitespace-nowrap xmd:text-[10px] lg:text-[12px] xl:text-[16px] hover:no-underline hover:text-[#302C2CCC]"
                >
                  Marketplace
                </Link>

                {isHoverOnMarket && (
                  <div className="top-[46px] absolute rounded-[16px] bo-1 border-2 border-[#413E3E] w-[45px]"></div>
                )}
              </button>

              <button
                className="flex flex-col justify-end items-center"
                onMouseEnter={handleHoverBlog}
                onMouseLeave={handleLeaveBlog}
              >
                <Link
                  to="blogs"
                  className="text-[#302C2CCC] xmd:text-[10px] lg:text-[12px] xl:text-[16px] hover:no-underline hover:text-[#302C2CCC]"
                >
                  Blog
                </Link>

                {isHoverOnBlog && (
                  <div className="top-[46px] absolute rounded-[16px] bo-1 border-2 border-[#413E3E] w-[19px]"></div>
                )}
              </button>

              <button
                className="flex flex-col justify-end items-center"
                onMouseEnter={handleHoverCompany}
                onMouseLeave={handleLeaveCompany}
              >
                <Link
                  to="company"
                  className="text-[#302C2CCC] xmd:text-[10px] lg:text-[12px] xl:text-[16px] hover:no-underline hover:text-[#302C2CCC]"
                >
                  Company
                </Link>

                {isHoverOnCompany && (
                  <div className="top-[46px] absolute rounded-[16px] bo-1 border-2 border-[#413E3E] w-[46px]"></div>
                )}
              </button>

              <button
                className="flex flex-col justify-end items-center"
                onMouseEnter={handleHoverSupport}
                onMouseLeave={handleLeaveSupport}
              >
                <Link
                  to="contact-us"
                  className="text-[#302C2CCC] xmd:text-[10px] lg:text-[12px] xl:text-[16px] hover:no-underline hover:text-[#302C2CCC]"
                >
                  Support
                </Link>

                {isHoverOnSupport && (
                  <div className="top-[46px] absolute rounded-[16px] bo-1 border-2 border-[#413E3E] w-[46px]"></div>
                )}
              </button>
            </div>
          </div>
          {/* button */}
          <div className="xmd:flex hidden flex-row items-center gap-[12px]">
            {/* <PrimaryButton link="" title="Login" /> */}
            {/* <Link
              to=""
              className="xmd:text-[10px] lg:text-[12px] xl:text-[16px] hover:no-underline hover:text-inherit"
            >
              Download
            </Link> */}
            <a href="https://app.wriety.com/web" target="_blank" rel="noreferrer">
              {" "}
              <button className="bg-[#302C2C] py-[9px] px-[18px] rounded-[8px] text-[#FFFFFF] xmd:text-[10px] lg:text-[12px] xl:text-[16px]">
                Get started
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
