import React, { useState } from "react";
import PricingTable from "../components/pricing/PricingTable";
import PricingPlan from "../components/pricing/PricingPlan";

import BasicLogo from "../assets/pricing/basic-plan.svg";
import TeamsLogo from "../assets/pricing/teams-plan.svg";
import ProLogo from "../assets/pricing/pro-plan.svg";

const pricingPlansData = [
  {
    id: 1,
    name: "Basic",
    logo: BasicLogo,
    decription: "For Individuals",
    price: "6,990",
  },
  {
    id: 2,
    name: "Pro",
    logo: ProLogo,
    decription: "For Professionals",
    price: "12,990",
  },
  {
    id: 3,
    name: "Teams",
    logo: TeamsLogo,
    decription: "For Teams & enterprices",
    price: "16,990",
  },
];

const Pricing = () => {
  const [active, setActive] = useState(1);

  return (
    <div className="md:pt-[12.313rem] pt-[7rem] pb-[6.25rem] flex flex-col items-center">
      {/* heading */}
      <div className="relative md:mb-[6.25rem] mb-[2.25rem]">
        <h1 className="md:text-[2.5rem] text-[1.2rem] font-semibold xl:pl-4 text-center">
          Flexible Pricing Plans to Fit Your Needs
        </h1>

        <div className="xl:block hidden -z-10 absolute top-7 left-5 h-[1.875rem] w-[19.188rem] bg-[#FFF9E2]" />
      </div>

      {/* plans */}
      <div className="xl:flex hidden w-full justify-end">
        {pricingPlansData.map((item) => {
          return (
            <PricingPlan
              id={item.id}
              name={item.name}
              logo={item.logo}
              decription={item.decription}
              price={item.price}
              active={active}
              setActive={setActive}
            />
          );
        })}
      </div>

      {/* table */}
      <PricingTable />

      {/* buttons */}
      <div className="xl:flex hidden w-full justify-end mt-[8.75rem]">
        <div className="flex justify-center w-[21.938rem]">
          <button
            type="submit"
            className="text-[#202020] font-medium w-[16.875rem] rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-[1.25rem] px-[1.75rem]"
          >
            Get Started
          </button>
        </div>
        <div className="flex items-center justify-center w-[21.938rem]">
          <button
            type="submit"
            className="text-[#202020] font-medium w-[16.875rem] rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-[1.25rem] px-[1.75rem]"
          >
            Get Started
          </button>
        </div>
        <div className="flex items-center justify-center w-[21.938rem]">
          <button
            type="submit"
            className="text-[#202020] font-medium w-[16.875rem] rounded-lg border border-[#EECA53] bg-gradient-to-r from-[#F1C638] via-[#FCDE7D] to-[#ECC547] py-[1.25rem] px-[1.75rem]"
          >
            Get Started
          </button>
        </div>
      </div>

      {/* plans */}
      <div className="xl:hidden flex lg:flex-row flex-col w-full mt-16 justify-center items-center">
        {pricingPlansData.map((item) => {
          return (
            <PricingPlan
              id={item.id}
              name={item.name}
              logo={item.logo}
              decription={item.decription}
              price={item.price}
              active={active}
              setActive={setActive}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Pricing;
